import { AccountType } from "../../localComponents/types/enums";
import { Models } from "../../localComponents/types/models";
import { ViewModels } from "../../localComponents/types/viewModels";
import { getPerson } from "./UserHelpers";

export const needsHiding = (entry: Models.IHealthRecordEntry<string>, user: ViewModels.IUserViewModel) => {
    const isMyInformation = user.accountType === AccountType.Patient
        && getPerson(user)?.id === entry.personId;
    return isMyInformation && !entry.hasBeenSeenBySharer;
}
