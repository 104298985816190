import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Models } from "../../types/models";
import { NavigationContextType } from "../../types/enums";

const initialState: Models.Navigation.NavigationContext = {
    type: NavigationContextType.None
};
export const navigationContextSlice = createSlice({
    name: 'navigationContext',
    initialState: initialState,
    reducers: {
        setContext: (state, action: PayloadAction<Models.Navigation.NavigationContext>) => {
            return action.payload;
        }
    }
});