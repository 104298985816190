import { distinct } from "../../sharedCommonComponents/helpers/CollectionHelpers";
import { AccountType, LegalEntityType } from "../types/enums";
import { Models } from "../types/models";
import { ViewModels } from "../types/viewModels";

export const getPerson = (user: ViewModels.IUserViewModel | undefined) => {
    if(!user) {
        return undefined;
    }
    switch(user.accountType) {
        case AccountType.Patient:
        case AccountType.Employee:
            const personUserViewModel = user as ViewModels.IPersonUserViewModel;
            return personUserViewModel.profileData;
        default:
            return undefined;
    }
}
export const getPractitioner = (user: ViewModels.IUserViewModel | undefined) => {
    if(!user) {
        return undefined;
    }
    if(user.accountType !== AccountType.Employee) {
        return undefined;
    }
    const employeeUserViewModel = user as ViewModels.EmployeeUserViewModel;
    return employeeUserViewModel.professionalProfile?.id;
}
export const getAllRoles = (user: ViewModels.EmployeeUserViewModel) => {
    const institutionRoles = user.permissions.flatMap(institutionPermissions => institutionPermissions.roles);
    const departmentRoles = user.permissions
        .flatMap(institutionPermissions => institutionPermissions.departmentPermissions)
        .flatMap(departmentPermissions => departmentPermissions.roles);
    const distincRoles: Models.AccessControl.Role[] = [];
    for (const role of distinct(institutionRoles.concat(departmentRoles))) {
        if(distincRoles.some(x => x.id === role.id)) {
            continue;
        }
        distincRoles.push(role);
    }
    return distincRoles;
}
export const getLegalEntityReference = (user: ViewModels.IUserViewModel | undefined): Models.LegalEntityReference | undefined => {
    if(!user) {
        return undefined;
    }
    switch(user.accountType) {
        case AccountType.Employee:
            const employee = user as ViewModels.EmployeeUserViewModel;
            return {
                type: LegalEntityType.Practitioner,
                entityId: employee.professionalProfile.id
            };
        case AccountType.Device:
            return undefined;
        case AccountType.Patient:
            const patientUser = user as ViewModels.PatientUserViewModel;
            return {
                type: LegalEntityType.Person,
                entityId: patientUser.profileData.personId
            };
        default:
            return undefined;
    }
}