import { Alert, Modal, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { EditLinkButton } from '../../sharedCommonComponents/components/EditLinkButton';
import { resolveText } from '../../sharedCommonComponents/helpers/Globalizer';
import { VerbatimText } from '../../sharedHealthComponents/components/Notes/VerbatimText';
import { formatDate, formatPersonName } from '../../sharedHealthComponents/helpers/Formatters';
import { modalSlice } from '../redux/slices/modalSlice';
import { useAppDispatch, useAppSelector } from '../redux/store/healthRecordStore';
import { Models } from '../types/models';
import { DeleteButton } from '../../sharedCommonComponents/components/DeleteButon';
import { isolationsActions } from '../../sharedHealthComponents/redux/slices/isolationsSlice';
import { buildHealthRecordUrl } from '../../sharedHealthComponents/navigation/Urls';

export interface IsolationModalProps {
    person: Models.Individuals.Person;
    isolations: Models.Isolation[];
}

export const IsolationModal = () => {

    const { show, parameters } = useAppSelector(state => state.modals.isolationModal);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onClose = () => {
        dispatch(modalSlice.actions.closeIsolationModal());
    }

    if(!parameters?.person) {
        return null;
    }

    const endIsolation = (isolationId: string) => {
        dispatch(isolationsActions.deleteItem({
            args: isolationId
        }));
        dispatch(modalSlice.actions.openIsolationModal({
            ...parameters,
            isolations: parameters.isolations.filter(x => x.id !== isolationId)
        }));
    }

    const isolations = parameters.isolations ?? [];
    return (
        <Modal show={show} onHide={onClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className='fa fa-exclamation-triangle red me-2' />
                    {resolveText("Isolation")} {formatPersonName(parameters.person)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isolations.length > 0
                ? <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{resolveText("Isolation_Timestamp")}</th>
                            <th>{resolveText("Isolation_Reason")}</th>
                            <th>{resolveText("Isolation_Precautions")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {parameters.isolations.map(isolation => (
                            <tr key={isolation.id}>
                                <td>
                                    <DeleteButton
                                        size='xs'
                                        onClick={() => endIsolation(isolation.id)}
                                        requireConfirm
                                        confirmDialogTitle={resolveText("Isolation_ConfirmDelete_Title")}
                                        confirmDialogMessage={resolveText("Isolation_ConfirmDelete_Message").replaceAll("{0}", isolation.reason)}
                                    />
                                </td>
                                <td>
                                    {formatDate(new Date(isolation.timestamp))}
                                    {isolation.endTime 
                                    ? <div>
                                        {resolveText("Isolation_EndTime")}: {formatDate(new Date(isolation.endTime))}
                                    </div> : null}
                                </td>
                                <td>{isolation.reason}</td>
                                <td>
                                    <VerbatimText
                                        text={isolation.precautions}
                                    />
                                </td>
                                <td>
                                    <EditLinkButton
                                        onClick={() => navigate(buildHealthRecordUrl(isolation.personId, `/edit/isolation/${isolation.id}`))}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                : <Alert variant='success'>
                    {resolveText("Isolation_NotIsolated")}
                </Alert>}
            </Modal.Body>
        </Modal>
    );

}