import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../localComponents/redux/store/healthRecordStore";
import { healthRecordModalsSlice } from "../../redux/slices/healthRecordModalsSlice";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { useEffect } from "react";
import { medicalAlertsActions, medicalAlertsSelectors } from "../../redux/slices/medicalAlertsSlice";
import { useMemorizedSelector } from "../../redux/helpers/ReduxHooks";

interface MedicalAlertIconProps {
    personId: string;
    size?: 'sm' | 'lg';
    className?: string;
}

export const MedicalAlertIcon = (props: MedicalAlertIconProps) => {

    const { personId, size, className } = props;
    const isLoading = useAppSelector(state => state.medicalAlerts.isLoading);
    const medicalAlerts = useMemorizedSelector(medicalAlertsSelectors.createSelectForPerson, { personId: personId });
    const dispatch = useAppDispatch();

    const openMedicalAlertModal = () => {
        dispatch(healthRecordModalsSlice.actions.openMedicalAlertsModal({
            personId: personId
        }));
    }

    useEffect(() => {
        dispatch(medicalAlertsActions.loadItems({
            args: {
                personId: personId
            }
        }));
    }, []);

    if(!isLoading && medicalAlerts.length === 0) {
        return null;
    }

    return (
        <Button
            variant="danger"
            size={size}
            onClick={openMedicalAlertModal}
            title={resolveText("MedicalAlerts")}
            className={className ?? 'px-2 py-1 mx-2'}
        >
            <i className="fa fa-exclamation-triangle" /> ({medicalAlerts.length})
        </Button>
    );

}