import { lazy } from 'react';
import { PrivacyPolicy } from '../components/PrivacyPolicy/PrivacyPolicy';
import NotFoundPage from '../pages/NotFoundPage';
import { AccountType } from '../types/enums';
import { JankisRouteDefinition } from '../types/frontendTypes';
import { Models } from '../types/models';
import { CreateEditSubscription } from '../pages/Subscriptions/CreateEditSubscription';
import MyTranslatedChatsPage from '../pages/Collaboration/MyTranslatedChatsPage';
import { HealthRecordUrls, SharedUrls, ToolsUrls } from '../../sharedHealthComponents/navigation/Urls';
import { JanKisUrls } from './Urls';

const CreateTranslatedChatPage = lazy(() => import('../../sharedHealthComponents/pages/Chat/CreateTranslatedChatPage'));
const TranslatedChatPage = lazy(() => import('../../sharedHealthComponents/pages/Chat/TranslatedChatPage'));
const JoinTranslatedChatPage = lazy(() => import('../../sharedHealthComponents/pages/Chat/JoinTranslatedChatPage'));

const EditPersonPage = lazy(() => import('../../sharedHealthComponents/pages/Patients/EditPersonPage'));
const MyAccountPage = lazy(() => import('../pages/UserManagement/MyAccountPage'));

export const RegisteredUserRoutes = (
    features: Models.Configuration.FeatureSettings,
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void,
    onLogOut: () => Promise<void>): JankisRouteDefinition[] => {
    const routes: JankisRouteDefinition[] = [
        { path: '*', element: <NotFoundPage />, audience: [] },
        { path: HealthRecordUrls.EDIT_PERSON, element: <EditPersonPage />, audience: [ AccountType.Patient ] },
        { path: SharedUrls.MY_ACCOUNT, element: <MyAccountPage />, audience: [ AccountType.Patient, AccountType.Employee ]},
        { path: JanKisUrls.CREATE_SUBSCRIPTION, element: <CreateEditSubscription />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.EDIT_SUBSCRIPTION, element: <CreateEditSubscription />, audience: [ AccountType.Employee ]},
        { path: SharedUrls.PRIVACY_STATEMENT, element: <PrivacyPolicy />, audience: [] },
        
        // Chat
        { path: ToolsUrls.MY_CHATS, element: <MyTranslatedChatsPage />, audience: [] },
        { path: ToolsUrls.NEW_CHAT, element: <CreateTranslatedChatPage onNewAuthenticationResult={onNewAuthenticationResult} />, audience: [] },
        { path: ToolsUrls.OPEN_CHAT, element: <TranslatedChatPage onLogOut={onLogOut} />, audience: [] },
        { path: ToolsUrls.JOIN_CHAT, element: <JoinTranslatedChatPage onNewAuthenticationResult={onNewAuthenticationResult} />, audience: [] },
    ];
    return routes;
}
export default RegisteredUserRoutes;