import { MedicalTextTemplatePartType } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { loadObject } from "../../../sharedCommonComponents/helpers/LoadingHelpers";

export const escapeTemplate = async (template: Models.MedicalTextEditor.MedicalTextTemplate) => {
    let knownTemplateCodes: string[] | undefined = undefined;
    await loadObject<string[]>(
        'api/medicalTextTemplates/codes', {},
        resolveText("MedicalTextTemplates_CouldNotLoad"),
        items => knownTemplateCodes = items
    );
    if(!knownTemplateCodes) {
        return template;
    }
    const escapedParts: Models.MedicalTextEditor.MedicalTextTemplatePart[] = [];
    for(const part of template.parts) {
        if(part.type !== MedicalTextTemplatePartType.Text) {
            escapedParts.push(part);
            continue;
        }
        const escapedPart = escapeTemplatePart(part as Models.MedicalTextEditor.TextMedicalTextTemplatePart, knownTemplateCodes);
        escapedParts.push(escapedPart);
    }
    const escapedTemplate: Models.MedicalTextEditor.MedicalTextTemplate = {
        ...template,
        parts: escapedParts
    };
    return escapedTemplate;
}
const escapeTemplatePart = (part: Models.MedicalTextEditor.TextMedicalTextTemplatePart, knownTemplateCodes: string[]) => {
    let escapedText = part.text;
    for(const templateCode of knownTemplateCodes) {
        escapedText = escapedText.replaceAll(templateCode, `[${templateCode}]`);
    }
    return {
        ...part,
        text: escapedText
    };
}