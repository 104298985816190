import { useCallback } from "react";
import { MedicalProcedureOutcome } from "../../../localComponents/types/enums";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";

interface FormattedMedicalProcedureOutcomeProps {
    outcome?: MedicalProcedureOutcome | null;
}

export const FormattedMedicalProcedureOutcome = (props: FormattedMedicalProcedureOutcomeProps) => {

    const { outcome } = props;

    const getColorForMedicalProcedureOutcome = useCallback((outcome: MedicalProcedureOutcome): string => {
        switch(outcome) {
            case MedicalProcedureOutcome.Successful:
                return 'text-success';
            case MedicalProcedureOutcome.PartiallySuccessful:
                return 'text-warning';
            case MedicalProcedureOutcome.Unsuccessful:
                return 'text-danger';
            default:
                return '';
        }
    }, []);

    if(!outcome) {
        return null;
    }

    return (<span 
        className={getColorForMedicalProcedureOutcome(outcome)}
    >
        <strong>- {resolveText(`MedicalProcedureOutcome_${outcome}`)}</strong>
    </span>);

}