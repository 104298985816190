import React, { PropsWithChildren, Suspense, useContext } from 'react';
import {Nav, Navbar, NavDropdown, NavItem, NavLink } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { LoggedInUser } from '../Menus/LoggedInUser';
import UserContext from '../../contexts/UserContext';
import { ToastContainer } from 'react-toastify';
import { LoadingAlert } from '../../../sharedCommonComponents/components/LoadingAlert';
import { LockedOverlay } from '../../../sharedHealthComponents/components/UserManagement/LockedOverlay';
import { HealthRecordSearchSelfcontained } from '../../../sharedHealthComponents/components/Patients/HealthRecordSearchSelfcontained';
import { getPerson } from '../../helpers/UserHelpers';
import { PatientPortalUrls } from '../../navigation/Urls';
import { HealthRecordUrls, ToolsUrls } from '../../../sharedHealthComponents/navigation/Urls';

interface PatientPortalLayoutProps {
    onLogOut: () => void;
}

export const PatientPortalLayout = (props: PropsWithChildren<PatientPortalLayoutProps>) => {

    const navigate = useNavigate();
    const user = useContext(UserContext)!;
    const personId = getPerson(user)!.id;

    return (
        <>
            <ToastContainer
                theme='colored'
            />
            <HealthRecordSearchSelfcontained />
            <Navbar bg="light" expand="md" sticky="top">
                <Navbar.Brand className="ms-2 clickable" onClick={() => navigate('/')}>
                    <i className='fa fa-hospital-o me-2' />
                    <strong>JanKIS</strong>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <NavItem>
                            <NavLink onClick={() => navigate(HealthRecordUrls.HEALTHRECORD_BASEURL.replace(':personId',personId))}>{resolveText("Menu_MyData")}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={() => navigate(PatientPortalUrls.CALENDAR)}>{resolveText("Menu_Calendar")}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={() => navigate(ToolsUrls.MY_CHATS)}>{resolveText("Menu_Chat")}</NavLink>
                        </NavItem>
                        <NavDropdown title={resolveText('Menu_Services')} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={() => navigate(PatientPortalUrls.ORDER_MEAL)}>{resolveText('Menu_MealService')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => navigate(PatientPortalUrls.SOCIALSERVICES)}>{resolveText('Menu_SocialServices')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => navigate(PatientPortalUrls.LIBRARY)}>{resolveText('Menu_Library')}</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <LoggedInUser onLogOut={props.onLogOut} />
                </Navbar.Collapse>
            </Navbar>
            <Suspense fallback={<LoadingAlert />}>
                {props.children}
            </Suspense>
            <LockedOverlay />
        </>
    );

}