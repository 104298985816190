import { Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store/healthRecordStore";
import { modalSlice } from "../redux/slices/modalSlice";

export interface TextPlaceholderModalProps {
    onPlaceholderSelected: (placeholder: string) => void;
}

const normalizePlaceholder = (str: string) => {
    return str.toUpperCase().replaceAll("[^A-Z0-9_]", "");
}

export const TextPlaceholderModal = () => {

    
    const { show, parameters } = useAppSelector(state => state.modals.textPlaceholderModal);
    const onPlaceholderSelected = parameters?.onPlaceholderSelected;
    const [ placeholder, setPlaceholder ] = useState<string>('');
    const [ suggestions, setSuggestions ] = useState<string[]>([]);
    const [ selectedSuggestionIndex, setSelectedSuggestionIndex ] = useState<number>(-1);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSelectedSuggestionIndex(-1);
    }, [ suggestions ]);

    useEffect(() => {
        if(selectedSuggestionIndex < 0 || selectedSuggestionIndex >= suggestions.length) {
            return;
        }
        const suggestion = suggestions[selectedSuggestionIndex];
        setPlaceholder(suggestion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ selectedSuggestionIndex ]);

    const onClose = useCallback(() => {
        dispatch(modalSlice.actions.closeTextPlaceholderModal());
    }, [ dispatch ]);

    const commit = useCallback((e?: FormEvent) => {
        e?.preventDefault();
        e?.stopPropagation();
        if(placeholder.length > 0 && typeof onPlaceholderSelected === 'function') {
            onPlaceholderSelected(placeholder);
        }
        onClose();
    }, [ placeholder, onPlaceholderSelected, onClose ]);

    return (<Modal
        show={show}
        onHide={onClose}
    >
        <Modal.Header closeButton>
            <Modal.Title>{resolveText("Placeholder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={commit}>
                <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <FormControl
                        value={placeholder}
                        onChange={e => setPlaceholder(normalizePlaceholder(e.target.value))}
                        placeholder={resolveText("Search")}
                        onKeyDown={e => {
                            if(e.shiftKey || e.ctrlKey || e.altKey) {
                                return;
                            }
                            if(e.key === 'ArrowDown') {
                                setSelectedSuggestionIndex(state => state >= suggestions.length ? suggestions.length - 1 : state + 1);
                            } else if(e.key === 'ArrowUp') {
                                setSelectedSuggestionIndex(state => 
                                    state < 0 ? -1 
                                    : state === 0 ? 0
                                    : state - 1
                                );
                            }
                        }}
                    />
                </InputGroup>
            </Form>
        </Modal.Body>
    </Modal>);

}