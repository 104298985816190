import { Models } from "../types/models";
import { Event } from "react-big-calendar";
import { ViewModels } from "../types/viewModels";
import { addHours } from "date-fns";

export const createCalendarEventsFromAppointmentsAndProcedures = (
    appointments: ViewModels.AppointmentViewModel[],
    procedures: Models.Procedures.MedicalProcedure[]
): Event[] => {
    const events: Event[] = [];
    events.push(...appointments.map(appointment => ({
        title: appointment.department?.name ?? appointment.institution?.name ?? '',
        start: new Date(appointment.startTime),
        end: new Date(appointment.endTime),
        resource: appointment
    } as Event)));
    events.push(...procedures.map(procedure => ({
        title: procedure.code.displayName,
        start: new Date(procedure.timestamp),
        end: procedure.endTime ? new Date(procedure.endTime) : addHours(new Date(procedure.timestamp), 1),
        resource: procedure,
    } as Event)));
    return events;
}