import { PropsWithChildren } from "react";
import { Button } from "react-bootstrap";

interface ActionButtonProps {
    isCtrlPressed: boolean;
    buttonIndex: number;
    onClick: () => void;
}
export const ActionButton = (props: PropsWithChildren<ActionButtonProps>) => {
    return (
        <Button
            onClick={props.onClick}
            size="lg"
            className="text-center w-100"
            variant={props.isCtrlPressed ? 'outline-primary' : 'primary'}
        >
            {props.isCtrlPressed ? <span className="display-3">{props.buttonIndex}</span> : null}
            <div>
                {props.children}
            </div>
        </Button>
    )
}