import { useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useAppDispatch } from '../../../localComponents/redux/store/healthRecordStore';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { canViewDocument } from '../../helpers/DocumentHelper';
import { documentsActions, documentsSelectors } from '../../redux/slices/documentsSlice';
import { createOneTimeTokenAndDownloadDocument } from '../../helpers/FileHelpers';
import { useMemorizedSelector } from '../../redux/helpers/ReduxHooks';
import { healthRecordModalsSlice } from '../../redux/slices/healthRecordModalsSlice';

interface DocumentButtonsProps {
    documentId: string;
}

export const DocumentButtons = (props: DocumentButtonsProps) => {

    const { documentId } = props;
    const document = useMemorizedSelector(documentsSelectors.getById, { id: documentId });
    const canView = useMemo(() => document && canViewDocument(document), [ document ]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!documentId) {
            return;
        }
        dispatch(documentsActions.loadItemIfNotLoadedYet({ 
            args: { 
                itemId: documentId 
            }
        }));
    }, [ documentId, dispatch ]);

    const openDocumentViewerModal = () => {
        dispatch(healthRecordModalsSlice.actions.openDocumentViewerModal({
            documentId: documentId
        }));
    }

    return (
        <>
            <Button 
                size="sm" 
                className='m-1'
                onClick={() => createOneTimeTokenAndDownloadDocument(documentId)}
            >
                {resolveText('Download')}
            </Button>
            {canView
            ? <>
                <Button 
                    size="sm" 
                    className='m-1'
                    onClick={openDocumentViewerModal}
                >
                    {resolveText('View')}
                </Button>
            </>
            : null}
        </>
    );

}