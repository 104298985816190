import { apiClient, ApiClient } from "../../sharedCommonComponents/communication/ApiClient";
import { accessTokenExpirationSessionStorageKey, accessTokenSessionStorageKey, csrfTokenSessionStorageKey } from "../../sharedCommonComponents/helpers/Constants";
import { defaultGlobalizer, Globalizer } from "../../sharedCommonComponents/helpers/Globalizer";
import germanTranslation from '../resources/translation.de.json';
import danishTranslation from '../resources/translation.da.json';
import englishTranslation from '../resources/translation.en.json';
import validator from '@rjsf/validator-ajv8';

export const initializeApp = () => {
    defaultGlobalizer.instance = new Globalizer(
        navigator.languages.map(x => x.substring(0, 2)), 
        "en", 
        [ germanTranslation, danishTranslation, englishTranslation ]);
    apiClient.instance = window.location.hostname.toLowerCase() === "localhost"
        ? new ApiClient(window.location.hostname, 44302)
        : new ApiClient(window.location.hostname, 443);
    apiClient.instance.defaultOptions.includeCredentials = false;
    const storedAccessToken = sessionStorage.getItem(accessTokenSessionStorageKey);
    if(!!storedAccessToken) {
        apiClient.instance!.isLoggedIn = true;
        apiClient.instance!.accessToken = storedAccessToken;
        const accessTokenExpirationTime = sessionStorage.getItem(accessTokenExpirationSessionStorageKey);
        if(!!accessTokenExpirationTime) {
            apiClient.instance!.loginExpirationTime = accessTokenExpirationTime as any;
        }
    }
    const storedCsrfToken = sessionStorage.getItem(csrfTokenSessionStorageKey);
    if(!!storedCsrfToken) {
        apiClient.instance!.csrfToken = storedCsrfToken;
    }
    validator.ajv.addFormat('duration', /([0-9]+\.)?([0-9]{1,2}):([0-9]{2}):([0-9]{2}(\.[0-9]+)?)/);
}