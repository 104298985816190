import { Table } from "react-bootstrap";
import { Models } from "../../../localComponents/types/models";
import { NoEntriesAlert } from "../../../sharedCommonComponents/components/NoEntriesAlert";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { HealthRecordEntryType } from "../../../localComponents/types/enums";
import { HealthRecordEntryEditButton } from "../Patients/HealthRecordEntryEditButton";

interface QuestionAnswerTableProps {
    answers: Models.Interview.QuestionAnswer[];
    canHideItems?: boolean;
    onHideItem?: (code: Models.ClassificationReference) => void;
}

export const QuestionAnswerTable = (props: QuestionAnswerTableProps) => {

    const { answers, canHideItems, onHideItem } = props;

    if(answers.length === 0) {
        return (<NoEntriesAlert />);
    }

    const hide = (umlsCuiCode: Models.ClassificationReference) => {
        if(!canHideItems) {
            return;
        }
        if(onHideItem) {
            onHideItem(umlsCuiCode);
        }
    }
    
    return (
    <Table>
        <thead>
            <tr>
                <th></th>
                <th>UMLS CUI Code</th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {answers.map(answer => (
                <tr key={answer.id}>
                    <td>
                        {canHideItems && answer.question.umlsCuiCode
                        ? <i 
                            className="fa fa-eye-slash clickable"
                            title={resolveText("Hide")}
                            onClick={() => hide(answer.question.umlsCuiCode!)}
                        /> : null}
                    </td>
                    <td>{answer.question.umlsCuiCode?.conceptId}</td>
                    <td>{answer.question.text}</td>
                    <td>{answer.answer}</td>
                    <td>
                        <HealthRecordEntryEditButton
                            variant="icon"
                            className="mx-2"
                            entryType={HealthRecordEntryType.Question}
                            entryId={answer.id}
                            onClick={() => {}}
                            disabled
                        />
                    </td>
                </tr>
            ))}
        </tbody>
    </Table>    
    );

}