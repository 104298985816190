import '../../../sharedHealthComponents/styles/print-area.css';

import { ReactNode, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import { Models } from "../../types/models";
import { Button, Col, Container, FormControl, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap";
import { StockAutocomplete } from "../../components/Autocompletes/StockAutocomplete";
import { ListFormControl } from "../../../sharedCommonComponents/components/FormControls/ListFormControl";
import { LabelPaperSettings } from "../../types/frontendTypes";
import { LabelPaperSettingsEditor } from "../../components/Stocks/LabelPaperSettingsEditor";
import { Center } from "../../../sharedCommonComponents/components/Center";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";

interface PrintStockLabelsPageProps {}

export const PrintStockLabelsPage = (props: PrintStockLabelsPageProps) => {

    const [ selectedStocks, setSelectedStocks ] = useState<Models.Warehousing.Stock[]>([]);

    const [ settings, setSettings ] = useState<LabelPaperSettings>({
        rowCount: 4,
        columnCount: 2,
        labelWidth: 99.1,
        labelHeight: 67.7,
        paperWidth: 210,
        paperHeight: 297,
        marginTop: 13,
        marginRight: 4,
        marginBottom: 13,
        marginLeft: 4
    });
    const {
        rowCount,
        columnCount,
        labelWidth,
        labelHeight,
        paperWidth,
        paperHeight,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft
    } = settings;
    const horizontalSpaceBetweenLabels = useMemo(() => {
        if(columnCount <= 1) {
            return 0;
        }
        const totalHorizontalSpacing = paperWidth - columnCount * labelWidth - marginLeft - marginRight;
        if(totalHorizontalSpacing <= 0) {
            return 0;
        }
        return totalHorizontalSpacing / (columnCount - 1);
    }, [ paperWidth, columnCount, labelWidth, marginLeft, marginRight ]);
    const verticalSpaceBetweenLabels = useMemo(() => {
        if(rowCount <= 1) {
            return 0;
        }
        const totalVerticalSpacing = paperHeight - rowCount * labelHeight - marginTop - marginBottom;
        if(totalVerticalSpacing <= 0) {
            return 0;
        }
        return totalVerticalSpacing / (rowCount - 1);
    }, [ paperHeight, rowCount, labelHeight, marginTop, marginBottom ]);
    const [ qrCodeSize, setQrCodeSize ] = useState<number>(30);

    const labels: ReactNode[] = [];
    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
        for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {

            const stockIndex = rowIndex * columnCount + columnIndex;
            const stock = stockIndex < selectedStocks.length ? selectedStocks[stockIndex] : undefined;

            const top = marginTop + (labelHeight + verticalSpaceBetweenLabels) * rowIndex;
            const left = marginLeft + (labelWidth + horizontalSpaceBetweenLabels) * columnIndex;
            const label = (<div
                key={stockIndex}
                style={{
                    position: 'absolute',
                    width: labelWidth + 'mm',
                    height: labelHeight + 'mm',
                    top: top + 'mm',
                    left: left + 'mm'
                }}
                className="border border-solid text-center"
            >
                {stock
                ? <>
                    <QRCode
                        value={`stock:${stock.id}`}
                        style={{
                            position: 'absolute',
                            width: qrCodeSize + 'mm',
                            height: qrCodeSize + 'mm',
                            top: '2mm',
                            right: '2mm'
                        }}
                    />
                    <div 
                        className="text-center"
                        style={{
                            position: 'relative',
                            top: '35mm'
                        }}
                    >
                        <span
                            style={{
                                fontSize: '2em',
                                fontWeight: 'bold'
                            }}
                        >
                            {stock.name}
                        </span>
                    </div>
                </> : null}
            </div>);
            labels.push(label);
        }   
    }

    return (<>
    <Container style={{ maxWidth: '90%' }}>
        <Center className='no-print'>
            <Button
                onClick={() => window.print()}
                size="lg"
            >
                {resolveText("Print")}
            </Button>
        </Center>
        <FormGroup as={Row} className="no-print my-3">
            <FormLabel column>{resolveText("Stock")}</FormLabel>
            <Col>
                <StockAutocomplete
                    includeChildStocks
                    onChange={stock => {
                        if(!stock) {
                            return;
                        }
                        setSelectedStocks(state => {
                            if(state.some(x => x.id === stock.id)) {
                                return state;
                            }
                            return state.concat(stock);
                        });
                    }}
                    className="mb-2"
                    disabled={selectedStocks.length >= rowCount * columnCount}
                />
                <ListFormControl
                    items={selectedStocks}
                    idFunc={x => x.id}
                    displayFunc={x => x.name}
                    removeItem={stock => setSelectedStocks(state => state.filter(x => x.id !== stock.id))}
                />
            </Col>
        </FormGroup>
        <LabelPaperSettingsEditor
            settings={settings}
            onChange={setSettings}
        >
            <FormGroup>
                <FormLabel>QR size</FormLabel>
                <InputGroup>
                    <FormControl
                        type="number"
                        min={1}
                        value={qrCodeSize}
                        onChange={e => setQrCodeSize(Number(e.target.value))}
                    />
                    <InputGroup.Text>mm</InputGroup.Text>
                </InputGroup>
            </FormGroup>
        </LabelPaperSettingsEditor>
    </Container>
    <div
        style={{
            position: 'relative',
            width: paperWidth + 'mm',
            height: paperHeight + 'mm'
        }}
        className="print-page"
    >
        {labels}
    </div>
    </>);

}
export default PrintStockLabelsPage;