import { useCallback, useMemo } from "react";
import { Autocomplete } from "../../../sharedCommonComponents/components/Autocompletes/Autocomplete";
import { Models } from "../../types/models";
import { Alert } from "react-bootstrap";

interface DischargeLetterItemAutocompleteProps {
    items: Models.Documentation.DischargeLetterItem[];
    value?: string;
    onChange: (selectedItem: Models.Documentation.DischargeLetterItem | undefined) => void;
}

export const DischargeLetterItemAutocomplete = (props: DischargeLetterItemAutocompleteProps) => {

    const { items, value, onChange } = props;

    const matchingItem = useMemo(() => items.find(x => x.id === value), [ items, value ]);

    const search = useCallback(async (searchText: string) => {
        const lowerSearchText = searchText.toLowerCase();
        return items.filter(x => x.name.toLowerCase().includes(lowerSearchText));
    }, [ items ]);

    if(!!matchingItem) {
        return (<Alert
            variant="info"
            dismissible
            onClose={() => onChange(undefined)}
        >
            {matchingItem.name}
        </Alert>);
    }

    return (<Autocomplete<Models.Documentation.DischargeLetterItem>
        highlightFirstSuggestion
        displayNameSelector={x => x.name}
        search={search}
        onItemSelected={onChange}
        resetOnSelect
    />);

}