import { useAppDispatch } from "../../../localComponents/redux/store/healthRecordStore";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { healthRecordModalsSlice } from "../../redux/slices/healthRecordModalsSlice";

interface HealthRecordSummaryIconProps {
    personId: string;
}

export const HealthRecordSummaryIcon = (props: HealthRecordSummaryIconProps) => {

    const { personId } = props;
    const dispatch = useAppDispatch();

    const openSummaryModal = () => {
        dispatch(healthRecordModalsSlice.actions.openHealthRecordSummaryModal({
            personId: personId
        }));
    }

    return (
        <i 
            className='fa fa-info-circle clickable' 
            onClick={openSummaryModal}
            title={resolveText("HealthRecordSummary")}
        />
    );

}