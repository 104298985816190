import { FormEvent, useContext, useState } from "react";
import UserContext from "../../../localComponents/contexts/UserContext";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { AsyncButton } from "../../../sharedCommonComponents/components/AsyncButton";
import { Col, Form, FormControl, FormGroup, FormText, Modal, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from '../../../localComponents/redux/store/healthRecordStore';
import { Center } from "../../../sharedCommonComponents/components/Center";
import { unlockScreen } from "../../helpers/ScreenLockHelpers";
import { formatUser } from "../../../localComponents/helpers/Formatters";

interface LockedOverlayProps {
}

export const LockedOverlay = (props: LockedOverlayProps) => {

    const user = useContext(UserContext)!;
    const isLocked = useAppSelector(state => !!state.screenLock.lockResult);
    const isUnlocking = useAppSelector(state => state.screenLock.isSubmitting);
    const [ isOnCooldown, setIsOnCooldown ] = useState<boolean>(false);
    const [ pin, setPin ] = useState<string>('');
    const dispatch = useAppDispatch();

    const unlock = (e?: FormEvent) => {
        e?.preventDefault();
        if(isOnCooldown) {
            return;
        }
        unlockScreen(pin, dispatch, () => {
            setIsOnCooldown(true);
            setTimeout(() => {
                setIsOnCooldown(false);
                document.getElementById('screen-lock-pin-input')?.focus();
            }, 3000);
        });
        setPin('');
    }

    if(!isLocked) {
        return null;
    }

    return (<Modal show={isLocked} centered fullscreen>
        <Modal.Body>
            <Row>
                <Col className="display-6">
                    <i className='fa fa-hospital-o me-2' />
                    <strong>JanKIS</strong>
                </Col>
            </Row>
            <Center className="text-center">
                <h1><i className="fa fa-lock me-2" />{resolveText("Locked")}</h1>
                <h2>{resolveText("by")} {formatUser(user)}</h2>
                <Form onSubmit={unlock}>
                    <FormGroup className="my-5">
                        <FormControl
                            type="password"
                            id='screen-lock-pin-input'
                            value={pin}
                            onChange={e => setPin(e.target.value)}
                            size="lg"
                            autoFocus
                            disabled={isOnCooldown}
                            placeholder={isOnCooldown ? '...' : resolveText("PIN")}
                            autoComplete="off"
                        />
                        {isOnCooldown
                        ? <FormText className="text-danger">
                            {resolveText("Account_IncorrectScreenLockPIN")}
                        </FormText> : null}
                    </FormGroup>
                    <AsyncButton
                        isExecuting={isUnlocking}
                        activeText={resolveText("Unlock")}
                        executingText={resolveText("Unlocking...")}
                        size="lg"
                        disabled={isOnCooldown}
                    />
                </Form>
            </Center>
        </Modal.Body>
    </Modal>);

}