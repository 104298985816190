import { FormControl, Table } from "react-bootstrap";
import { NoEntriesTableRow } from "../../../sharedCommonComponents/components/NoEntriesTableRow";

interface LetterPlaceholdersTableProps {
    placeholders: { [id:string]: string };
    onChange: (placeholder: string, value: string) => void;
}

export const LetterPlaceholdersTable = (props: LetterPlaceholdersTableProps) => {

    const { placeholders, onChange } = props;

    return (<Table>
        <tbody>
            {Object.entries(placeholders).length === 0 ? <NoEntriesTableRow colSpan={2} />
            : Object.keys(placeholders).map(id => {
                const value = placeholders[id];
                return (
                    <tr key={id}>
                        <td>
                            {id}
                        </td>
                        <td>
                            <FormControl
                                value={value}
                                onChange={e => onChange(id, e.target.value)}
                            />
                        </td>
                    </tr>
                );
            })}
        </tbody>
    </Table>);

}