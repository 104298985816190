import { Fragment, ReactNode, useMemo } from 'react';
import { OrderDirection } from '../../localComponents/types/enums';
import { Models } from '../../localComponents/types/models';
import { HorizontalLineWithText } from '../../sharedCommonComponents/components/HorizontalLineWithText';
import { groupBy } from '../../sharedCommonComponents/helpers/CollectionHelpers';
import { formatDateDifferenceAndAge } from '../helpers/DateFormatter';
import { formatDate } from '../helpers/Formatters';

interface DateDividedTimelineProps<T> {
    items: T[];
    dateSelector: (item: T) => Date;
    orderDirection?: OrderDirection;
    itemToDOM: (item: T) => ReactNode;
    noItemsDOM?: ReactNode;
    birthDate?: Date;
}

export const DateDividedTimeline = <T extends Models.IId<string>>(props: DateDividedTimelineProps<T>) => {

    const { items, orderDirection, dateSelector } = props;
    const dateGroups = useMemo(() => groupBy(items, item => formatDate(dateSelector(item))), [ items, dateSelector ]);
    const sortedGroups = useMemo(() => dateGroups
        .sort((a,b) =>  orderDirection === OrderDirection.Descending
            ? b.key.localeCompare(a.key)
            : a.key.localeCompare(b.key)
    ), [ dateGroups, orderDirection ]);

    const now = new Date();
    return (
        <>
            {sortedGroups.length > 0
            ? sortedGroups.map(dateGroup => {
                const date = new Date(dateGroup.key);
                const deltaText = formatDateDifferenceAndAge(date, props.birthDate ? new Date(props.birthDate) : undefined, now);
                const headerText = `${dateGroup.key} (${deltaText})`;
                return (
                    <Fragment key={dateGroup.key}>
                        <HorizontalLineWithText text={headerText} />
                        {dateGroup.items.map(item => <Fragment key={item.id}>{props.itemToDOM(item)}</Fragment>)}
                    </Fragment>
                )
            })
            : props.noItemsDOM}
        </>
    );

}