import { ReactNode, useState } from "react";
import { Card, Container, Row, Col, InputGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { ContactPersonsModal } from "../../../sharedHealthComponents/modals/ContactPersonsModal";
import { Models } from "../../types/models";
import { HealthRecordUrls } from "../../../sharedHealthComponents/navigation/Urls";
import { UrlIdPlaceholders } from "../../navigation/Urls";

interface PregnancyProfileJumbotronProps {
    firstName: string;
    lastName: string;
    motherPersonId?: string;
    fatherPersonId?: string;
    contactPersons: Models.Individuals.ContactPerson[];
    isDeceased?: boolean;
    medicalAlertIcon: ReactNode;
    ward: string;
    room: string;
    bed: string;
    showSubscription?: boolean;
    isSubscribed?: boolean;
    subscribe: () => void;
    unsubscribe: () => void;
}
export const PregnancyProfileJumbotron = (props: PregnancyProfileJumbotronProps) => {

    const {
        firstName,
        lastName,
        motherPersonId,
        fatherPersonId,
        contactPersons,
        isDeceased,
        medicalAlertIcon,
        ward,
        room,
        bed,
        showSubscription,
        isSubscribed,
        subscribe,
        unsubscribe
    } = props;

    const [ showContactPersonsModal, setShowContactPersonsModal ] = useState<boolean>(false);
    const navigate = useNavigate();

    return (
    <Card 
        id="basic-information"
        className={`p-3 mb-2 ${isDeceased ? 'text-light' : ''}`}
        style={{ borderRadius: '10px' }}
        bg={isDeceased ? 'secondary' : undefined}
    >
        <Card.Body>
        <Container>
            <Row>
                <Col id="basic-information-name">
                    <h2>{firstName} {lastName}</h2>
                </Col>
                {medicalAlertIcon}
                {showSubscription
                ? <Col xs="auto">
                    <InputGroup>
                        <Button 
                            variant={isSubscribed ? 'light' : 'primary'}
                            onClick={isSubscribed ? unsubscribe : subscribe}>
                            {isSubscribed ? resolveText('Subscribed') : resolveText('Subscribe')}
                        </Button>
                    </InputGroup>
                </Col>
                : null}
            </Row>
            <Row>
                <Col><b>{resolveText('Ward')}</b></Col>
                <Col>{ward}</Col>
            </Row>
            <Row>
                <Col><b>{resolveText('Room')}</b></Col>
                <Col>{room}</Col>
            </Row>
            <Row>
                <Col><b>{resolveText('Bed')}</b></Col>
                <Col>{bed}</Col>
            </Row>
            {contactPersons.length > 0 
            ? <Row id="basic-information-contactpersons">
                <Col><b>{resolveText("Person_ContactPersons")}</b></Col>
                <Col>
                    <Button
                        onClick={() => setShowContactPersonsModal(true)}
                        variant="link"
                        className='p-0'
                    >
                        {resolveText("Person_ContactPersons_ShowModal")}
                    </Button>
                    <ContactPersonsModal
                        show={showContactPersonsModal}
                        onClose={() => setShowContactPersonsModal(false)}
                        contactPersons={contactPersons}
                    />
                </Col>
            </Row> : null}
            {motherPersonId
            ? <Row>
                <Col>{resolveText('ContactPersonRole_Mother')}</Col>
                <Col>
                    <Button
                        size='sm'
                        onClick={() => navigate(HealthRecordUrls.HEALTHRECORD_BASEURL.replace(UrlIdPlaceholders.PERSON, motherPersonId))}
                    >
                        {resolveText("Open")}
                    </Button>
                </Col>
            </Row> : null}
            {fatherPersonId
            ? <Row>
                <Col>{resolveText('ContactPersonRole_Father')}</Col>
                <Col>
                    <Button
                        size='sm'
                        onClick={() => navigate(HealthRecordUrls.HEALTHRECORD_BASEURL.replace(UrlIdPlaceholders.PERSON, fatherPersonId))}
                    >
                        {resolveText("Open")}
                    </Button>
                </Col>
            </Row> : null}
        </Container>
        </Card.Body>
    </Card>
    );
}