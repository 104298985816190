import { useEffect, useMemo, useState } from "react";
import { groupBy } from "../../../sharedCommonComponents/helpers/CollectionHelpers";
import { Accordion, Col, Row } from "react-bootstrap";
import { AccordionCard } from "../../../sharedCommonComponents/components/AccordionCard";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Update } from "../../../sharedCommonComponents/types/frontendTypes";
import { DischargeLetterItemEditor } from "./DischargeLetterItemEditor";
import { Models } from "../../types/models";
import { DischargeLetterItemType } from "../../types/enums";
import { isUnfinishedLetterItem } from "../../helpers/LetterHelpers";
import { DeleteButton } from "../../../sharedCommonComponents/components/DeleteButon";

interface DischargeLetterItemGroupedAccordionCardsProps {
    items: Models.Documentation.DischargeLetterItem[];
    templateItems: { [id: string]: Models.Documentation.DischargeLetterTemplateItem };
    onChange: (itemId: string, update: Update<Models.Documentation.DischargeLetterItem>) => void;
    onItemDeleted: (itemId: string) => void;
}

export const DischargeLetterItemGroupedAccordionCards = (props: DischargeLetterItemGroupedAccordionCardsProps) => {

    const { items, templateItems, onChange, onItemDeleted } = props;

    const typeGroupedItems = useMemo(() => groupBy(items, x => x.type), [ items ]);
    const [ selectedItemType, setSelectedItemType ] = useState<DischargeLetterItemType>();
    const [ selectedItemId, setSelectedItemId ] = useState<string>();

    useEffect(() => {
        if(!selectedItemId) {
            return;
        }
        const selectedItem = items.find(x => x.id === selectedItemId);
        if(!selectedItem) {
            return;
        }
        setSelectedItemType(selectedItem.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ selectedItemId ]);

    return (<Accordion 
        activeKey={selectedItemType} 
        onSelect={key => setSelectedItemType(key as DischargeLetterItemType)}
    >
        {typeGroupedItems.map(typeGroup => {
            const isGroupUnfinished = typeGroup.items.some(isUnfinishedLetterItem);
            return (
                <AccordionCard
                    key={typeGroup.key}
                    eventKey={typeGroup.key}
                    title={resolveText(`DischargeLetterItemType_${typeGroup.key}`)}
                    bg={isGroupUnfinished ? 'danger' : undefined}
                >
                    <Accordion
                        activeKey={selectedItemId}
                        onSelect={key => setSelectedItemId(key as any)}
                    >
                        {typeGroup.items.map(item => {
                            const isItemUnfinished = isUnfinishedLetterItem(item);
                            return (
                                <AccordionCard
                                    key={item.id}
                                    eventKey={item.id}
                                    title={<Row className="w-100 align-items-center">
                                        <Col>
                                            {item.name}
                                        </Col>
                                        <Col xs="auto">
                                            <DeleteButton
                                                type="button"
                                                requireConfirm
                                                confirmDialogTitle={resolveText("DischargeLetterItem_ConfirmDelete_Title")}
                                                confirmDialogMessage={resolveText("DischargeLetterItem_ConfirmDelete_Message")}
                                                onClick={() => onItemDeleted(item.id)}
                                                size="sm"
                                            />
                                        </Col>
                                    </Row>}
                                    headerClassName="py-2"
                                    bg={isItemUnfinished ? 'danger' : undefined}
                                >
                                    <DischargeLetterItemEditor
                                        item={item}
                                        template={item.templateItemId ? templateItems[item.templateItemId] : undefined}
                                        allItems={items}
                                        onChange={onChange}
                                        onNavigateToOtherItem={setSelectedItemId}
                                    />
                                </AccordionCard>
                            );
                        })}
                    </Accordion>
                </AccordionCard>
            );
        })}
    </Accordion>);

}