export const MenschIdPattern = "[0-9]{8}-[A-Z0-9]{5}";

export const isMenschId = (personId: string) => {
    return /[0-9]{4}[0-1][0-9][0-3][0-9]-[A-Z0-9]{5}/.test(personId.toUpperCase());
}
export const getBirthDateFromMenschId = (personId: string): Date => {
    if(!isMenschId(personId)) {
        throw new Error("ID is not a mensch.ID. Cannot extract birth date");
    }
    return `${personId.substring(0, 4)}-${personId.substring(4, 6)}-${personId.substring(6, 8)}` as any;
}