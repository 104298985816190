import { createSlice } from "@reduxjs/toolkit";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { RootState } from "../store/healthRecordStore";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";

export interface LetterTemplatesState extends RemoteState<Models.Documentation.DischargeLetterTemplate,{}> {}
const initialState: LetterTemplatesState = {
    ...defaultRemoteInitialState(),
};
export const letterTemplatesSlice = createSlice({
    name: 'letterTemplates',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState)
    }
});
const letterTemplateFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
const letterTemplateQueryBuilder = (state: RootState, sliceState: LetterTemplatesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, letterTemplateFilterComparer);
    return queryParams;
}
export const letterTemplatesActions = {
    ...createRestApiActions(
        'letterTemplates',
        letterTemplatesSlice.actions,
        state => state.letterTemplates,
        letterTemplateQueryBuilder,
        letterTemplateFilterComparer
    ),
}