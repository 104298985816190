import { ChecklistUrls } from "../../../checklist/navigation/Urls";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { formatDepartment } from "../../../sharedHealthComponents/helpers/Formatters";
import { SharedUrls, ToolsUrls } from "../../../sharedHealthComponents/navigation/Urls";
import { hasGlobalPermission } from "../../helpers/PermissionsHelpers";
import { JanKisUrls, UrlIdPlaceholders } from "../../navigation/Urls";
import { AccountType, DepartmentType, NavigationContextType, Permission } from "../../types/enums";
import { Models } from "../../types/models";
import { ViewModels } from "../../types/viewModels";

export interface MenuItem {
    displayName: string;
    url: string;
}
export interface MenuItemGroup {
    title: string;
    items: MenuItem[];
}

export const getMenuItems = (
    features: Models.Configuration.FeatureSettings,
    user: ViewModels.IUserViewModel,
    context?: Models.Navigation.NavigationContext
) => {
    const categories: MenuItemGroup[] = [];

    const commonGroup: MenuItemGroup = {
        title: resolveText('Menu_Common'),
        items: [
            {
                displayName: resolveText("Menu_KnowledgeBase"),
                url: JanKisUrls.KNOWLEDGEBASE
            }
        ]
    };
    if(features.enableAdverseEvents) {
        commonGroup.items.push({
            displayName: resolveText('Menu_AdverseEvents'),
            url: SharedUrls.ADVERSE_EVENTS
        });
    }
    if(features.enableContactList) {
        commonGroup.items.push({
            displayName: resolveText('Menu_Contacts'),
            url: JanKisUrls.CONTACTS
        });
    }
    if(features.enableWarehousing) {
        commonGroup.items.push({
            displayName: resolveText('Menu_Consumables'),
            url: JanKisUrls.CONSUMABLES
        });
        commonGroup.items.push({
            displayName: resolveText('Menu_Resources'),
            url: JanKisUrls.RESOURCES
        });
        commonGroup.items.push({
            displayName: resolveText('Menu_MaterialOrders'),
            url: JanKisUrls.MATERIALORDERS
        });
    }
    if(features.enableTaskList) {
        commonGroup.items.push({
            displayName: resolveText("Tasks"),
            url: SharedUrls.TODOLIST
        });
    }
    if(features.enableServices) {
        commonGroup.items.push({
            displayName: resolveText("Services"),
            url: SharedUrls.SERVICES
        });
    }
    commonGroup.items.push({
        displayName: resolveText('Menu_TranslatedChat'),
        url: ToolsUrls.MY_CHATS
    });
    categories.push(commonGroup);

    const configGroup: MenuItemGroup = {
        title: resolveText('Menu_Config'),
        items: [
            {
                displayName: resolveText('Menu_Config_Institutions'),
                url: SharedUrls.INSTITUTIONS
            },
            {
                displayName: resolveText('Menu_Config_Departments'),
                url: SharedUrls.DEPARTMENTS
            },
            {
                displayName: resolveText('Menu_Config_Accounts'),
                url: JanKisUrls.ACCOUNTS
            },
            {
                displayName: resolveText('Menu_Config_Roles'),
                url: JanKisUrls.ROLES
            },
            {
                displayName: resolveText('Menu_Config_FunctionalGroups'),
                url: JanKisUrls.FUNCTIONAL_GROUPS
            },
            {
                displayName: resolveText('Menu_Config_PrintTemplates'),
                url: SharedUrls.PRINT_TEMPLATES
            }
        ]
    };
    if(features.healthRecord.enableMedication) {
        configGroup.items.push({
            displayName: resolveText('Menu_Config_Drugs'),
            url: SharedUrls.DRUGS
        });
    }
    if(features.healthRecord.enableQuestionnaires) {
        configGroup.items.push({
            displayName: resolveText('Menu_Config_Questionnaires'),
            url: SharedUrls.QUESTIONNAIRES
        });
    }
    if(features.enableTaskList) {
        configGroup.items.push({
            displayName: resolveText('Menu_Config_TasksPackages'),
            url: SharedUrls.TASK_PACKAGES
        });
        configGroup.items.push({
            displayName: resolveText('Menu_Config_Checklists'),
            url: ChecklistUrls.CHECKLISTS
        });
    }
    if(features.healthRecord.enableTestResults) {
        configGroup.items.push({
            displayName: resolveText('Menu_Config_TestPackages'),
            url: SharedUrls.LABTEST_PACKAGES
        });
    }
    if(features.enableDataImportExport) {
        configGroup.items.push({
            displayName: resolveText('Menu_Config_ExportPatientData'),
            url: SharedUrls.EXPORT_PATIENTDATA
        });
    }
    configGroup.items.push({
        displayName: resolveText('Menu_Config_EquipmentMeasurements'),
        url: SharedUrls.EQUIPMENT_MEASUREMENTS
    });
    configGroup.items.push({
        displayName: resolveText("Menu_Config_DischargeLetterTemplates"),
        url: JanKisUrls.LETTERTEMPLATES
    });
    if(hasGlobalPermission(user, Permission.HandleFeedback)) {
        configGroup.items.push({
            displayName: resolveText('Feedback'),
            url: SharedUrls.FEEDBACK
        });
    }
    categories.push(configGroup);

    const departmentsGroup: MenuItemGroup = {
        title: resolveText('Menu_Department'),
        items: [
            {
                displayName: resolveText('Rooms'),
                url: JanKisUrls.ROOMS
            },
            {
                displayName: resolveText('MyPatients'),
                url: JanKisUrls.MY_PATIENTS
            }
        ]
    };
    categories.push(departmentsGroup);

    if(context?.type === NavigationContextType.Department) {
        if(user.accountType === AccountType.Employee) {
            const employee = user as ViewModels.EmployeeUserViewModel;
            const matchingDepartment = employee.departments.find(x => x.id === context.entityId);
            if(matchingDepartment) {
                const contextDepartmentGroup = getDepartmentMenu(matchingDepartment, features, user);
                categories.push(contextDepartmentGroup);
            }
        }
    }

    const registrationGroup: MenuItemGroup = {
        title: resolveText('Menu_Registration'),
        items: [
            {
                displayName: resolveText('Menu_PatientList'),
                url: JanKisUrls.PERSONS
            },
            {
                displayName: resolveText('Menu_CreatePatient'),
                url: JanKisUrls.CREATE_PERSON
            },
            {
                displayName: resolveText('Menu_ShehrdImport'),
                url: JanKisUrls.SHEHRD_SYNC
            },
            {
                displayName: resolveText('Menu_Checkin'),
                url: JanKisUrls.CHECKIN_TERMINAL
            },
            {
                displayName: resolveText('Menu_ListOfAdmissions'),
                url: JanKisUrls.ENCOUNTERS
            },
            {
                displayName: resolveText('Menu_Admit'),
                url: JanKisUrls.CREATE_ENCOUNTER
            },
            {
                displayName: resolveText('Menu_QuickAdmit'),
                url: JanKisUrls.QUICK_ADMIT
            },
            {
                displayName: resolveText('Menu_ScheduleBeds'),
                url: JanKisUrls.ROOMS
            },
            {
                displayName: resolveText('Menu_Appointments'),
                url: SharedUrls.APPOINTMENTS
            },
            {
                displayName: resolveText('Menu_NewAppointment'),
                url: SharedUrls.CREATE_APPOINTMENT
            }
        ]
    };
    categories.push(registrationGroup);

    const controllingGroup: MenuItemGroup = {
        title: resolveText("Menu_Financial"),
        items: [
            {
                displayName: resolveText("Menu_Invoices"),
                url: JanKisUrls.INVOICES
            }
        ]
    };
    categories.push(controllingGroup);

    const myAccountGroup: MenuItemGroup = {
        title: resolveText("MyAccount"),
        items: [
            {
                displayName: resolveText("MyAccount"),
                url: SharedUrls.MY_ACCOUNT
            }
        ]
    };
    categories.push(myAccountGroup);

    return categories;
}


export const getDepartmentMenu = (
    department: Models.Organizations.Department,
    features: Models.Configuration.FeatureSettings,
    user: ViewModels.IUserViewModel
) => {
    const departmentGroup: MenuItemGroup = {
        title: (department as ViewModels.DepartmentViewModel)?.institution !== undefined 
            ? formatDepartment(department as ViewModels.DepartmentViewModel) 
            : department.name,
        items: []
    };
    if(department.departmentType === DepartmentType.Clinics) {
        departmentGroup.items.push({
            displayName: resolveText('WaitingRoom'),
            url: JanKisUrls.WAITINGROOM.replace(UrlIdPlaceholders.DEPARTMENT, department.id)
        });
    }
    if(department.departmentType === DepartmentType.Ward) {
        departmentGroup.items.push({
            displayName: resolveText('Rooms'),
            url: JanKisUrls.INSTITUTION_DEPARTMENT_ROOMS
                .replace(UrlIdPlaceholders.INSTITUTION, department.institutionId)
                .replace(UrlIdPlaceholders.DEPARTMENT, department.id)
        });
    } else if(department.departmentType === DepartmentType.MealService) {
        departmentGroup.items.push({
            displayName: resolveText('MealMenus'),
            url: JanKisUrls.MEALSERVICE_MENUS.replace(UrlIdPlaceholders.DEPARTMENT, department.id)
        });
    }
    if(features.enableServices) {
        departmentGroup.items.push({
            displayName: resolveText('Services'),
            url: SharedUrls.DEPARTMENT_SERVICES.replace(UrlIdPlaceholders.DEPARTMENT, department.id)
        });
        departmentGroup.items.push({
            displayName: resolveText('ServiceRequests'),
            url: SharedUrls.DEPARTMENT_SERVICEREQUESTS.replace(UrlIdPlaceholders.DEPARTMENT, department.id)
        });
    }
    if(features.enableWarehousing) {
        departmentGroup.items.push({
            displayName: resolveText('Stocks'),
            url: JanKisUrls.DEPARTMENT_STOCKS.replace(UrlIdPlaceholders.DEPARTMENT, department.id)
        });
    }
    if(features.enableTaskList) {
        departmentGroup.items.push({
            displayName: resolveText('TodoList'),
            url: SharedUrls.TODOLIST + `?mode=Department&departmentId=${department.id}`
        });
    }
    if(features.healthRecord.enableProcedures) {
        departmentGroup.items.push({
            displayName: resolveText('DepartmentPatientJourneys'),
            url: JanKisUrls.DEPARTMENT_PATIENTJOURNEYS.replace(UrlIdPlaceholders.DEPARTMENT, department.id)
        });
    }
    return departmentGroup;
}