import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { loadItemActionBuilder, postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { LoadItemArgs, RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ViewModels } from "../../types/viewModels";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";
import { RootState } from "../store/healthRecordStore";
import { Models } from "../../types/models";
import { isBefore, isAfter } from "date-fns";
import { BedOccupancyFilter } from "../../../sharedHealthComponents/types/frontendTypes";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";

export interface BedOccupanciesState extends RemoteState<ViewModels.BedOccupancyViewModel, BedOccupancyFilter> {
}

const initialState: BedOccupanciesState = {
    ...defaultRemoteInitialState(),
};

export const bedOccupanciesSlice = createSlice({
    name: 'bedOccupancies',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        swapBedOccupancies: (state, action: PayloadAction<Models.BedManagement.BedOccupancySwapResponse>) => {
            const { request, newBedOccupanciesAtSource, newBedOccupanciesAtTarget } = action.payload;
            const sourceBed = request.sourceBed;
            const targetBed = request.targetBed;

            // End current bed occupancies
            const currentSourceBedOccupancies = state.items.filter(x => x.roomId === sourceBed.roomId && x.bedPosition === sourceBed.bedPosition);
            const currentTargetBedOccupancies = state.items.filter(x => x.roomId === targetBed.roomId && x.bedPosition === targetBed.bedPosition);
            const now = new Date().toISOString() as any;
            for (const bedOccupancy of currentSourceBedOccupancies.concat(currentTargetBedOccupancies)) {
                bedOccupancy.endTime = now;
            }

            // Add new bed occupancies
            state.items = state.items.concat(newBedOccupanciesAtSource).concat(newBedOccupanciesAtTarget);
        }
    }
});

const bedOccupanciesFilterComparer = (f1?: BedOccupancyFilter, f2?: BedOccupancyFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.timeRangeStart === f2!.timeRangeStart
        && f1!.timeRangeEnd === f2!.timeRangeEnd;
}
const bedOccupanciesQueryBuilder = (state: RootState, sliceState: BedOccupanciesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, bedOccupanciesFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.timeRangeStart) {
            queryParams.push({ key: 'timeRangeStart', value: filter.timeRangeStart as any });
        }
        if(filter.timeRangeEnd) {
            queryParams.push({ key: 'timeRangeEnd', value: filter.timeRangeEnd as any });
        }
    }
    return queryParams;
}
export const bedOccupanciesActions = {
    ...createRestApiActions(
        'bedOccupancies', 
        bedOccupanciesSlice.actions,
        state => state.bedOccupancies,
        defaultQueryBuilder,
        _ => true
    ),
    loadBedOccupanciesForPerson: loadItemActionBuilder(
        (args: LoadItemArgs) => `api/persons/${args!.itemId}/bedOccupancies`,
        state => bedOccupanciesQueryBuilder(state, state.bedOccupancies),
        () => resolveText("BedOccupancies_CouldNotLoad"),
        bedOccupanciesSlice.actions.setIsLoading,
        bedOccupanciesSlice.actions.setItems
    ),
    loadBedOccupanciesForInstitution: loadItemActionBuilder(
        args => `api/institutions/${args.itemId}/bedoccupancies`,
        state => bedOccupanciesQueryBuilder(state, state.bedOccupancies),
        () => resolveText("BedOccupancies_CouldNotLoad"),
        bedOccupanciesSlice.actions.setIsLoading,
        bedOccupanciesSlice.actions.addOrUpdateItems
    ),
    loadBedOccupanciesForDepartment: loadItemActionBuilder(
        args => `api/departments/${args.itemId}/bedoccupancies`,
        state => bedOccupanciesQueryBuilder(state, state.bedOccupancies),
        () => resolveText("BedOccupancies_CouldNotLoad"),
        bedOccupanciesSlice.actions.setIsLoading,
        bedOccupanciesSlice.actions.addOrUpdateItems
    ),
    loadBedOccupanciesForRoom: loadItemActionBuilder(
        args => `api/rooms/${args.itemId}/bedoccupancies`,
        state => bedOccupanciesQueryBuilder(state, state.bedOccupancies),
        () => resolveText("BedOccupancies_CouldNotLoad"),
        bedOccupanciesSlice.actions.setIsLoading,
        bedOccupanciesSlice.actions.addOrUpdateItems
    ),
    swapBeds: postActionBuilder<any, Models.RequestBodies.BedOccupancySwapRequest>(
        _ => `api/bedOccupancies/swap`,
        () => resolveText("BedOccupancy_CouldNotMove"),
        bedOccupanciesSlice.actions.setIsSubmitting,
        (dispatch,response,_) => dispatch(bedOccupanciesSlice.actions.swapBedOccupancies(response))
    )
};
const isCurrentBedOccupancy = (x: Models.BedManagement.BedOccupancy, personId: string, now: Date) => {
    if(x.personId !== personId) {
        return false;
    }
    if(isBefore(now, new Date(x.startTime))) {
        return false;
    }
    if(x.endTime && isAfter(now, new Date(x.endTime))) {
        return false;
    }
    return true;
}
export interface SelectBedOccupanciesForRoomArgs {
    roomId?: string;
    bedPosition?: string;
    now: Date;
}
export interface SelectCurrentBedOccupanciesForPersonArgs {
    personId: string;
    now: Date;
}
export const bedOccupanciesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.bedOccupancies),
    createSelectForDepartment: () => createSelector(
        (state: RootState) => state.bedOccupancies.items,
        (_: RootState, args: { departmentId: string | undefined }) => args.departmentId,
        (items, departmentId) => departmentId 
            ? items.filter(x => x.departmentId === departmentId)
            : []
    ),
    createSelectForRoom: () => createSelector(
        (state: RootState) => state.bedOccupancies.items,
        (_: RootState, args: SelectBedOccupanciesForRoomArgs) => args.roomId,
        (_: RootState, args: SelectBedOccupanciesForRoomArgs) => args.bedPosition,
        (_: RootState, args: SelectBedOccupanciesForRoomArgs) => args.now.getTime(),
        (items, roomId, bedPosition, now) => roomId && now
            ? items.filter(x => 
                x.room?.id === roomId
                && (!bedPosition || x.bedPosition === bedPosition)
                && (!x.endTime || new Date(x.endTime).getTime() > now))
            : []
    ),
    createSelectCurrentForPerson: () => createSelector(
        (state: RootState) => state.bedOccupancies.items,
        (_: RootState, args: SelectCurrentBedOccupanciesForPersonArgs) => args.personId,
        (_: RootState, args: SelectCurrentBedOccupanciesForPersonArgs) => args.now,
        (items, personId, now) => personId && now
            ? items.find(x => isCurrentBedOccupancy(x, personId, now))
            : undefined
    ),
};