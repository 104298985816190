import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, FormGroup, ListGroup, Row } from 'react-bootstrap';
import { ViewModels } from '../../localComponents/types/viewModels';
import { apiClient } from '../../sharedCommonComponents/communication/ApiClient';
import { showErrorAlert } from '../../sharedCommonComponents/helpers/AlertHelpers';
import { resolveText } from '../../sharedCommonComponents/helpers/Globalizer';
import { buildLoadObjectFunc } from '../../sharedCommonComponents/helpers/LoadingHelpers';
import { SnomedCtAutoComplete } from './Autocompletes/SnomedCtAutoComplete';

interface SnomedCtEntryFinderProps {
    initialConceptId?: string;
    concept?: ViewModels.SnomedCtConceptViewModel;
    showSelectButton?: boolean;
    onConceptSelected?: (concept: ViewModels.SnomedCtConceptViewModel) => void;
}

export const SnomedCtEntryFinder = (props: SnomedCtEntryFinderProps) => {

    const [ selectedConcept, setSelectedConcept ] = useState<ViewModels.SnomedCtConceptViewModel | undefined>(props.concept);
    const [ isLoadingGenerlizedConcepts, setIsLoadingGeneralizedConcepts ] = useState<boolean>(false);
    const [ generalizedConcepts, setGeneralizedConcepts ] = useState<ViewModels.SnomedCtConceptViewModel[]>([]);
    const [ isLoadingSpecializedConcepts, setIsLoadingSpecializedConcepts ] = useState<boolean>(false);
    const [ specializedConcepts, setSpecializedConcepts ] = useState<ViewModels.SnomedCtConceptViewModel[]>([]);

    useEffect(() => {
        if(!props.concept) {
            return;
        }
        if(selectedConcept && props.concept.id === selectedConcept.id) {
            return;
        }
        setSelectedConcept(props.concept);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.concept ]);

    useEffect(() => {
        if(!props.initialConceptId) {
            return;
        }
        if(props.concept) {
            return; // Don't load anything if concept is provided
        }
        const loadConcept = buildLoadObjectFunc(
            `api/classifications/snomedct/${props.initialConceptId}`, {},
            resolveText("SnomedCt_CouldNotLoad"),
            setSelectedConcept
        );
        loadConcept();
    }, [ props.initialConceptId ]);

    const showSelectButton = props.showSelectButton;
    const onConceptSelected = props.onConceptSelected;
    useEffect(() => {
        if(!selectedConcept) {
            setGeneralizedConcepts([]);
            setSpecializedConcepts([]);
            return;
        }
        if(!showSelectButton && onConceptSelected) {
            onConceptSelected(selectedConcept);
        }
        if(selectedConcept.hasSubConcepts) {
            loadSpecializedConcepts(selectedConcept.id);
        } else {
            setSpecializedConcepts([]);
        }
        loadGeneralizedConcepts(selectedConcept.id);
    }, [ selectedConcept, showSelectButton, onConceptSelected ]);

    const loadGeneralizedConcepts = async (conceptId: number) => {
        setIsLoadingGeneralizedConcepts(true);
        try {
            const response = await apiClient.instance!.get(`api/classifications/snomedct/${conceptId}/generalize`, {});
            const items = await response.json() as ViewModels.SnomedCtConceptViewModel[];
            setGeneralizedConcepts(items);
        } catch(error: any) {
            showErrorAlert(resolveText("SnomedCt_CouldNotLoadGeneralizedConcepts"));
        } finally {
            setIsLoadingGeneralizedConcepts(false);
        }
    }

    const loadSpecializedConcepts = async (conceptId: number) => {
        setIsLoadingSpecializedConcepts(true);
        try {
            const response = await apiClient.instance!.get(`api/classifications/snomedct/${conceptId}/specialize`, {});
            const items = await response.json() as ViewModels.SnomedCtConceptViewModel[];
            setSpecializedConcepts(items);
        } catch(error: any) {
            showErrorAlert(resolveText("SnomedCt_CouldNotLoadSpecializedConcepts"));
        } finally {
            setIsLoadingSpecializedConcepts(false);
        }
    }

    return (
        <>
            <FormGroup>
                <h3>{resolveText("SnomedCt_BrowseSnomedCtEntries")}</h3>
                <SnomedCtAutoComplete
                    onChange={setSelectedConcept}
                    resetOnSelect
                    hideBrowseButton
                />
            </FormGroup>
            <Row className='mt-3'>
                <Col>
                    <ConceptsCard
                        isLoading={isLoadingGenerlizedConcepts}
                        title={resolveText("SnomedCt_GeneralizedConcepts")}
                        concepts={generalizedConcepts}
                        onConceptSelected={setSelectedConcept}
                    />
                </Col>
                <Col>
                    {selectedConcept
                    ? <Card bg='info' className='text-white fw-bold'>
                        <Card.Header>
                            {resolveText("SnomedCt_SelectedConcept")}
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>{resolveText("SnomedCt_ConceptID")}:</Col>
                                <Col>{selectedConcept.id}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    {resolveText("SnomedCt_Terms")}:
                                    <ul>
                                        {selectedConcept.terms.map((term,idx) => (
                                            <li key={idx}>{term}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            {showSelectButton && onConceptSelected
                            ? <div className='text-end'>
                                <Button
                                    onClick={() => onConceptSelected(selectedConcept)}
                                    size='sm'
                                >
                                    {resolveText("Select")}
                                </Button>
                            </div>
                            : null}
                        </Card.Body>
                    </Card>
                    : <Alert variant='warning'>
                        {resolveText("SnomedCt_NoConceptSelected")}
                    </Alert>}
                </Col>
                <Col>
                    <ConceptsCard
                        isLoading={isLoadingSpecializedConcepts}
                        title={resolveText("SnomedCt_SpecializedConcepts")}
                        concepts={specializedConcepts}
                        onConceptSelected={setSelectedConcept}
                    />
                </Col>
            </Row>
        </>
    );

}
interface ConceptsCardProps {
    isLoading?: boolean;
    title: string;
    concepts: ViewModels.SnomedCtConceptViewModel[];
    onConceptSelected: (concept: ViewModels.SnomedCtConceptViewModel) => void;
}
const ConceptsCard = (props: ConceptsCardProps) => {
    if(props.isLoading) {
        return (
            <Alert variant="info">
                {resolveText("Loading...")}
            </Alert>
        );
    }
    if(!props.concepts || props.concepts.length === 0) {
        return (
            <Alert variant='info'>
                {resolveText("NoFurtherEntries")}
            </Alert>
        );
    }

    return (
        <Card>
            <Card.Header>{props.title}</Card.Header>
            <Card.Body>
                <ListGroup className='list-group-hover'>
                    {props.concepts.map(concept => (
                        <ListGroup.Item 
                            key={concept.id}
                            onClick={() => props.onConceptSelected(concept)}
                            className="clickable"
                        >
                            {concept.terms[0]}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Card.Body>
        </Card>
    )
}