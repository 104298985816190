import { Alert, Button, Card, Col, Container, InputGroup, Row } from 'react-bootstrap';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { formatDate } from '../../../sharedHealthComponents/helpers/Formatters';
import { useAppDispatch } from '../../redux/store/healthRecordStore';
import { ContactPersonRole } from '../../types/enums';
import { subscriptionsActions, subscriptionsSelectors } from '../../redux/slices/subscriptionsSlice';
import { useEffect, useMemo, useState } from 'react';
import { bedOccupanciesActions, bedOccupanciesSelectors } from '../../redux/slices/bedOccupanciesSlice';
import { formatAge } from '../../../sharedHealthComponents/helpers/DateFormatter';
import { isPregnancyPersonId } from '../../../sharedHealthComponents/helpers/PregnancyHelpers';
import { isBirthday } from '../../../sharedCommonComponents/helpers/DateHelpers';
import { useNavigate } from 'react-router-dom';
import { ContactPersonsModal } from '../../../sharedHealthComponents/modals/ContactPersonsModal';
import { modalSlice } from '../../redux/slices/modalSlice';
import { IsolationModal } from '../../modals/IsolationModal';
import { MedicalAlertIcon } from '../../../sharedHealthComponents/components/Diagnoses/MedicalAlertIcon';
import { HealthRecordSummaryIcon } from '../../../sharedHealthComponents/components/Patients/HealthRecordSummaryIcon';
import { isMenschId } from '../../../sharedHealthComponents/helpers/MenschIdHelpers';
import { CopyButton } from '../../../sharedCommonComponents/components/CopyButton';
import { healthRecordModalsSlice } from '../../../sharedHealthComponents/redux/slices/healthRecordModalsSlice';
import { AdditionalPersonInformationModal } from '../../../sharedHealthComponents/modals/AdditionalPersonInformationModal';
import { useMemorizedSelector } from '../../../sharedHealthComponents/redux/helpers/ReduxHooks';
import { personsActions, personsSelectors } from '../../../sharedHealthComponents/redux/slices/personsSlice';
import { PregnancyProfileJumbotron } from './PregnancyProfileJumbotron';
import { isolationsActions, isolationsSelectors, isolationsSlice } from '../../../sharedHealthComponents/redux/slices/isolationsSlice';
import { HealthRecordUrls } from '../../../sharedHealthComponents/navigation/Urls';
import { UrlIdPlaceholders } from '../../navigation/Urls';

interface PatientProfileJumbotronProps {
    personId: string;
    showSubscription?: boolean
}

export const PatientProfileJumbotron = (props: PatientProfileJumbotronProps) => {

    const { personId, showSubscription } = props;
    const profileData = useMemorizedSelector(personsSelectors.getById, { id: personId });
    const isPregnancyProfile = useMemo(() => isPregnancyPersonId(personId), [ personId ]);
    const isDeceased = !!profileData?.deceasedDate;
    const firstName = profileData?.firstName ?? '';
    const lastName = profileData?.lastName ?? '';
    const birthDate = !isPregnancyProfile && profileData?.birthDate ? new Date(profileData.birthDate) : undefined;
    const deceasedDate = !isPregnancyProfile && isDeceased ? new Date(profileData.deceasedDate!) : undefined;
    const now = new Date();
    const hasBirthdayToday = birthDate && isBirthday(now, birthDate);
    const motherPersonId = profileData?.contactPersons.find(x => x.role === ContactPersonRole.Mother)?.personId ?? undefined;
    const fatherPersonId = profileData?.contactPersons.find(x => x.role === ContactPersonRole.Father)?.personId ?? undefined;
    const bedOccupancy = useMemorizedSelector(
        bedOccupanciesSelectors.createSelectCurrentForPerson,
        { personId, now }
    );
    const ward = bedOccupancy?.departmentName ?? `(${resolveText('NotAdmitted')})`;
    const room = bedOccupancy?.room.name ?? `(${resolveText('NotAdmitted')})`;
    const bed = bedOccupancy?.bedPosition ?? `(${resolveText('NotAdmitted')})`;
    const isolations = useMemorizedSelector(isolationsSelectors.createSelectForPerson, { personId });
    const height = "--";
    const weight = "--";
    const contactPersons = profileData?.contactPersons ?? [];
    const [ showContactPersonsModal, setShowContactPersonsModal ] = useState<boolean>(false);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    useEffect(() => {
        if(!personId) {
            return;
        }
        dispatch(personsActions.loadItemIfNotLoadedYet({
            args: {
                itemId: personId
            }
        }));
        dispatch(isolationsSlice.actions.patchFilter({ onlyActive: true }));
        dispatch(isolationsActions.loadItems({
            args: {
                personId: personId
            }
        }));
        dispatch(bedOccupanciesActions.loadBedOccupanciesForPerson({ 
            args: { 
                itemId: personId 
            }
        }));
        dispatch(subscriptionsActions.loadForPatient({ 
            args: {
                personId: personId 
            }
        }));
    }, [ personId, dispatch ]);

    const subscription = useMemorizedSelector(
        subscriptionsSelectors.createSelectForPerson,
        { personId }
    );
    const isSubscribed = !!subscription;

    const subscribe = () => {
        dispatch(subscriptionsActions.subscribeToPerson(personId));
    }
    const unsubscribe = () => {
        dispatch(subscriptionsActions.unsubscribeFromPerson(personId));
    }

    const medicalAlertIcon = (<Col xs="auto">
        <MedicalAlertIcon personId={personId} />
    </Col>);

    if(isPregnancyProfile) {
        return (<PregnancyProfileJumbotron
            firstName={firstName}
            lastName={lastName}
            contactPersons={contactPersons}
            motherPersonId={motherPersonId}
            fatherPersonId={fatherPersonId}
            isDeceased={isDeceased}
            medicalAlertIcon={medicalAlertIcon}
            ward={ward}
            room={room}
            bed={bed}
            showSubscription={showSubscription}
            isSubscribed={isSubscribed}
            subscribe={subscribe}
            unsubscribe={unsubscribe}
        />);
    }

    return (
        <Card 
            id="basic-information"
            className={`p-3 mb-2 ${isDeceased ? 'text-light' : ''}`}
            style={{ borderRadius: '10px' }}
            bg={isDeceased ? 'secondary' : undefined}
        >
            <Card.Body>
            <Container>
                <Row className='align-items-center'>
                    <Col id="basic-information-name">
                        <h2>{firstName} {lastName}</h2>
                    </Col>
                    {medicalAlertIcon}
                    <Col xs="auto">
                        <HealthRecordSummaryIcon
                            personId={personId}
                        />
                    </Col>
                    <Col xs="auto">
                        <i 
                            className='fa fa-edit clickable' 
                            onClick={() => navigate(HealthRecordUrls.EDIT_PERSON.replace(UrlIdPlaceholders.PERSON, personId))} 
                        />
                    </Col>
                    {props.showSubscription
                    ? <Col xs="auto">
                        <InputGroup>
                            <Button 
                                variant={isSubscribed ? 'light' : 'primary'}
                                onClick={isSubscribed ? unsubscribe : subscribe}>
                                {isSubscribed ? resolveText('Subscribed') : resolveText('Subscribe')}
                            </Button>
                        </InputGroup>
                    </Col>
                    : null}
                </Row>
                {isolations && isolations.length > 0
                ? <Alert 
                    variant='danger' 
                    className='mt-1 clickable'
                    onClick={() => dispatch(modalSlice.actions.openIsolationModal({
                        person: profileData!,
                        isolations: isolations
                    }))}
                >
                    <Row className='align-items-center'>
                        <Col xs="auto">
                            <i 
                                className='fa fa-exclamation-triangle red'
                            />
                            <span className='ms-2'>
                                <strong>{resolveText("Isolation")}:</strong>
                            </span>
                        </Col>
                        <Col>
                            {isolations.map(x => x.reason).join(", ")}
                        </Col>
                        <Col xs="auto">
                            <Button
                                variant='link'
                                size='sm'
                                className='my-0 py-0'
                            >
                                {resolveText("ClickForDetails")}
                            </Button>
                        </Col>
                    </Row>
                </Alert> : null}
                {isMenschId(personId)
                ? <Row className="mb-1" id="basic-information-id">
                    <Col xs={4}>
                        {resolveText('Person_Id')}
                    </Col>
                    <Col>
                        <strong>{personId}</strong>
                        <CopyButton
                            size="xs"
                            className="mx-2 py-1"
                            value={personId}
                        />
                    </Col>
                </Row> : null}
                {birthDate
                ? <Row className="mb-1" id="basic-information-birthdate">
                    <Col xs={4}>
                        {resolveText('Person_BirthDate')}
                    </Col>
                    <Col>
                        {hasBirthdayToday ? <>&#127881;</> : null} {formatDate(birthDate)} {!isDeceased ? `(${formatAge(now, birthDate)})` : null}
                    </Col>
                </Row> : null}
                {deceasedDate
                ? <Row className="mb-1" id="basic-information-deceaseddate">
                    <Col xs={4}>
                        {resolveText('Person_DeceasedDate')}
                    </Col>
                    <Col>
                        {formatDate(deceasedDate)} {birthDate ? `(${formatAge(deceasedDate, birthDate)})` : null}
                    </Col>
                </Row> : null}
                <Row className="mb-2" id="basic-information-additionalinformation">
                    <Col xs={4}>{resolveText("Person_AdditionalInformations")}</Col>
                    <Col>
                        <Button
                            onClick={() => dispatch(healthRecordModalsSlice.actions.openAdditionalPersonInformationModal({
                                personId: personId
                            }))}
                            variant="link"
                            className='p-0'
                        >
                            {resolveText("Person_AdditionalInformations_ShowModal")}
                        </Button>
                        <AdditionalPersonInformationModal />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ border: '2px solid black', width: '150px', height: '200px' }} className="text-center">
                            Image
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            <Col><b>{resolveText('Ward')}</b></Col>
                            <Col>{ward}</Col>
                        </Row>
                        <Row>
                            <Col><b>{resolveText('Room')}</b></Col>
                            <Col>{room}</Col>
                        </Row>
                        <Row>
                            <Col><b>{resolveText('Bed')}</b></Col>
                            <Col>{bed}</Col>
                        </Row>
                        <Row>
                            <Col><b>{resolveText('Patient_Height')}</b></Col>
                            <Col>{height}</Col>
                        </Row>
                        <Row>
                            <Col><b>{resolveText('Patient_Weight')}</b></Col>
                            <Col>{weight}</Col>
                        </Row>
                        {contactPersons.length > 0 
                        ? <Row id="basic-information-contactpersons">
                            <Col><b>{resolveText("Person_ContactPersons")}</b></Col>
                            <Col>
                                <Button
                                    onClick={() => setShowContactPersonsModal(true)}
                                    variant="link"
                                    className='p-0'
                                >
                                    {resolveText("Person_ContactPersons_ShowModal")}
                                </Button>
                                <ContactPersonsModal
                                    show={showContactPersonsModal}
                                    onClose={() => setShowContactPersonsModal(false)}
                                    contactPersons={contactPersons}
                                />
                            </Col>
                        </Row> : null}
                    </Col>
                </Row>
            </Container>
            <IsolationModal />
            </Card.Body>
        </Card>
    );

}