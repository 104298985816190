import { Models } from "../../../localComponents/types/models";
import { GenericIdAutocomplete } from "../../../sharedCommonComponents/components/Autocompletes/GenericIdAutocomplete";
import { IdAutocompleteProps } from "../../../sharedCommonComponents/types/frontendTypes";
import { PersonAutocomplete } from "./PersonAutocomplete";

interface PersonIdAutocompleteProps extends IdAutocompleteProps {
    value: string | undefined;
    onChange: (value: string | undefined) => void;
    disabled?: boolean;
    required?: boolean;
}

export const PersonIdAutocomplete = (props: PersonIdAutocompleteProps) => {

    return (
    <GenericIdAutocomplete<Models.Individuals.Person>
        {...props}
        loadItemApiPathBuilder={id => `api/persons/${id}`}
        autocompleteBuilder={(key,props) => (
            <PersonAutocomplete
                key={key}
                {...props}
            />
        )}
    />
    );

}
export default PersonIdAutocomplete;