import { useContext } from 'react';
import { Button, Col, Nav, NavDropdown, NavItem, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import UserContext from '../../contexts/UserContext';
import { useAppDispatch } from '../../redux/store/healthRecordStore';
import { lockScreen } from '../../../sharedHealthComponents/helpers/ScreenLockHelpers';
import { getPerson } from '../../helpers/UserHelpers';
import { SharedUrls } from '../../../sharedHealthComponents/navigation/Urls';

interface LoggedInUserProps {
    onLogOut: () => void;
}

export const LoggedInUser = (props: LoggedInUserProps) => {

    const { onLogOut } = props;
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <Nav className='mx-3 no-print align-items-center'>
            <NavDropdown
                title={`${resolveText('Hello')}, ${getPerson(user)!.firstName}`}
                className='me-3'
            >
                <NavDropdown.Item onClick={() => navigate(SharedUrls.MY_ACCOUNT)}>{resolveText('Account_ManageAccount')}</NavDropdown.Item>
            </NavDropdown>
            <NavItem>
                <Row>
                    <Col xs="auto" className='ps-0 text-nowrap'>
                        <Button
                            variant="danger"
                            onClick={onLogOut}
                            className='mx-1'
                        >
                            {resolveText('LogOut')}
                        </Button>
                        <Button 
                            variant="danger"
                            onClick={() => lockScreen(dispatch, navigate)}
                            className='mx-1'
                        >
                            <i className='fa fa-lock' />
                        </Button>
                    </Col>
                </Row>
            </NavItem>
        </Nav>
    );

}