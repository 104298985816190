import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, FormControl, InputGroup, ListGroup, Modal, Row } from "react-bootstrap";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { loadObject } from "../../sharedCommonComponents/helpers/LoadingHelpers";
import { getMenuItems } from "../components/Menus/MenuBuilder";
import FeatureContext from "../contexts/FeatureContext";
import UserContext from "../contexts/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchTextHighlightedText } from "../../sharedCommonComponents/components/SearchTextHighlightedText";
import { MenuItemGroupViewer } from "../components/Menus/MenuItemGroupViewer";
import { Models } from "../types/models";
import { JanKisUrls, UrlIdPlaceholders } from "../navigation/Urls";
import { buildHealthRecordUrl, SharedUrls } from "../../sharedHealthComponents/navigation/Urls";
import { NavigationContextType } from "../types/enums";

export interface MenuSearchResult {
    displayName: string;
    url: string;
}
interface MenuModalProps {
    show: boolean;
    onClose: () => void;
    context?: Models.Navigation.NavigationContext;
}

// ################
// # TODO: Consider merging/replacing NavigationCommandLineModal
// ################
export const MenuModal = (props: MenuModalProps) => {

    const { show, onClose, context } = props;

    const features = useContext(FeatureContext);
    const user = useContext(UserContext)!;
    const menus = useMemo(() => getMenuItems(features, user, context), [ features, user, context ]);
    const [ searchText, setSearchText ] = useState<string>('');
    const [ localSearchResults, setLocalSearchResults ] = useState<MenuSearchResult[]>([]);
    const [ remoteSearchResults, setRemoteSearchResults ] = useState<MenuSearchResult[]>([]);
    const combinedSearchResults = useMemo(() => localSearchResults.concat(remoteSearchResults), [ localSearchResults, remoteSearchResults ]);
    const [ highlightedSearchResultIndex, setHighlightedSearchResultIndex ] = useState<number>(0); 
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ location ]);

    const searchInStaticLinks = useCallback((searchText: string, context?: Models.Navigation.NavigationContext) => {
        const lowerSearchText = searchText.trim().toLowerCase();
        const searchResults: MenuSearchResult[] = [];
        if(lowerSearchText.length < 2) {
            return searchResults;
        }
        for (const group of menus) {
            const matchingItems = group.items.filter(item => item.displayName.toLowerCase().includes(lowerSearchText));
            if(matchingItems.length === 0) {
                continue;
            }
            searchResults.push(...matchingItems.map(item => ({
                displayName: `${group.title} - ${item.displayName}`,
                url: item.url
            } as MenuSearchResult)));
        }
        return searchResults;
    }, [ menus ]);

    const search = useCallback(async (searchText: string, context?: Models.Navigation.NavigationContext) => {
        if(!context || context.type === NavigationContextType.None || !context.entityId) {
            // Only search in static links
            return;
        }
        let searchUrl = null;
        switch(context.type) {
            case NavigationContextType.Institution:
                searchUrl = `api/navigation/institutions/${context.entityId}/search`
                break;
            case NavigationContextType.Department:
                searchUrl = `api/navigation/departments/${context.entityId}/search`;
                break;
            case NavigationContextType.Patient:
                searchUrl = `api/navigation/persons/${context.entityId}/search`
                break;
        }
        if(!searchUrl) {
            return;
        }
        await loadObject<Models.Navigation.MenuSearchResult[]>(
            searchUrl, { searchText: searchText },
            resolveText("Menu_CouldNotSearch"),
            searchResults => {
                const transformedRemoteResults: MenuSearchResult[] = searchResults.flatMap(searchResult => {
                    const displayName = `${resolveText(searchResult.itemType)} - ${searchResult.name}`;
                    switch(context.type) {
                        case NavigationContextType.Institution:
                            switch(searchResult.itemType) {
                                case "Department":
                                    return {
                                        displayName,
                                        url: JanKisUrls.OPEN_DEPARTMENT.replace(UrlIdPlaceholders.ID, searchResult.id)
                                    };
                            }
                            break;
                        case NavigationContextType.Department:
                            switch(searchResult.itemType) {
                                case "DepartmentPatientJourneyConfiguration":
                                    return {
                                        displayName,
                                        url: JanKisUrls.OPEN_DEPARTMENT_PATIENTJOURNEY
                                            .replace(UrlIdPlaceholders.DEPARTMENT, context.entityId!)
                                            .replace(UrlIdPlaceholders.ID, searchResult.id)
                                    };
                                case "ServiceDefinition":
                                    return [
                                        {
                                            displayName,
                                            url: SharedUrls.OPEN_SERVICE.replace(UrlIdPlaceholders.ID, searchResult.id)
                                        },
                                        {
                                            displayName: `${resolveText("ServiceRequest")} - ${searchResult.name}`,
                                            url: SharedUrls.SERVICE_SERVICEREQUESTS.replace(UrlIdPlaceholders.SERVICE, searchResult.id)
                                        }
                                    ];
                                case "Person":
                                    return {
                                        displayName,
                                        url: buildHealthRecordUrl(searchResult.id)
                                    }
                            }
                            break;
                        case NavigationContextType.Patient:
                            break;
                    }
                    return [];
                });
                setRemoteSearchResults(transformedRemoteResults);
            }
        );
    }, []);

    useEffect(() => {
        setLocalSearchResults(searchInStaticLinks(searchText, context));
        const searchTimeout = setTimeout(() => search(searchText, context), 300);
        return () => {
            clearTimeout(searchTimeout);
        }
    }, [ context, searchText, search, searchInStaticLinks ]);

    useEffect(() => {
        setHighlightedSearchResultIndex(0);
    }, [ combinedSearchResults ]);

    useEffect(() => {
        if(show) {
            const searchFormControl = document.getElementById('menu-search-text') as HTMLInputElement;
            searchFormControl?.focus();
            searchFormControl?.select();
        }
    }, [ show ]);

    return (<Modal 
        show={show} 
        onHide={onClose}
        size="lg"
        fullscreen="md"
    >
        <Modal.Body>
            <Row className="mb-3">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            <i className="fa fa-search" />
                        </InputGroup.Text>
                        <FormControl
                            id="menu-search-text"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            onKeyDown={e => {
                                if(e.key === 'ArrowDown') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setHighlightedSearchResultIndex(state => state + 1 < combinedSearchResults.length ? state + 1 : state);
                                } else if(e.key === "ArrowUp") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setHighlightedSearchResultIndex(state => state > 0 ? state - 1 : state);
                                } else if(e.key === "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if(highlightedSearchResultIndex >= 0 && highlightedSearchResultIndex < combinedSearchResults.length) {
                                        navigate(combinedSearchResults[highlightedSearchResultIndex].url);
                                    }
                                }
                            }}
                            size="lg"
                            placeholder={resolveText("Search")}
                        />
                    </InputGroup>
                </Col>
            </Row>
            {combinedSearchResults.length > 0
            ? <Row>
                <Col>
                    {/* Search results */}
                    <div className="ms-2">
                        <strong>{resolveText("Menu_SearchResults")}</strong>
                    </div>
                    <ListGroup>
                    {combinedSearchResults.map((result,resultIndex) => {
                        const isHighlighted = resultIndex === highlightedSearchResultIndex;
                        return (
                            <ListGroup.Item
                                key={result.displayName}
                                onClick={() => navigate(result.url)}
                                className={[ "clickable" ].concat(isHighlighted ? [ 'bg-info' ] : []).join(' ')}
                            >
                                <SearchTextHighlightedText 
                                    text={result.displayName}
                                    searchText={searchText}
                                />
                            </ListGroup.Item>
                        );
                    })}
                    </ListGroup>
                </Col>
            </Row> : null}
            <hr />
            <Row>
                <Col md={4}>
                    {/* Static menu links */}
                    {menus.slice(0, menus.length / 2).map(menuGroup => (
                        <MenuItemGroupViewer
                            key={menuGroup.title}
                            menuGroup={menuGroup}
                        />
                    ))}
                </Col>
                <Col md={4}>
                    {menus.slice(menus.length / 2).map(menuGroup => (
                        <MenuItemGroupViewer
                            key={menuGroup.title}
                            menuGroup={menuGroup}
                        />
                    ))}
                </Col>
                <Col md={4}>
                    {/* Last used / last search */}
                </Col>
            </Row>
        </Modal.Body>
    </Modal>);

}