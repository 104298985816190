import { Card, Row, Col, FormGroup, FormLabel, InputGroup, FormControl } from "react-bootstrap";
import { Update } from "../../../sharedCommonComponents/types/frontendTypes";
import { LabelPaperSettings } from "../../types/frontendTypes";
import { Center } from "../../../sharedCommonComponents/components/Center";
import { PropsWithChildren } from "react";

interface LabelPaperSettingsEditorProps extends PropsWithChildren {
    settings: LabelPaperSettings;
    onChange: (update: Update<LabelPaperSettings>) => void;
}

export const LabelPaperSettingsEditor = (props: LabelPaperSettingsEditorProps) => {

    const { settings, onChange } = props;

    const {
        rowCount,
        columnCount,
        labelWidth,
        labelHeight,
        paperWidth,
        paperHeight,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft
    } = settings;
    const setRowCount = (x: number) => onChange(state => ({ ...state, rowCount: x }));
    const setColumnCount = (x: number) => onChange(state => ({ ...state, columnCount: x }));
    const setLabelHeight = (x: number) => onChange(state => ({ ...state, labelHeight: x }));
    const setLabelWidth = (x: number) => onChange(state => ({ ...state, labelWidth: x }));
    const setPaperHeight = (x: number) => onChange(state => ({ ...state, paperHeight: x }));
    const setPaperWidth = (x: number) => onChange(state => ({ ...state, paperWidth: x }));
    const setMarginTop = (x: number) => onChange(state => ({ ...state, marginTop: x }));
    const setMarginRight = (x: number) => onChange(state => ({ ...state, marginRight: x }));
    const setMarginBottom = (x: number) => onChange(state => ({ ...state, marginBottom: x }));
    const setMarginLeft = (x: number) => onChange(state => ({ ...state, marginLeft: x }));

    return (<Card className="my-2 no-print">
        <Card.Header>
            <Card.Title>Settings</Card.Title>
        </Card.Header>
        <Card.Body>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Rows</FormLabel>
                        <InputGroup>
                            <InputGroup.Text>
                                <i className="fa fa-long-arrow-down" />
                            </InputGroup.Text>
                            <FormControl
                                type="number"
                                value={rowCount}
                                onChange={e => setRowCount(Number(e.target.value))}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs="auto">
                    x
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Column</FormLabel>
                        <InputGroup>
                            <InputGroup.Text>
                                <i className="fa fa-long-arrow-right" />
                            </InputGroup.Text>
                            <FormControl
                                type="number"
                                value={columnCount}
                                onChange={e => setColumnCount(Number(e.target.value))}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Label height</FormLabel>
                        <InputGroup>
                            <InputGroup.Text>
                                <i className="fa fa-long-arrow-down" />
                            </InputGroup.Text>
                            <FormControl
                                type="number"
                                min={0}
                                step={0.1}
                                value={labelHeight}
                                onChange={e => setLabelHeight(Number(e.target.value))}
                            />
                            <InputGroup.Text>mm</InputGroup.Text>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs="auto">
                    x
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Label width</FormLabel>
                        <InputGroup>
                            <InputGroup.Text>
                                <i className="fa fa-long-arrow-right" />
                            </InputGroup.Text>
                            <FormControl
                                type="number"
                                min={0}
                                step={0.1}
                                value={labelWidth}
                                onChange={e => setLabelWidth(Number(e.target.value))}
                            />
                            <InputGroup.Text>mm</InputGroup.Text>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Paper height</FormLabel>
                        <InputGroup>
                            <InputGroup.Text>
                                <i className="fa fa-long-arrow-down" />
                            </InputGroup.Text>
                            <FormControl
                                type="number"
                                value={paperHeight}
                                onChange={e => setPaperHeight(Number(e.target.value))}
                            />
                            <InputGroup.Text>mm</InputGroup.Text>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs="auto">
                    x
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Paper width</FormLabel>
                        <InputGroup>
                            <InputGroup.Text>
                                <i className="fa fa-long-arrow-right" />
                            </InputGroup.Text>
                            <FormControl
                                type="number"
                                value={paperWidth}
                                onChange={e => setPaperWidth(Number(e.target.value))}
                            />
                            <InputGroup.Text>mm</InputGroup.Text>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <FormLabel>Margin</FormLabel>
                <Center>
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <InputGroup>
                                        <FormControl
                                            type="number"
                                            step={0.1}
                                            value={marginTop}
                                            onChange={e => setMarginTop(Number(e.target.value))}
                                        />
                                        <InputGroup.Text>mm</InputGroup.Text>
                                    </InputGroup>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <InputGroup>
                                        <FormControl
                                            type="number"
                                            step={0.1}
                                            value={marginLeft}
                                            onChange={e => setMarginLeft(Number(e.target.value))}
                                        />
                                        <InputGroup.Text>mm</InputGroup.Text>
                                    </InputGroup>
                                </td>
                                <td className="border border-dark" style={{ height: '80px'}} ></td>
                                <td>
                                    <InputGroup>
                                        <FormControl
                                            type="number"
                                            step={0.1}
                                            value={marginRight}
                                            onChange={e => setMarginRight(Number(e.target.value))}
                                        />
                                        <InputGroup.Text>mm</InputGroup.Text>
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <InputGroup>
                                        <FormControl
                                            type="number"
                                            step={0.1}
                                            value={marginBottom}
                                            onChange={e => setMarginBottom(Number(e.target.value))}
                                        />
                                        <InputGroup.Text>mm</InputGroup.Text>
                                    </InputGroup>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </Center>
            </FormGroup>
            {props.children}
        </Card.Body>
    </Card>);

}