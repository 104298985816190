import { Button } from "react-bootstrap";
import { PatientNoteType } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { MedicalTextViewer } from "../MedicalTextEditor/MedicalTextViewer";
import { useNavigate } from "react-router-dom";
import { buildHealthRecordUrl } from "../../navigation/Urls";

interface PatientNoteTimelineItemTextProps {
    note: ViewModels.HealthRecordEntries.IPatientNoteViewModel;
}

export const PatientNoteTimelineItemText = (props: PatientNoteTimelineItemTextProps) => {

    const { note } = props;
    const navigate = useNavigate();

    switch(note.noteType) {
        case PatientNoteType.Simple:
            const simpleNote = note as ViewModels.HealthRecordEntries.SimplePatientNoteViewModel;
            return (<MedicalTextViewer
                text={simpleNote.text}
            />);
        case PatientNoteType.Complex:
            const complexNote = note as ViewModels.HealthRecordEntries.ComplexPatientNoteViewModel;
            return (<>
                <strong>{complexNote.title}</strong>
                <Button
                    onClick={() => navigate(buildHealthRecordUrl(note.personId, `/note/${note.id}`))}
                    size="sm"
                    className="ms-2"
                >
                    {resolveText("Show")}
                </Button>
            </>);
    }

}