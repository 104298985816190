import { useMemo } from 'react';
import { useAppDispatch } from '../../localComponents/redux/store/healthRecordStore';
import { HealthRecordEntryType, VerificationStatus } from '../../localComponents/types/enums';
import { resolveText } from '../../sharedCommonComponents/helpers/Globalizer';
import { healthRecordModalsSlice } from '../redux/slices/healthRecordModalsSlice';
import { healthRecordsSlice } from '../redux/slices/healthRecordsSlice';

interface HealthRecordEntryAdditionalInfoButtonsProps {
    personId: string;
    entryType: HealthRecordEntryType;
    entryId: string;
    opinions: VerificationStatus[];
}

export const HealthRecordEntryAdditionalInfoButtons = (props: HealthRecordEntryAdditionalInfoButtonsProps) => {

    const { personId, entryType, entryId, opinions } = props;
    const dispatch = useAppDispatch();
    const entryReference = useMemo(() => ({
        entryType: entryType,
        entryId: entryId
    }), [ entryType, entryId ]);

    const setSelectedEntry = () => {
        dispatch(healthRecordsSlice.actions.setSelectedEntry(entryReference));
    }
    const openRelatedEntriesModal = () => {
        setSelectedEntry();
        dispatch(healthRecordModalsSlice.actions.openRelatedEntriesModal({
            personId,
            entryReference
        }));
    }
    const openChangelogModal = () => {
        setSelectedEntry();
        dispatch(healthRecordModalsSlice.actions.openChangeLogModal({
            entryReference
        }));
    }
    const openOpinionsModal = () => {
        setSelectedEntry();
        dispatch(healthRecordModalsSlice.actions.openOpinionsModal({
            personId,
            entryReference
        }));
    }

    const commentColor = !opinions || opinions.length === 0 ? 'text-secondary'
        : opinions.includes(VerificationStatus.Refuted) ? 'text-danger'
        : opinions.includes(VerificationStatus.Confirmed) ? 'text-success'
        : 'text-warning';

    return (
        <>
            <i 
                className='fa fa-link mx-1 clickable' 
                title={resolveText("HealthRecordEntry_RelatedEntries")}
                onClick={openRelatedEntriesModal}
            />
            <i 
                className={`fa fa-comment mx-1 clickable ${commentColor}`}
                title={resolveText("HealthProfessionalOpinion")}
                onClick={openOpinionsModal} 
            />
            <i 
                className='fa fa-clock-o mx-1 clickable' 
                title={resolveText("Changelog")}
                onClick={openChangelogModal}
            />
        </>
    );

}