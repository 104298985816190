import React, { Suspense, useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LoadingAlert } from '../../sharedCommonComponents/components/LoadingAlert';
import { QRScannerModal } from '../modals/QRScannerModal';
import { departmentsActions } from '../redux/slices/departmentsSlice';
import { useAppDispatch } from '../redux/store/healthRecordStore';
import { LoggedInUser } from './Menus/LoggedInUser';
import { LockedOverlay } from '../../sharedHealthComponents/components/UserManagement/LockedOverlay';
import { HealthRecordSearchSelfcontained } from '../../sharedHealthComponents/components/Patients/HealthRecordSearchSelfcontained';
import { FeedbackIcon } from '../../sharedCommonComponents/components/FeedbackIcon';
import { UniversalSearchMenu } from './Menus/UniversalSearchMenu';

interface EmployeeLayoutProps extends React.PropsWithChildren<{}> {
    onLogOut: () => void;
}

export const EmployeeLayout = (props: EmployeeLayoutProps) => {

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(departmentsActions.loadServiceDepartments({ args: { itemId: 'not used' }}));
    }, [ dispatch ]);
    const [ showQRScannerModal, setShowQRScannerModal ] = useState<boolean>(false);
    const navigate = useNavigate();

    return (
        <>
            <ToastContainer
                theme='colored'
            />
            <HealthRecordSearchSelfcontained />
            <Navbar id='main-navbar' bg="light" expand="xl" sticky="top">
                <div className='d-flex flex-fill'>
                    <Navbar.Brand className="ms-2 clickable" onClick={() => navigate('/')}>
                        <i className='fa fa-hospital-o me-2' />
                        <strong>JanKIS</strong>
                    </Navbar.Brand>
                    <UniversalSearchMenu />
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className='no-print ms-3' />
                </div>
                <Navbar.Collapse id="basic-navbar-nav no-print" style={{ flexGrow: 0 }}>
                    <LoggedInUser 
                        onLogOut={props.onLogOut}
                    />
                </Navbar.Collapse>
            </Navbar>
            <Suspense fallback={<LoadingAlert />}>
                {props.children}
            </Suspense>
            <QRScannerModal show={showQRScannerModal} onHide={() => setShowQRScannerModal(false)} />
            <FeedbackIcon />
            <LockedOverlay />
        </>
    );

}