import { NavigateFunction } from "react-router-dom";
import { AppDispatch } from "../../localComponents/redux/store/healthRecordStore";
import { apiClient } from "../../sharedCommonComponents/communication/ApiClient";
import { screenLockActions } from "../redux/slices/screenLockSlice";
import { accessTokenSessionStorageKey } from "../../sharedCommonComponents/helpers/Constants";
import { SharedUrls } from "../navigation/Urls";

export const lockScreen = (dispatch: AppDispatch, navigate: NavigateFunction) => {
    dispatch(screenLockActions.lock({
        args: undefined,
        onSuccess: () => {
            apiClient.instance!.accessToken = undefined;
            sessionStorage.removeItem(accessTokenSessionStorageKey);
        },
        onFailure: () => {
            navigate(SharedUrls.MY_ACCOUNT + '?tab=screen-lock-pin');
        }
    }));
}

export const unlockScreen = (pin: string, dispatch: AppDispatch, onFailure?: () => void) => {
    dispatch(screenLockActions.unlock({
        pin: pin,
        onFailure: () => {
            if(onFailure) {
                onFailure();
            }
        },
        onSuccess: (unlockResult) => {
            apiClient.instance!.accessToken = unlockResult.jwt!.rawAccessToken;
            sessionStorage.setItem(accessTokenSessionStorageKey, unlockResult.jwt!.rawAccessToken);
        }
    }));
}