import { Models } from "../types/models";

export const isUnfinishedLetterItem = (item: Models.Documentation.DischargeLetterItem) => {
    const hasEmptyPlaceholders = Object.values(item.placeholderValues).some(x => x.trim().length === 0);
    if(hasEmptyPlaceholders) {
        return true;
    }
    return false;
}
export const extractPlaceholders = (text: string): string[] => {
    return text.match(/\$[A-Z0-9_]+/g) ?? [];
}