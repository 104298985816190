import { GenericIdAutocomplete } from "../../../sharedCommonComponents/components/Autocompletes/GenericIdAutocomplete";
import { IdAutocompleteProps } from "../../../sharedCommonComponents/types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { MaterialOrderAutocomplete } from "./MaterialOrderAutocomplete";

interface MaterialOrderIdAutocompleteProps extends IdAutocompleteProps {
}

export const MaterialOrderIdAutocomplete = (props: MaterialOrderIdAutocompleteProps) => {
    
    return (<GenericIdAutocomplete<ViewModels.MaterialOrderViewModel>
        loadItemApiPathBuilder={id => `api/materialOrders/${id}`}
        autocompleteBuilder={(key, props) => (
            <MaterialOrderAutocomplete
                key={key}
                {...props}
            />
        )}
        {...props}
    />);

}