import { UnknownAction, configureStore, combineReducers, ActionCreator, Action, Reducer } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { diagnosesSlice } from '../../../sharedHealthComponents/redux/slices/diagnosesSlice';
import { testResultsSlice } from '../../../sharedHealthComponents/redux/slices/testResultsSlice';
import { documentsSlice } from '../../../sharedHealthComponents/redux/slices/documentsSlice';
import { questionnaireAnswersSlice } from '../../../sharedHealthComponents/redux/slices/questionnaireAnswersSlice';
import { subscriptionsSlice } from '../slices/subscriptionsSlice';
import { institutionsSlice } from '../slices/institutionsSlice';
import { stocksSlice } from '../slices/stocksSlice';
import { consumablesSlice } from '../slices/consumablesSlice';
import { resourcesSlice } from '../slices/resourcesSlice';
import { bedOccupanciesSlice } from '../slices/bedOccupanciesSlice';
import { departmentsSlice } from '../slices/departmentsSlice';
import { roomsSlice } from '../slices/roomsSlice';
import { newsSlice } from '../slices/newsSlice';
import { allergiesSlice } from '../../../sharedHealthComponents/redux/slices/allergiesSlice';
import { specimensSlice } from '../../../sharedHealthComponents/redux/slices/specimensSlice';
import { healthRecordsSlice } from '../../../sharedHealthComponents/redux/slices/healthRecordsSlice';
import { immunizationsSlice } from '../../../sharedHealthComponents/redux/slices/immunizationsSlice';
import { medicalProceduresSlice } from '../../../sharedHealthComponents/redux/slices/medicalProceduresSlice';
import { medicationDispensionsSlice } from '../../../sharedHealthComponents/redux/slices/medicationDispensionsSlice';
import { medicationSchedulesSlice } from '../../../sharedHealthComponents/redux/slices/medicationSchedulesSlice';
import { notesSlice } from '../../../sharedHealthComponents/redux/slices/notesSlice';
import { observationsSlice } from '../../../sharedHealthComponents/redux/slices/observationsSlice';
import { permissionsSlice } from '../../../sharedHealthComponents/redux/slices/permissionsSlice';
import { personsSlice } from '../../../sharedHealthComponents/redux/slices/personsSlice';
import { practitionersSlice } from '../../../sharedHealthComponents/redux/slices/practitionersSlice';
import { encountersSlice } from '../../../sharedHealthComponents/redux/slices/encountersSlice';
import { abbreviationsSlice } from '../../../sharedHealthComponents/redux/slices/abbreviationsSlice';
import { medicalTextTemplatesSlice } from '../../../sharedHealthComponents/redux/slices/medicalTextTemplatesSlice';
import { equipmentsSlice } from '../../../sharedHealthComponents/redux/slices/equipmentsSlice';
import { opinionsSlice } from '../../../sharedHealthComponents/redux/slices/opinionsSlice';
import { personDataChangesSlice } from '../../../sharedHealthComponents/redux/slices/personDataChangesSlice';
import { relatedEntriesSlice } from '../../../sharedHealthComponents/redux/slices/relatedHealthRecordEntriesSlice';
import { accountsSlice } from '../slices/accountsSlice';
import { servicesSlice } from '../slices/servicesSlice';
import { serviceRequestsSlice } from '../slices/serviceRequestsSlice';
import { materialOrdersSlice } from '../slices/materialOrdersSlice';
import { contactsSlice } from '../slices/contactsSlice';
import { rolesSlice } from '../slices/rolesSlice';
import { drugsSlice } from '../../../sharedHealthComponents/redux/slices/drugsSlice';
import { questionnairesSlice } from '../../../sharedHealthComponents/redux/slices/questionnairesSlice';
import { notificationsSlice } from '../slices/notificationsSlice';
import { stockStatesSlice } from '../slices/stockStatesSlice';
import { testPackagesSlice } from '../../../sharedHealthComponents/redux/slices/testPackagesSlice';
import { pregnanciesSlice } from '../../../sharedHealthComponents/redux/slices/pregnanciesSlice';
import { todolistSlice } from '../../../sharedHealthComponents/redux/slices/todolistSlice';
import { questionnaireAnswersSeriesSlice } from '../../../sharedHealthComponents/redux/slices/questionnaireAnswersSeriesSlice';
import { tagsSlice } from '../../../sharedHealthComponents/redux/slices/tagsSlice';
import { modalSlice } from '../slices/modalSlice';
import { invoicesSlice } from '../slices/invoicesSlice';
import { isolationsSlice } from '../../../sharedHealthComponents/redux/slices/isolationsSlice';
import { checklistAnswersSlice } from '../../../checklist/redux/checklistAnswersSlice';
import { checklistsSlice } from '../../../checklist/redux/checklistsSlice';
import { servicePackagesSlice } from '../slices/servicePackagesSlice';
import { healthRecordModalsSlice } from '../../../sharedHealthComponents/redux/slices/healthRecordModalsSlice';
import { printTemplatesSlice } from '../../../sharedHealthComponents/redux/slices/printTemplatesSlice';
import { medicationRequestsSlice } from '../../../sharedHealthComponents/redux/slices/medicationRequestsSlice';
import { equipmentMeasurementsSlice } from '../../../sharedHealthComponents/redux/slices/equipmentMeasurementsSlice';
import { cprSlice } from '../../../sharedHealthComponents/redux/slices/cprSlice';
import { pharmacyOrdersSlice } from '../../../sharedHealthComponents/redux/slices/pharmacyOrdersSlice';
import { appointmentsSlice } from '../../../sharedHealthComponents/redux/slices/appointmentsSlice';
import { adverseEventsSlice } from '../../../sharedHealthComponents/redux/slices/adverseEventsSlice';
import { patientChecklistAnswersSlice } from '../../../sharedHealthComponents/redux/slices/patientChecklistAnswersSlice';
import { taskPackagesSlice } from '../../../sharedHealthComponents/redux/slices/taskPackagesSlice';
import { timeslotGeneratorsSlice } from '../../../sharedHealthComponents/redux/slices/timeslotGeneratorsSlice';
import { representationPermissionsSlice } from '../../../sharedHealthComponents/redux/slices/representationPermissionsSlice';
import { screenLockSlice } from '../../../sharedHealthComponents/redux/slices/screenLockSlice';
import { treatmentProcessesSlice } from '../../../sharedHealthComponents/redux/slices/treatmentProcessesSlice';
import { medicalAlertsSlice } from '../../../sharedHealthComponents/redux/slices/medicalAlertsSlice';
import { autocompleteItemsSlice } from '../../../sharedHealthComponents/redux/slices/autocompleteItemsSlice';
import { questionAnswersSlice } from '../../../sharedHealthComponents/redux/slices/questionAnswersSlice';
import { classificationsSlice } from '../../../sharedHealthComponents/redux/slices/classificationsSlice';
import { specimenMeasurementOrdersSlice } from '../../../sharedHealthComponents/redux/slices/specimenMeasurementOrdersSlice';
import { feedbackSlice } from '../slices/feedbackSlice';
import { sessionSlice } from '../slices/sessionSlice';
import { healthRecordEntryEditLockSlice } from '../../../sharedHealthComponents/redux/slices/healthRecordEntryEditLockSlice';
import { knowledgeBaseSlice } from '../slices/knowledgeBaseSlice';
import { functionalGroupsSlice } from '../slices/functionalGroupsSlice';
import { customHealthRecordSummarySlice } from '../../../sharedHealthComponents/redux/slices/customHealthRecordSummarySlice';
import { navigationContextSlice } from '../slices/navigationContextSlice';
import { letterTemplatesSlice } from '../slices/letterTemplatesSlice';

const appReducer = combineReducers({
    session: sessionSlice.reducer,
    navigationContext: navigationContextSlice.reducer,

    persons: personsSlice.reducer,
    practitioners: practitionersSlice.reducer,
    allergies: allergiesSlice.reducer,
    customHealthRecordSummaries: customHealthRecordSummarySlice.reducer,
    diagnoses: diagnosesSlice.reducer,
    medicalAlerts: medicalAlertsSlice.reducer,
    treatmentProcesses: treatmentProcessesSlice.reducer,
    equipments: equipmentsSlice.reducer,
    observations: observationsSlice.reducer,
    specimens: specimensSlice.reducer,
    specimenMeasurementOrders: specimenMeasurementOrdersSlice.reducer,
    testResults: testResultsSlice.reducer,
    medicationSchedules: medicationSchedulesSlice.reducer,
    medicationDispensions: medicationDispensionsSlice.reducer,
    medicationRequests: medicationRequestsSlice.reducer,
    immunizations: immunizationsSlice.reducer,
    isolations: isolationsSlice.reducer,
    drugs: drugsSlice.reducer,
    notes: notesSlice.reducer,
    documents: documentsSlice.reducer,
    medicalProcedures: medicalProceduresSlice.reducer,
    questionnaires: questionnairesSlice.reducer,
    questionnaireAnswers: questionnaireAnswersSlice.reducer,
    questionnaireAnswersSeries: questionnaireAnswersSeriesSlice.reducer,
    pregnancies: pregnanciesSlice.reducer,
    opinions: opinionsSlice.reducer,
    healthRecords: healthRecordsSlice.reducer,
    healthRecordEntryEditLocks: healthRecordEntryEditLockSlice.reducer,
    personDataChanges: personDataChangesSlice.reducer,
    permissions: permissionsSlice.reducer,
    questionAnswers: questionAnswersSlice.reducer,
    relatedEntries: relatedEntriesSlice.reducer,
    subscriptions: subscriptionsSlice.reducer,
    testPackages: testPackagesSlice.reducer,
    letterTemplates: letterTemplatesSlice.reducer,
    tags: tagsSlice.reducer,
    cpr: cprSlice.reducer,
    
    abbreviations: abbreviationsSlice.reducer,
    medicalTextTemplates: medicalTextTemplatesSlice.reducer,
    encounters: encountersSlice.reducer,
    appointments: appointmentsSlice.reducer,
    timeslotGenerators: timeslotGeneratorsSlice.reducer,
    invoices: invoicesSlice.reducer,
    
    accounts: accountsSlice.reducer,
    roles: rolesSlice.reducer,
    representationPermissions: representationPermissionsSlice.reducer,
    institutions: institutionsSlice.reducer,
    departments: departmentsSlice.reducer,
    rooms: roomsSlice.reducer,
    bedOccupancies: bedOccupanciesSlice.reducer,
    stocks: stocksSlice.reducer,
    stockStates: stockStatesSlice.reducer,
    consumables: consumablesSlice.reducer,
    materialOrders: materialOrdersSlice.reducer,
    resources: resourcesSlice.reducer,
    services: servicesSlice.reducer,
    servicePackages: servicePackagesSlice.reducer,
    serviceRequests: serviceRequestsSlice.reducer,
    pharmacyOrders: pharmacyOrdersSlice.reducer,
    notifications: notificationsSlice.reducer,
    news: newsSlice.reducer,
    contacts: contactsSlice.reducer,
    knowledgeBase: knowledgeBaseSlice.reducer,
    todoList: todolistSlice.reducer,
    tasksPackages: taskPackagesSlice.reducer,
    functionalGroups: functionalGroupsSlice.reducer,
    adverseEvents: adverseEventsSlice.reducer,
    printTemplates: printTemplatesSlice.reducer,
    equipmentMeasurements: equipmentMeasurementsSlice.reducer,
    autocompleteItems: autocompleteItemsSlice.reducer,
    classifications: classificationsSlice.reducer,

    checklists: checklistsSlice.reducer,
    checklistAnswers: checklistAnswersSlice.reducer,
    patientChecklistAnswers: patientChecklistAnswersSlice.reducer,

    modals: modalSlice.reducer,
    healthRecordModals: healthRecordModalsSlice.reducer,
    screenLock: screenLockSlice.reducer,
    feedback: feedbackSlice.reducer,
});
const rootReducer: Reducer<ReturnType<typeof appReducer>>  = (state, action) => {
    if(action.type === resetActionType) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
const resetActionType = "root/reset";
export const reset: ActionCreator<Action> = () => {
    return {
        type: resetActionType
    };
}


const healthRecordStore = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof healthRecordStore.getState>;
export type AppDispatch = ThunkDispatch<RootState, void, UnknownAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default healthRecordStore;