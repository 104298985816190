import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HealthRecordEntryConflictComparisonModalProps } from "../../modals/HealthRecordEntryConflictComparisonModal";
import { IsolationModalProps } from "../../modals/IsolationModal";
import { ModalState } from "../../../sharedHealthComponents/types/frontendTypes";
import { TextPlaceholderModalProps } from "../../modals/TextPlaceholderModal";

export interface ModalSliceState {
    healthRecordEntryComparisonModal: ModalState<HealthRecordEntryConflictComparisonModalProps>;
    isolationModal: ModalState<IsolationModalProps>;
    textPlaceholderModal: ModalState<TextPlaceholderModalProps>;
}

const initialState: ModalSliceState = {
    healthRecordEntryComparisonModal: {
        show: false
    },
    isolationModal: {
        show: false
    },
    textPlaceholderModal: {
        show: false
    }
}

export const modalSlice = createSlice({
    name: 'modals',
    initialState: initialState,
    reducers: {
        openHealthRecordEntryComparisonModal: (state, action: PayloadAction<HealthRecordEntryConflictComparisonModalProps>) => {
            state.healthRecordEntryComparisonModal = {
                show: true,
                parameters: action.payload
            };
        },
        closeHealthRecordEntryComparisonModal: (state) => {
            state.healthRecordEntryComparisonModal.show = false;
        },
        openIsolationModal: (state, action: PayloadAction<IsolationModalProps>) => {
            state.isolationModal = {
                show: true,
                parameters: action.payload
            };
        },
        closeIsolationModal: (state) => {
            state.isolationModal.show = false;
        },
        openTextPlaceholderModal: (state, action: PayloadAction<TextPlaceholderModalProps>) => {
            state.textPlaceholderModal = {
                show: true,
                parameters: action.payload
            }
        },
        closeTextPlaceholderModal: (state) => {
            state.textPlaceholderModal.show = false;
        }
    }
});