import { PatientTimelineItem } from './PatientTimelineItem';
import { useAppDispatch, useAppSelector } from '../../../localComponents/redux/store/healthRecordStore';
import { useEffect, useMemo } from 'react';
import { getHealthRecordEntryTimestampAsDate, sortByTimeDescending } from '../../helpers/HealthRecordEntryHelpers';
import { NoEntriesAlert } from '../../../sharedCommonComponents/components/NoEntriesAlert';
import { DateDividedTimeline } from '../DateDividedTimeline';
import { healthRecordEntrySliceActions, healthRecordEntrySliceSelectors } from '../../redux/slices/healthRecordsSlice';
import { InfiniteScroll } from '../../../sharedCommonComponents/components/InfiniteScroll';
import { LoadingAlert } from '../../../sharedCommonComponents/components/LoadingAlert';
import { OrderDirection } from '../../../sharedCommonComponents/types/enums';
import { Button, Col, Row } from 'react-bootstrap';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { useNavigate } from 'react-router-dom';
import { useMemorizedSelector } from '../../redux/helpers/ReduxHooks';
import { personsSelectors } from '../../redux/slices/personsSlice';
import { buildHealthRecordUrl } from '../../navigation/Urls';

interface HealthRecordOverviewProps {
    personId: string;
}

export const HealthRecordOverview = (props: HealthRecordOverviewProps) => {

    const { personId } = props;
    const isLoading = useAppSelector(state => state.healthRecords.isLoading);
    const person = useMemorizedSelector(personsSelectors.getById, { id: personId });
    const now = useMemo(() => new Date(), []);
    const events = useMemorizedSelector(healthRecordEntrySliceSelectors.getForPerson, { personId: personId, now: now });
    const timeSortedEvents = useMemo(() => sortByTimeDescending(events), [ events ]);
    const filter = useAppSelector(state => state.healthRecords.filter);
    const hasMoreEntries = useAppSelector(state => state.healthRecords.hasMoreItems);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const loadMoreEvents = useMemo(() => () => {
        dispatch(healthRecordEntrySliceActions.loadItems({ 
            args: {
                personId: personId
            }
        }));
    }, [ personId, dispatch ]);
    
    useEffect(() => {
        loadMoreEvents();
    }, [ filter, loadMoreEvents ]);

    return (
        <div className="mt-3 bg-white">
            <Row className='mb-2'>
                <Col />
                <Col xs="auto">
                    <Button
                        onClick={() => navigate(buildHealthRecordUrl(personId, `/timeline`))}
                        size='sm'
                    >
                        {resolveText("Patient_Timeline")} <i className='fa fa-external-link ms-2' />
                    </Button>
                </Col>
                <Col />
            </Row>
            <InfiniteScroll
                dataLength={timeSortedEvents.length}
                isLoading={isLoading}
                next={loadMoreEvents}
                hasMore={hasMoreEntries}
                showNoMoreItemsAlert
            >
                {timeSortedEvents.length > 0
                ? <DateDividedTimeline
                    items={timeSortedEvents}
                    dateSelector={getHealthRecordEntryTimestampAsDate}
                    birthDate={person?.birthDate}
                    orderDirection={OrderDirection.Descending}
                    itemToDOM={event => (
                        <PatientTimelineItem
                            key={event.id}
                            entry={event}
                        />
                    )}
                />
                : isLoading ? <LoadingAlert />
                : <NoEntriesAlert />}
            </InfiniteScroll>
        </div>
    );

}