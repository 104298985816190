import { DiagnosticTestScaleType } from '../../../localComponents/types/enums';
import { Models } from '../../../localComponents/types/models';
import { ScientificNotationValue } from '../ScientificNotationValue';
import { DocumentButtons } from './DocumentButtons';

interface DiagnosticTestValueViewProps {
    testResult: Models.DiagnosticTests.DiagnosticTestResult;
}

export const DiagnosticTestValueView = (props: DiagnosticTestValueViewProps) => {

    const testResult = props.testResult;
    if(testResult.scaleType === DiagnosticTestScaleType.Freetext) {
        const freetextTestResult = testResult as Models.DiagnosticTests.FreetextDiagnosticTestResult;
        return (<>
            {freetextTestResult.text}
        </>);
    } else if(testResult.scaleType === DiagnosticTestScaleType.Document) {
        const documentTestResult = testResult as Models.DiagnosticTests.DocumentDiagnosticTestResult;
        return (<DocumentButtons documentId={documentTestResult.documentId} />);
    } else if(testResult.scaleType === DiagnosticTestScaleType.Quantitative) {
        const quantitiveResult = testResult as Models.DiagnosticTests.QuantitativeDiagnosticTestResult;
        return (<>
            <ScientificNotationValue value={quantitiveResult.value} /> {quantitiveResult.unit}
        </>);
    } else if(testResult.scaleType === DiagnosticTestScaleType.Ordinal) {
        const ordinalResult = testResult as Models.DiagnosticTests.OrdinalDiagnosticTestResult;
        return (<>
            {ordinalResult.value}
        </>);
    } else if(testResult.scaleType === DiagnosticTestScaleType.Nominal) {
        const nominalResult = testResult as Models.DiagnosticTests.NominalDiagnosticTestResult;
        return (<>
            {nominalResult.value}
        </>);
    }
    throw new Error(`Diagnostic test scale type '${testResult.scaleType}' is not supported`);

}