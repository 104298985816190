interface ScientificNotationValueProps {
    value: number;
    precision?: number;
}
export const ScientificNotationValue = (props: ScientificNotationValueProps) => {

    if(typeof props.value !== 'number') {
        return (<>NaN</>);
    }
    const stringValue = props.value.toPrecision(props.precision ?? 4);
    if(!stringValue.includes('e')) {
        return <>{stringValue}</>;
    }
    const splittedValue = stringValue.split('e').map(Number);
    const scalar = splittedValue[0];
    const exponent = splittedValue[1];
    return (<>{scalar} 	&middot; 10<sup>{exponent}</sup></>);

}