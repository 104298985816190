import { ReactNode, useCallback, useEffect } from 'react';
import { RemoteState } from '../types/reduxInterfaces';
import { ApiGetActionCreator } from '../types/reduxTypes';
import { NonExhaustiveTable } from '../../sharedCommonComponents/components/NonExhaustiveTable';
import { RootState, useAppSelector, useAppDispatch } from '../../localComponents/redux/store/healthRecordStore';
import { Models } from '../../localComponents/types/models';

interface GenericReduxListProps<ItemType extends Models.IId<string>, FilterType> {
    sliceSelector: (state: RootState) => RemoteState<ItemType,FilterType>,
    loadItems: ApiGetActionCreator<any,ItemType[]>,
    tableHeaderBuilder?: () => ReactNode;
    tableRowBuilder: (item: ItemType) => ReactNode;
    colSpan: number;
    hasCreateNewButton?: boolean;
    onCreateNew?: () => void;
    additionalButtons?: ReactNode[];
    autoScroll?: boolean;
    hover?: boolean;
    showNoMoreItemsAlert?: boolean;
}

export const GenericReduxList = <
    ItemType extends Models.IId<string>,
    FilterType
>(props: GenericReduxListProps<ItemType,FilterType>) => {

    const { 
        sliceSelector,
        loadItems,
        tableHeaderBuilder,
        tableRowBuilder,
        colSpan,
        hasCreateNewButton,
        onCreateNew,
        additionalButtons,
        autoScroll, 
        hover, 
        showNoMoreItemsAlert
    } = props;
    const items = useAppSelector(state => sliceSelector(state).items);
    const hasMoreItems = useAppSelector(state => sliceSelector(state).hasMoreItems);
    const isLoading = useAppSelector(state => sliceSelector(state).isLoading);
    const dispatch = useAppDispatch();

    const loadMoreItems = useCallback(() => {
        dispatch(loadItems({}));
    }, [ dispatch, loadItems ]);

    useEffect(() => {
        loadMoreItems();
    }, [ loadMoreItems ]);

    return (
        <NonExhaustiveTable
            autoScroll={autoScroll}
            items={items}
            hasMoreItems={hasMoreItems}
            isLoading={isLoading}
            loadMoreItems={loadMoreItems}
            hasCreateNewButton={hasCreateNewButton}
            onCreateNew={onCreateNew}
            additionalButtons={additionalButtons}
            tableHeaderBuilder={tableHeaderBuilder}
            tableRowBuilder={tableRowBuilder}
            colSpan={colSpan}
            hover={hover}
            showNoMoreItemsAlert={showNoMoreItemsAlert}
        />
    )
}