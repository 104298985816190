import { Models } from "../../../localComponents/types/models";
import { GenericIdAutocomplete } from "../../../sharedCommonComponents/components/Autocompletes/GenericIdAutocomplete";
import { IdAutocompleteProps } from "../../../sharedCommonComponents/types/frontendTypes";
import { ServiceAutocomplete } from "./ServiceAutocomplete";

interface ServiceIdAutocompleteProps extends IdAutocompleteProps {
}

export const ServiceIdAutocomplete = (props: ServiceIdAutocompleteProps) => {

    return (<GenericIdAutocomplete<Models.Services.ServiceDefinition>
        loadItemApiPathBuilder={id => `api/services/${id}`}
        autocompleteBuilder={(key, props) => (
            <ServiceAutocomplete
                key={key}
                {...props}
            />
        )}
        {...props}
    />);

}