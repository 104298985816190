import { Button } from "react-bootstrap";
import { Models } from "../../../localComponents/types/models";
import { useMemorizedSelector } from "../../redux/helpers/ReduxHooks";
import { healthRecordEntryEditLockSelectors } from "../../redux/slices/healthRecordEntryEditLockSlice";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { useContext, useMemo } from "react";
import UserContext from "../../../localComponents/contexts/UserContext";

interface HealthRecordEntryEditButtonProps extends Models.HealthRecordEntryReference {
    variant?: 'link' | 'icon';
    onClick: () => void;
    className?: string;
    disabled?: boolean;
}

export const HealthRecordEntryEditButton = (props: HealthRecordEntryEditButtonProps) => {

    const { entryType, entryId, variant, onClick, className, disabled } = props;

    const user = useContext(UserContext)!;
    const editLock = useMemorizedSelector(healthRecordEntryEditLockSelectors.getEditLockForEntry, { entryType, entryId });
    const isLockedByOtherUser = useMemo(() => editLock && editLock.editorAccountId !== user.accountId, [ editLock, user ]);

    switch(variant) {
        case "icon":
            const classes: string[] = [ 'fa', 'fa-edit'];
            if(className) {
                classes.push(className);
            }
            if(isLockedByOtherUser || disabled) {
                classes.push('text-secondary');
            } else {
                classes.push('clickable');
            }
            return (<i 
                className={classes.join(' ')}
                onClick={onClick}
            />);
        case "link":
        default:
            return (<Button
                onClick={onClick}
                variant="link"
                className={className}
                disabled={isLockedByOtherUser || disabled}
                title={isLockedByOtherUser ? resolveText("HealthRecordEntryEditLock_LockedByOtherUser") : ''}
            >
                {isLockedByOtherUser ? <i className="fa fa-lock me-2" /> : null} {resolveText("Edit...")}
            </Button>);
    }

}