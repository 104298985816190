import { Card, Col, Row } from "react-bootstrap";
import { PatientProfileJumbotron } from "../../components/Patients/PatientProfileJumbotron";
import { useNavigate, useParams } from "react-router-dom";
import { PatientCalendar } from "../../components/PatientPortal/PatientCalendar";
import { useMemo } from "react";
import { ActionButton } from "../../components/EntryPages/ActionButton";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { HealthRecordUrls, SharedUrls } from "../../../sharedHealthComponents/navigation/Urls";
import { JankisActionButtonDefinition } from "../../types/frontendTypes";
import { UrlIdPlaceholders } from "../../navigation/Urls";
import { HealthRecordOverview } from "../../../sharedHealthComponents/components/Patients/HealthRecordOverview";

interface PatientDashboardPageProps {}

export const PatientDashboardPage = (props: PatientDashboardPageProps) => {

    const { personId } = useParams();

    const navigate = useNavigate();
    const actions = useMemo(() => [
        {
            title: resolveText("HealthRecord"), 
            icon: 'fa-medkit', 
            onClick: () => navigate(HealthRecordUrls.HEALTHRECORD_BASEURL.replace(UrlIdPlaceholders.PERSON, personId!))
        },
        {
            title: resolveText("TodoList"), 
            icon: 'fa-list', 
            onClick: () => navigate(SharedUrls.TODOLIST + `?personId=${personId}`)
        }
    ] as JankisActionButtonDefinition[], 
    [ personId, navigate ]);

    if(!personId) {
        return null;
    }

    return (<>
        <Row>
            <Col>
                <PatientProfileJumbotron
                    personId={personId}
                    showSubscription
                />
            </Col>
            <Col>
                <PatientCalendar 
                    personId={personId}
                    height={600}
                />
            </Col>
        </Row>
        <Row className="my-3">
            {actions.map((action,idx) => (
                <Col key={idx} lg={2} md={4} sm={4} xs={6}>
                    <ActionButton
                        isCtrlPressed={false}
                        buttonIndex={idx+1}
                        onClick={action.onClick}
                    >
                        {action.title}
                        <div>
                            <i className={`fa ${action.icon} display-3`} />
                        </div>
                    </ActionButton>
                </Col> 
            ))}
        </Row>
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <Card.Title>{resolveText("Encounters")}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card>
                    <Card.Header>
                        <Card.Title>{resolveText("Appointments")}</Card.Title>
                    </Card.Header>
                    <Card.Body>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <HealthRecordOverview
            personId={personId}
        />
    </>);

}
export default PatientDashboardPage;