import { Fragment, useMemo } from "react";
import { formatPerson } from "../../../sharedHealthComponents/helpers/Formatters";
import { Models } from "../../types/models";
import { groupBy } from "../../../sharedCommonComponents/helpers/CollectionHelpers";

interface DischargeLetterPreviewProps {
    person?: Models.Individuals.Person;
    letter: Models.Documentation.DischargeLetter;
}

export const DischargeLetterPreview = (props: DischargeLetterPreviewProps) => {

    const { person, letter } = props;

    const typeGroupedItems = useMemo(() => groupBy(letter.items, x => x.type), [ letter.items ]);

    return (<>
        <h3>{letter.title} {person ? formatPerson(person) : null}</h3>
        {typeGroupedItems.map(typeGroup => (<Fragment key={typeGroup.key}>
            <h4>{typeGroup.key}</h4>
            <p>
                {typeGroup.items.map(item => item.text).join(" ")}
            </p>
        </Fragment>))}
    </>);

}