import { addSeconds, compareAsc, differenceInDays, differenceInMinutes, differenceInMonths, differenceInYears, isBefore, startOfDay } from "date-fns";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";

export const formatAge = (date: Date, birthDate: Date) => {
    const ageInYears = differenceInYears(date, birthDate);
    const ageTextTemplate = resolveText("AgeTemplate");
    if(ageInYears >= 1) {
        return ageTextTemplate.replace("{0}", `${ageInYears} ${resolveText("years")}`);
    } else if(ageInYears >= 0) {
        const ageInMonths = differenceInMonths(date, birthDate);
        if(ageInMonths > 1) {
            return ageTextTemplate.replace("{0}", `${ageInMonths} ${resolveText("months")}`);
        }
        const ageInDays = differenceInDays(date, birthDate);
        return ageTextTemplate.replace("{0}", `${ageInDays} ${resolveText("days")}`);
    } else {
        return resolveText("Age_Prenatal");
    }
}
export const formatDateDifference = (date: Date, referenceDate: Date) => {
    const daysFromNow = differenceInDays(startOfDay(date), startOfDay(referenceDate));
    let deltaText;
    const agoTextTemplate = resolveText("TimeAgoTemplate");
    const inFutureTextTempalte = resolveText("TimeInFutureTemplate");
    if(daysFromNow === 0) {
        deltaText = resolveText("Today");
    } else if(daysFromNow === 1) {
        deltaText = resolveText("Tomorrow");
    } else if(daysFromNow === -1) {
        deltaText = resolveText("Yesterday");
    } else if(Math.abs(daysFromNow) <= 30) {
        if(daysFromNow > 0) {
            deltaText = inFutureTextTempalte.replace("{0}", `${Math.abs(daysFromNow)} ${resolveText("days")}`);
        } else {
            deltaText = agoTextTemplate.replace("{0}", `${Math.abs(daysFromNow)} ${resolveText("days")}`);
        }
    } else if(Math.abs(daysFromNow) <= 365) {
        const monthsFromNow = differenceInMonths(date, referenceDate);
        if(monthsFromNow > 0) {
            deltaText = inFutureTextTempalte.replace("{0}", `${Math.abs(monthsFromNow)} ${resolveText("months")}`);
        } else {
            deltaText = agoTextTemplate.replace("{0}", `${Math.abs(monthsFromNow)} ${resolveText("months")}`);
        }
    } else {
        const yearsFromNow = differenceInYears(date, referenceDate);
        if(yearsFromNow > 0) {
            deltaText = inFutureTextTempalte.replace("{0}", `${Math.abs(yearsFromNow)} ${resolveText("months")}`);
        } else {
            deltaText = agoTextTemplate.replace("{0}", `${Math.abs(yearsFromNow)} ${resolveText("years")}`);
        }
    }
    return deltaText;
}
export const formatDateDifferenceAndAge = (date: Date, birthDate: Date | undefined, now: Date) => {
    let deltaText = formatDateDifference(date, now);
    if(birthDate) {
        const ageDelta = formatAge(date, birthDate);
        deltaText += `, ${ageDelta}`;
    }
    return deltaText;
}
export const formatTimeDifference = (date: Date, now: Date) => {
    const minutesAgo = differenceInMinutes(now, date);
    const agoTextTemplate = resolveText("TimeAgoTemplate");
    const deltaText = agoTextTemplate.replace("{0}", `${Math.abs(minutesAgo)} ${resolveText("minutes")}`);
    return deltaText;
}
export const formatElapsedTime = (seconds: number | undefined) => {
    if(!seconds) {
        return '---';
    }
    let remainingSeconds = seconds;
    const hours = Math.floor(remainingSeconds / 3600);
    remainingSeconds -= hours * 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    remainingSeconds -= minutes * 60;
    return `${hours}:${(minutes + '').padStart(2, '0')}:${(remainingSeconds.toFixed(0).padStart(2, '0'))}`;
}
/*
* Sort timestamps and return only those timestamps that are separated at least by the split threshold
*/
export const splitTimestamps = (timestamps: Date[], splitThresholdInSeconds: number): Date[] => {
    const sortedTimestamps = [...timestamps].sort((a: Date, b: Date) => compareAsc(a,b));
    let lastTimestamp: Date | null = null;
    const splittedTimestamps: Date[] = [];
    for (const t of sortedTimestamps) {
        if(lastTimestamp !== null && isBefore(t, addSeconds(lastTimestamp, splitThresholdInSeconds))) {
            continue;
        }
        splittedTimestamps.push(t);
        lastTimestamp = t;
    }
    return splittedTimestamps;
}