import { ReactNode, useContext, useState } from 'react';
import { Button, Col, FormCheck, Row } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import UserContext from '../../../localComponents/contexts/UserContext';
import { needsHiding } from '../../../localComponents/helpers/HealthRecordEntryHelpers';
import { HealthRecordEntryType, MedicalProcessType } from '../../../localComponents/types/enums';
import { ViewModels } from '../../../localComponents/types/viewModels';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { formatClassificationReference, formatDate, formatDateTime, formatDiagnosisNameAndCode, formatDiagnosticTestNameOfResult, formatDispension, formatDrug, formatMedicalProcedureCodeAndName, formatQuestionnaireAnswers } from '../../helpers/Formatters';
import { DiagnosticTestValueView } from '../TestResults/DiagnosticTestValueView';
import { HidableHealthRecordEntryValue } from '../HidableHealthRecordEntryValue';
import { confirmUnhide, getIconForHealthRecordEntryType, unhideHealthRecordEntry } from '../../helpers/HealthRecordEntryHelpers';
import { useAppDispatch, useAppSelector } from '../../../localComponents/redux/store/healthRecordStore';
import { DocumentButtons } from '../TestResults/DocumentButtons';
import { HealthRecordEntryAdditionalInfoButtons } from '../HealthRecordEntryAdditionalInfoButtons';
import { Models } from '../../../localComponents/types/models';
import { relatedEntriesActions, relatedEntriesSelectors } from '../../redux/slices/relatedHealthRecordEntriesSlice';
import { MedicalTreatmentProcessViewModels } from '../../types/frontendTypes';
import { useNavigate } from 'react-router-dom';
import { useMemorizedSelector } from '../../redux/helpers/ReduxHooks';
import { PatientNoteTimelineItemText } from '../Notes/PatientNoteTimelineItemText';
import { FormattedMedicalProcedureOutcome } from '../MedicalProcedures/FormattedMedicalProcedureOutcome';
import { buildHealthRecordUrl } from '../../navigation/Urls';

interface PatientTimelineItemProps {
    entry: ViewModels.HealthRecordEntries.IHealthRecordEntryViewModelUnknownType;
}

export const PatientTimelineItem = (props: PatientTimelineItemProps) => {

    const entry = props.entry;
    const user = useContext(UserContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const activeLinkingSession = useAppSelector(state => state.relatedEntries.linkingSession);
    const [ isLinking, setIsLinking ] = useState<boolean>(false);
    const entryReference: Models.HealthRecordEntryReference = {
        entryType: entry.type,
        entryId: entry.id
    };
    const isLinked = useMemorizedSelector(relatedEntriesSelectors.createSelectIsLinked, { entryReference });
    const hideValue = needsHiding(entry, user!);

    let colorVariant = "primary";
    const symbol = getIconForHealthRecordEntryType(entry.type);
    let body: ReactNode = null;
    if(entry.type === HealthRecordEntryType.Note) {
        const note = entry as ViewModels.HealthRecordEntries.IPatientNoteViewModel;
        colorVariant = "primary";
        body = (<PatientNoteTimelineItemText note={note} />);
    }
    else if(entry.type === HealthRecordEntryType.Diagnosis) {
        const diagnosis = entry as ViewModels.HealthRecordEntries.DiagnosisViewModel;
        colorVariant = "success";
        body = (<>
            {resolveText("HealthRecordEntryType_Diagnosis")}: <strong>{formatDiagnosisNameAndCode(diagnosis)}</strong>
        </>);
    }
    else if(entry.type === HealthRecordEntryType.Allergy) {
        const allergy = entry as ViewModels.HealthRecordEntries.AllergyViewModel;
        colorVariant = "success";
        body = (<>
            {resolveText("HealthRecordEntryType_Allergy")}: <strong>{formatDiagnosisNameAndCode(allergy)}</strong>
        </>);
    }
    else if([ HealthRecordEntryType.MedicationDispension, HealthRecordEntryType.Immunization, HealthRecordEntryType.Infusion ].includes(entry.type)) {
        const medicationDispension = entry as ViewModels.HealthRecordEntries.MedicationDispensionViewModel;
        colorVariant = "secondary";
        body = (<>
            {formatDrug(medicationDispension.drug)}: {formatDispension(medicationDispension)}
        </>);
    }
    else if(entry.type === HealthRecordEntryType.Document) {
        const document = entry as ViewModels.HealthRecordEntries.PatientDocumentViewModel;
        colorVariant = "secondary";
        body = (<>
            <DocumentButtons documentId={document.id} /> {document.fileName}
        </>);
    }
    else if(entry.type === HealthRecordEntryType.TestResult) {
        const testResult = entry as ViewModels.HealthRecordEntries.IDiagnosticTestResultViewModel;
        colorVariant = "info";
        body = (<>
            <div><b>{formatDiagnosticTestNameOfResult(testResult)}</b></div>
            <DiagnosticTestValueView testResult={testResult} />
        </>);
    } else if(entry.type === HealthRecordEntryType.Procedure) {
        const procedure = entry as ViewModels.HealthRecordEntries.MedicalProcedureViewModel;
        colorVariant = "primary";
        body = (<>
            <div><b>{formatMedicalProcedureCodeAndName(procedure)}</b></div>
            {!!procedure.bodyStructure || !!procedure.outcome
            ? <div>
                {procedure.bodyStructure 
                ? <span className='me-2'>
                    {formatClassificationReference(procedure.bodyStructure)}
                </span> : null}
                <FormattedMedicalProcedureOutcome outcome={procedure.outcome} />
            </div> : null}
        </>);
    } else if(entry.type === HealthRecordEntryType.Questionnaire) {
        const questionnaireAnswers = entry as ViewModels.HealthRecordEntries.QuestionnaireAnswersViewModel;
        colorVariant = "primary";
        body = (<>
            <div>{resolveText("Questionnaire")}: <b>{formatQuestionnaireAnswers(questionnaireAnswers)}</b></div>
        </>);
    } else if(entry.type === HealthRecordEntryType.Equipment) {
        const equipement = entry as ViewModels.HealthRecordEntries.AttachedEquipmentViewModel;
        body = (<>
            <div>{resolveText("Equipment")}: <b>{equipement.equipmentType.displayName}</b></div>
        </>);
    } else if(entry.type === HealthRecordEntryType.Pregnancy) {
        const pregnancy = entry as ViewModels.HealthRecordEntries.PregnancyViewModel;
        body = (<>
            <div>{resolveText("Pregnancy")}: <b>{formatDate(new Date(pregnancy.timestamp))}</b></div>
        </>);
    } else if(entry.type === HealthRecordEntryType.Isolation) {
        const isolation = entry as ViewModels.HealthRecordEntries.IsolationViewModel;
        colorVariant = "danger";
        body = (<>
            <div>
                {resolveText("Isolation")}:&nbsp;
                <b>{isolation.reason}</b>&nbsp;
                ({formatDate(new Date(isolation.timestamp))} - {isolation.endTime ? formatDate(new Date(isolation.endTime)) : null})
            </div>
        </>);
    } else if(entry.type === HealthRecordEntryType.Specimen) {
        const specimen = entry as ViewModels.HealthRecordEntries.SpecimenViewModel;
        body = (<>
            <div>{resolveText("Specimen")} <b>{specimen.bodyStructure.displayName}</b></div>
        </>);
    } else if(entry.type === HealthRecordEntryType.Process) {
        const treatmentProcess = entry as MedicalTreatmentProcessViewModels;
        switch(treatmentProcess.processType) {
            case MedicalProcessType.Surgery:
                const surgery = treatmentProcess as ViewModels.HealthRecordEntries.SurgeryViewModel;
                body = (<Row>
                    <Col xs="auto">
                        <Button
                            onClick={() => navigate(buildHealthRecordUrl(workup.personId, `/edit/workup/${workup.id}`))}
                            size='sm'
                        >
                            {resolveText("Open")}
                        </Button>
                    </Col>
                    {surgery.isFinalized
                    ? <Col xs="auto">
                        <i className='fa fa-check green' /> {resolveText("Workup_Finalized")}
                    </Col> : null}
                    <Col>
                        {resolveText("Surgery")}
                        {surgery.code ? <> for <strong>{surgery.code.displayName}</strong></> : null}
                    </Col>
                </Row>);
                break;
            case MedicalProcessType.Workup:
                const workup = treatmentProcess as ViewModels.HealthRecordEntries.WorkupViewModel;
                body = (<Row>
                    <Col xs="auto">
                        <Button
                            onClick={() => navigate(buildHealthRecordUrl(workup.personId, `/edit/workup/${workup.id}`))}
                            size='sm'
                        >
                            {resolveText("Open")}
                        </Button>
                    </Col>
                    {workup.isFinalized
                    ? <Col xs="auto">
                        <i className='fa fa-check green' /> {resolveText("Workup_Finalized")}
                    </Col> : null}
                    <Col>
                        {resolveText("Workup")}
                        {workup.leadingDiagnosis ? <> for <strong>{workup.leadingDiagnosis.displayName}</strong></> : null}
                    </Col>
                </Row>);
                break;
        }
    } else if(entry.type === HealthRecordEntryType.MedicalAlert) {
        const medicalAlert = entry as ViewModels.HealthRecordEntries.MedicalAlertViewModel;
        colorVariant = "danger";
        body = (<>
            <div><strong>CAVE</strong> {medicalAlert.text}</div>
        </>);
    }


    const unhide = () => {
        if(hideValue) {
            confirmUnhide(() => unhideHealthRecordEntry(dispatch, entry.type, entry.id))
        } else {
            unhideHealthRecordEntry(dispatch, entry.type, entry.id);
        }
    }

    const linkEntry = () => {
        setIsLinking(true);
        dispatch(relatedEntriesActions.addLink({
            args: {
                personId: entry.personId,
                targetEntryType: entry.type,
                targetEntryId: entry.id
            },
            onFinally: () => setIsLinking(false)
        }));
    }
    const unlinkEntry = () => {
        setIsLinking(true);
        dispatch(relatedEntriesActions.removeLink({
            args: {
                personId: entry.personId,
                targetEntryType: entry.type,
                targetEntryId: entry.id
            },
            onFinally: () => setIsLinking(false)
        }));
    }

    return (
    <>
        <Alert variant={colorVariant} className="px-2 py-1">
            <Row>
                {activeLinkingSession
                ? <Col xs="auto">
                    <FormCheck
                        checked={isLinked}
                        onChange={(e:any) => e.target.checked ? linkEntry() : unlinkEntry()}
                        disabled={isLinking}
                    />
                </Col> : null}
                <Col xs="auto">
                    <span className='timeline-item-symbol'><i className={`fa ${symbol}`} /></span>
                </Col>
                <Col>
                    <Row>
                        <Col><small>{formatDateTime(new Date(entry.timestamp))}</small></Col>
                        <Col xs="auto">
                            <HealthRecordEntryAdditionalInfoButtons
                                personId={entry.personId}
                                entryType={entry.type}
                                entryId={entry.id}
                                opinions={entry.healthProfessionalOpinions}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <HidableHealthRecordEntryValue
                                hideValue={hideValue}
                                hasBeenSeenBySharer={entry.hasBeenSeenBySharer}
                                onMarkAsSeen={unhide}
                            >
                                {body}
                            </HidableHealthRecordEntryValue>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Alert>
    </>);

}