import { useState, useCallback, useEffect, useContext } from "react";
import { Nav, InputGroup, FormControl, FormGroup, Row, FormLabel, Col } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { MenuModal } from "../../modals/MenuModal";
import UserContext from "../../contexts/UserContext";
import { NavigationContextType } from "../../types/enums";
import { Models } from "../../types/models";
import { ViewModels } from "../../types/viewModels";
import { formatDepartment } from "../../../sharedHealthComponents/helpers/Formatters";
import { useAppDispatch, useAppSelector } from "../../redux/store/healthRecordStore";
import { navigationContextSlice } from "../../redux/slices/navigationContextSlice";

interface UniversalSearchMenuProps {}

export const UniversalSearchMenu = (props: UniversalSearchMenuProps) => {

    const user = useContext(UserContext) as ViewModels.EmployeeUserViewModel;
    const navigationContext = useAppSelector(state => state.navigationContext);
    const [ showMenuModal, setShowMenuModal ] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const setNavigationContext = useCallback((context: Models.Navigation.NavigationContext) => {
        dispatch(navigationContextSlice.actions.setContext(context));
    }, [ dispatch]);

    const onKeyPress = useCallback((e: KeyboardEvent) => {
        if(e.key === 'Enter' && e.altKey && !e.shiftKey && !e.ctrlKey) {
            setShowMenuModal(true);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keyup', onKeyPress);
        return () => {
            window.removeEventListener('keyup', onKeyPress);
        }
    }, [ onKeyPress ]);

    const parseNavigationContext = useCallback((str: string): Models.Navigation.NavigationContext => {
        const splitted = str.split('|');
        if(splitted.length !== 2) {
            return { type: NavigationContextType.None };
        }
        const lowerContextType = splitted[0].toLowerCase();
        const entityId = splitted[1];
        const matchingContextType = Object.values(NavigationContextType).find(x => x.toLowerCase() === lowerContextType);
        if(!matchingContextType) {
            return { type: NavigationContextType.None };
        }
        return {
            type: matchingContextType,
            entityId: entityId
        };
    }, []);

    return (<>
        <Nav className='no-print mx-auto w-100'>
            <InputGroup className='mx-3 mx-auto' style={{ minWidth: '300px', maxWidth: '550px' }}>
                <InputGroup.Text>
                    <i className='fa fa-search' />
                </InputGroup.Text>
                <FormControl
                    onClick={() => setShowMenuModal(true)}
                    onKeyDown={e => {
                        if(e.key.length === 1) {
                            setShowMenuModal(true);
                        }
                    }}
                    placeholder={resolveText("Search") + " (Alt+Enter)"}
                />
            </InputGroup>
        </Nav>
        <Nav className='no-print ms-auto'>
            {/* Context selector */}
            <FormGroup as={Row}>
                <FormLabel column xs="auto" className="pe-0">Context</FormLabel>
                <Col>
                    <FormControl
                        as="select"
                        value={`${navigationContext.type}|${navigationContext.entityId}`}
                        onChange={e => setNavigationContext(parseNavigationContext(e.target.value))}
                    >
                        <option value={`${NavigationContextType.None}|`}>{resolveText("NavigationContextType_None")}</option>
                        {user.institutions.map(institution => (
                            <option 
                                key={`${NavigationContextType.Institution}|${institution.id}`} 
                                value={`${NavigationContextType.Institution}|${institution.id}`}
                            >
                                {/* <i className="fa fa-hospital-o" /> */} {institution.name}
                            </option>
                        ))}
                        {user.departments.map(department => (
                            <option
                                key={`${NavigationContextType.Department}|${department.id}`}
                                value={`${NavigationContextType.Department}|${department.id}`}
                            >
                                {/* <i className="fa fa-home" /> */} {formatDepartment(department)}
                            </option>
                        ))}
                    </FormControl>
                </Col>
            </FormGroup>
        </Nav>
        <MenuModal show={showMenuModal} onClose={() => setShowMenuModal(false)} context={navigationContext} />
    </>);

}