import { Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MedicalTextPartType } from '../../../localComponents/types/enums';
import { Models } from '../../../localComponents/types/models';
import { MEDICAL_TEXT_ABBREVIATION_CLASS, MEDICAL_TEXT_DISEASE_CLASS, MEDICAL_TEXT_SNOMEDCT_CLASS } from '../../helpers/MedicalTextEditor/MedicalTextNodeBuilder';

interface MedicalTextViewerProps {
    text?: Models.MedicalTextEditor.MedicalText;
    parts?: Models.MedicalTextEditor.MedicalTextPart[];
}

export const MedicalTextViewer = (props: MedicalTextViewerProps) => {

    const parts = props.parts ?? props.text?.parts;
    if(!parts) {
        return null;
    }

    return (
        <>
            {parts.map((part,partIndex) => {
                const type = part.type;
                const fallbackId = `part-${partIndex}`;
                switch(type) {
                    case MedicalTextPartType.Text:
                        const textPart = part as Models.MedicalTextEditor.TextMedicalTextPart;
                        const splittedText = textPart.text.split('\n');
                        return <Fragment key={part.id ?? fallbackId}>
                            {splittedText[0]}
                            {splittedText.filter((_,paragraphIndex) => paragraphIndex > 0).map((paragraph,idx) => (
                            <Fragment key={`paragraph-${idx}`}>
                                <br />{paragraph}
                            </Fragment>)
                            )}
                        </Fragment>;
                    case MedicalTextPartType.Abbreviation:
                        const abbreviationPart = part as Models.MedicalTextEditor.AbbreviationMedicalTextPart;
                        return (
                            <OverlayTrigger
                                key={part.id ?? fallbackId}
                                overlay={<Tooltip>{abbreviationPart.fullText}</Tooltip>}
                            >
                                <span className={`text-decoration-underline ${MEDICAL_TEXT_ABBREVIATION_CLASS}`}>{abbreviationPart.abbreviation}</span>
                            </OverlayTrigger>
                        );
                    case MedicalTextPartType.SnomedCtConcept:
                        const snomedCtPart = part as Models.MedicalTextEditor.SnomedCtConceptMedicalTextPart;
                        return (
                            <OverlayTrigger
                                key={part.id ?? fallbackId}
                                overlay={<Tooltip>{snomedCtPart.conceptId}</Tooltip>}
                            >
                                <span className={`text-decoration-underline ${MEDICAL_TEXT_SNOMEDCT_CLASS}`}>{snomedCtPart.term}</span>
                            </OverlayTrigger>
                        );
                    case MedicalTextPartType.Disease:
                        const diseasePart = part as Models.MedicalTextEditor.DiseaseMedicalTextPart;
                        return (
                            <OverlayTrigger
                                key={part.id ?? fallbackId}
                                overlay={<Tooltip>{diseasePart.icd11Code}</Tooltip>}
                            >
                                <span className={`text-decoration-underline ${MEDICAL_TEXT_DISEASE_CLASS}`}>{diseasePart.name}</span>
                            </OverlayTrigger>
                        );
                    default:
                        return null;
                }
            })}
        </>
    );

}