import { GenericAutocomplete, GenericAutocompleteImplementationProps } from '../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete';
import { formatStock } from '../../helpers/Formatters';
import { ViewModels } from '../../types/viewModels';

interface StockAutocompleteProps extends GenericAutocompleteImplementationProps<ViewModels.StockViewModel> {
    includeChildStocks?: boolean;
}

export const StockAutocomplete = (props: StockAutocompleteProps) => {

    return (
        <GenericAutocomplete<ViewModels.StockViewModel>
            {...props}
            apiPath='api/stocks'
            displayFunc={formatStock}
            additionalParameters={props.includeChildStocks ? {
                'includeChildStocks': 'true'
            } : undefined}
        />
    );

}