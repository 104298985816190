import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { EncounterStatus, EncounterType, HealthRecordEntryType, TriagePriority } from "../../../localComponents/types/enums";
import { ViewModels } from "../../../localComponents/types/viewModels";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areHealthRecordEntryFiltersEqual } from "../../helpers/FilterHelpers";
import { EncountersFilter } from "../../types/frontendTypes";
import { RemoteState } from "../../types/reduxInterfaces";
import { deleteActionBuilder, loadItemsActionBuilder, postActionBuilder } from "../helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../helpers/DefaultInitialState";
import { createDefaultHealthRecordEntryReducers } from "../helpers/DefaultReducers";
import { createHealthRecordEntryApiActions } from "../helpers/HealthRecordEntrySliceActions";
import { healthRecordEntryQueryBuilder } from "../helpers/QueryBuilders";
import { createHealthRecordEntrySelectors } from "../helpers/HealthRecordEntrySliceSelectors";
import { Models } from "../../../localComponents/types/models";

interface EncountersState extends RemoteState<ViewModels.HealthRecordEntries.EncounterViewModel, EncountersFilter> {}
const initialState: EncountersState = {
    ...defaultRemoteInitialState(),
};
export const encountersSlice = createSlice({
    name: 'encounters',
    initialState: initialState,
    reducers: {
        ...createDefaultHealthRecordEntryReducers(initialState),
        setItemsWithoutPersonFilter: (state, action: PayloadAction<ViewModels.HealthRecordEntries.EncounterViewModel[]>) => {
            state.items = action.payload;
        },
        addOrUpdateItemWithoutPersonFilter: (state, action: PayloadAction<ViewModels.HealthRecordEntries.EncounterViewModel>) => {
            const item = action.payload;
            if(state.items.some(x => x.id === item.id)) {
                state.items = state.items.map(x => x.id === item.id ? item : x);
            } else {
                state.items.push(item);
            }
        },
        addOrUpdateItemsWithoutPersonFilter: (state, action: PayloadAction<ViewModels.HealthRecordEntries.EncounterViewModel[]>) => {
            const items = action.payload;
            const existingIds = state.items.map(x => x.id);
            const newItems = items.filter(item => !existingIds.includes(item.id));
            const existingUpdatedItems = state.items.map(existingItem => {
                const updatedItem = items.find(x => x.id === existingItem.id);
                return updatedItem ?? existingItem;
            });
            state.items = existingUpdatedItems.concat(newItems);
        },
        changeStatus: (state, action: PayloadAction<ChangeEncounterStatusArgs>) => {
            const encounter = state.items.find(x => x.id === action.payload.encounterId);
            if(!encounter) {
                return;
            }
            encounter.status = action.payload.newStatus;
        },
        changeTriagePriority: (state, action: PayloadAction<ChangeEncounterTriagePriorityArgs>) => {
            const encounter = state.items.find(x => x.id === action.payload.encounterId);
            if(!encounter) {
                return;
            }
            encounter.triagePriority = action.payload.newPriority;
        },
        addDepartment: (state, action: PayloadAction<AddDepartmentToEncounterArgs>) => {
            const encounter = state.items.find(x => x.id === action.payload.encounterId);
            if(!encounter) {
                return;
            }
            if(encounter.departmentIds.includes(action.payload.departmentId)) {
                return;
            }
            encounter.departmentIds.push(action.payload.departmentId);
            encounter.departments.push(action.payload.department);
        },
        removeDepartment: (state, action: PayloadAction<RemoveDepartmentFromEncounterArgs>) => {
            const encounter = state.items.find(x => x.id === action.payload.encounterId);
            if(!encounter) {
                return;
            }
            encounter.departmentIds = encounter.departmentIds.filter(x => x !== action.payload.departmentId);
            encounter.departments = encounter.departments.filter(x => x.id !== action.payload.departmentId);
        },
        assignPractitioner: (state, action: PayloadAction<AddPractitionerToEncounterArgs>) => {
            const encounter = state.items.find(x => x.id === action.payload.encounterId);
            if(!encounter) {
                return;
            }
            if(encounter.practitionerIds.includes(action.payload.practitionerId)) {
                return;
            }
            encounter.practitionerIds.push(action.payload.practitionerId);
            encounter.practitioners.push(action.payload.practitioner);
        },
        removePractitioner: (state, action: PayloadAction<RemovePractitionerFromEncounterArgs>) => {
            const encounter = state.items.find(x => x.id === action.payload.encounterId);
            if(!encounter) {
                return;
            }
            encounter.practitionerIds = encounter.practitionerIds.filter(x => x !== action.payload.practitionerId);
            encounter.practitioners = encounter.practitioners.filter(x => x.id !== action.payload.practitionerId);
        }
    }
});

const encounterQueryBuilder = (state: RootState, sliceState: EncountersState) => {
    const queryParams = healthRecordEntryQueryBuilder(state, sliceState, encounterFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.institutionId) {
            queryParams.push({ key: 'institutionId', value: filter.institutionId });
        }
        if(filter.departmentId) {
            queryParams.push({ key: 'departmentId', value: filter.departmentId });
        }
        if(filter.practitionerId) {
            queryParams.push({ key: 'practitionerId', value: filter.practitionerId });
        }
        if(filter.status) {
            queryParams.push({ key: 'status', value: filter.status });
        }
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
    }
    return queryParams;
}
const encounterFilterComparer = (f1?: EncountersFilter, f2?: EncountersFilter) => {
    if(!areHealthRecordEntryFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText
        && f1!.institutionId === f2!.institutionId
        && f1!.departmentId === f2!.departmentId
        && f1!.practitionerId === f2!.practitionerId
        && f1!.status === f2!.status;
}
interface ChangeEncounterStatusArgs {
    encounterId: string;
    newStatus: EncounterStatus;
}
interface ChangeEncounterTriagePriorityArgs {
    encounterId: string;
    newPriority: TriagePriority;
}
interface AddDepartmentToEncounterArgs {
    encounterId: string;
    departmentId: string;
    department: Models.Organizations.Department;
}
interface AddPractitionerToEncounterArgs {
    encounterId: string;
    practitionerId: string;
    practitioner: Models.Individuals.Practitioner;
}
interface RemoveDepartmentFromEncounterArgs {
    encounterId: string;
    departmentId: string;
}
interface RemovePractitionerFromEncounterArgs {
    encounterId: string;
    practitionerId: string;
}
export const encountersActions = {
    ...createHealthRecordEntryApiActions(
        HealthRecordEntryType.Encounter,
        'encounters', 
        encountersSlice.actions, 
        state => state.encounters,
        encounterQueryBuilder,
        encounterFilterComparer
    ),
    loadForInstitution: loadItemsActionBuilder(
        (args?: { institutionId: string}) => `api/institutions/${args!.institutionId}/encounters`,
        encounterQueryBuilder,
        encounterFilterComparer,
        () => resolveText("Encounters_CouldNotLoad"),
        encountersSlice.actions.setIsLoading,
        encountersSlice.actions.setItemsWithoutPersonFilter,
        encountersSlice.actions.addOrUpdateItemsWithoutPersonFilter,
        encountersSlice.actions.setHasMoreItems,
        encountersSlice.actions.setLastUsedFilter,
        state => state.encounters
    ),
    changeStatus: postActionBuilder(
        (args: ChangeEncounterStatusArgs) => `api/encounters/${args!.encounterId}/changestatus`,
        () => resolveText('Encounter_CouldNotChangeStatus'),
        encountersSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(encountersSlice.actions.changeStatus(args))
    ),
    changeTriagePriority: postActionBuilder(
        (args: ChangeEncounterTriagePriorityArgs) => `api/encounters/${args!.encounterId}/triage`,
        () => resolveText('Encounter_CouldNotChangeTriagePriority'),
        encountersSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(encountersSlice.actions.changeTriagePriority(args))
    ),
    addDepartment: postActionBuilder(
        (args: AddDepartmentToEncounterArgs) => `api/encounters/${args!.encounterId}/departments/${args!.departmentId}`,
        () => resolveText("Encounter_CouldNotAddDepartment"),
        encountersSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(encountersSlice.actions.addDepartment(args))
    ),
    removeDepartment: deleteActionBuilder(
        (args: RemoveDepartmentFromEncounterArgs) => `api/encounters/${args.encounterId}/departments/${args!.departmentId}`,
        () => resolveText("Encounter_SuccessfullyRemovedDepartment"),
        () => resolveText("Encounter_CouldNotRemoveDepartment"),
        (dispatch,args) => dispatch(encountersSlice.actions.removeDepartment(args))
    ),
    assignPractitioner: postActionBuilder(
        (args: AddPractitionerToEncounterArgs) => `api/encounters/${args!.encounterId}/practitioners/${args!.practitionerId}`,
        () => resolveText("Encounter_CouldNotAssignPractitioner"),
        encountersSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(encountersSlice.actions.assignPractitioner(args))
    ),
    removePractitioner: deleteActionBuilder(
        (args: RemovePractitionerFromEncounterArgs) => `api/encounters/${args.encounterId}/practitioners/${args!.practitionerId}`,
        () => resolveText("Encounter_SuccessfullyRemovedPractitioner"),
        () => resolveText("Encounter_CouldNotRemovePractitioner"),
        (dispatch,args) => dispatch(encountersSlice.actions.removePractitioner(args))
    ),
}
export const encountersSelectors = {
    ...createHealthRecordEntrySelectors(state => state.encounters),
    createSelectAdmissionsForPerson: () => createSelector(
        (state: RootState) => state.encounters.items,
        (_: RootState, args: { personId: string }) => args.personId,
        (items, personId) => personId 
            ? items.filter(x => x.personId === personId && x.encounterType === EncounterType.Admission)
            : []
    ),
};