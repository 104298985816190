import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { OrderState } from "../../types/enums";
import { MaterialOrdersFilter } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface MaterialOrdersState extends RemoteState<ViewModels.MaterialOrderViewModel, MaterialOrdersFilter> {
}
const initialState: MaterialOrdersState = {
    ...defaultRemoteInitialState(),
    filter: {
        showOnlyUnfinishedOrders: true
    }
}
export const materialOrdersSlice = createSlice({
    name: 'materialOrders',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        setAssignee: (state, action: PayloadAction<SetMaterialOrderAssigneeArgs>) => {
            const order = state.items.find(x => x.id === action.payload.orderId);
            if(!order) {
                return;
            }
            order.assignee = action.payload.assignee;
            order.assigneeViewModel = action.payload.assignee;
        },
        changeState: (state, action: PayloadAction<ChangeMaterialOrderStateArgs>) => {
            const order = state.items.find(x => x.id === action.payload.orderId);
            if(!order) {
                return;
            }
            order.state = action.payload.newState;
        },
        setHandlerNote: (state, action: PayloadAction<SetMaterialOrderHandlerNoteArgs>) => {
            const order = state.items.find(x => x.id === action.payload.orderId);
            if(!order) {
                return;
            }
            order.handlerNote = action.payload.note;
        }
    }
});

export interface SetMaterialOrderAssigneeArgs {
    orderId: string;
    assignee: ViewModels.AssigneeViewModel | undefined;
}
export interface ChangeMaterialOrderStateArgs {
    orderId: string;
    newState: OrderState;
}
export interface SetMaterialOrderHandlerNoteArgs {
    orderId: string;
    note: string;
}

const materialOrdersFilterComparer = (f1: MaterialOrdersFilter | undefined, f2: MaterialOrdersFilter | undefined) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText 
        && f1!.materialType === f2!.materialType 
        && f1!.materialId === f2!.materialId
        && f1!.showOnlyUnfinishedOrders === f2!.showOnlyUnfinishedOrders;
}

const materialOrderQueryBuilder = (state: RootState, sliceState: MaterialOrdersState) => {
    const queryParams: { [key:string]: string } = {};
    const filter = sliceState.filter;
    if(filter.materialType) {
        queryParams['materialType'] = filter.materialType + '';
    }
    if(filter.materialId) {
        queryParams['materialId'] = filter.materialId + '';
    }
    if(filter.searchText) {
        queryParams['searchText'] = filter.searchText;
    }
    if(filter.showOnlyUnfinishedOrders) {
        queryParams['showOnlyUnfinishedOrders'] = 'true';
    }
    return queryParams;
}

export const materialOrdersActions = {
    ...createRestApiActions(
        'materialOrders', 
        materialOrdersSlice.actions,
        state => state.materialOrders,
        materialOrderQueryBuilder,
        materialOrdersFilterComparer
    ),
    setAssignee: postActionBuilder(
        (args: SetMaterialOrderAssigneeArgs) => `api/materialOrders/${args!.orderId}/assign`,
        () => resolveText("MaterialOrder_Assignee_CouldNotSet"),
        materialOrdersSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(materialOrdersSlice.actions.setAssignee(args))
    ),
    changeState: postActionBuilder(
        (args: ChangeMaterialOrderStateArgs) => `api/materialOrders/${args!.orderId}/changestate`,
        () => resolveText('MaterialOrder_State_CouldNotChange'),
        materialOrdersSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(materialOrdersSlice.actions.changeState(args))
    ),
    setHandlerNote: postActionBuilder(
        (args: SetMaterialOrderHandlerNoteArgs) => `api/materialOrders/${args!.orderId}/handlernote`,
        () => resolveText('MaterialOrder_CouldNotStore'),
        materialOrdersSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(materialOrdersSlice.actions.setHandlerNote(args))
    )
};
export const materialOrdersSelectors = {
    ...createDefaultGenericItemSelectors(state => state.materialOrders),
};