import { createVerb, editVerb, printVerb } from "../../sharedHealthComponents/navigation/Urls"
import { HealthRecordEntryType } from "../types/enums"
import { DataTypeNames as ShahredDataTypeNames, UrlIdPlaceholders as SharedUrlIdPlaceholders } from '../../sharedHealthComponents/navigation/Urls';

export const UrlIdPlaceholders = {
    ...SharedUrlIdPlaceholders,
    ROOM: ':roomId',
    BED: ':bedPosition',
    FUNCTIONALGROUP: ':groupId'
};
export const DataTypeNames = {
    ...ShahredDataTypeNames,

    ROOMS: 'rooms',
    ROOM: 'room',

    BEDS: 'beds',
    BED: 'bed',

    BEDOCCUPANCIES: 'bedoccupancies',
    BEDOCCUPANCY: 'bedoccupancy',

    STOCKS: 'stocks',
    STOCK: 'stock',

    MEALMENU: 'meal-menu',

    CONSUMABLES: 'consumables',
    CONSUMABLE: 'consumable',

    RESOURCES: 'resources',
    RESOURCE: 'resource',

    MATERIALORDERS: 'materialorders',
    MATERIALORDER: 'materialorder',

    CONTACTS: 'contacts',
    CONTACT: 'contact',

    ACCOUNTS: 'accounts',
    ACCOUNT: 'account',

    ROLES: 'roles',
    ROLE: 'role',

    FUNCTIONALGROUPS: 'functionalgroups',
    FUNCTIONALGROUP: 'functionalgroup',

    PATIENTJOURNEYS: 'patientjourneys',
    PATIENTJOURNEY: 'patientjourney',

    INVOICES: 'invoices',
    INVOICE: 'invoice',

    ARTICLES: 'articles',
    ARTICLE: 'article',
};
// export interface DataTypeName {
//     singular: string;
//     plural: string;
//     contexts?: NavigationContextType[];
// }
// export const UrlDataTypes: DataTypeName[] = [
//     { singular: 'adverse-event', plural: 'adverse-events' },
//     { singular: 'contact', plural: 'contacts' },
//     { singular: 'consumable', plural: 'consumables' },
//     { singular: 'resource', plural: 'resources', contexts: [ NavigationContextType.Department ] },
//     { singular: 'material-order', plural: 'material-orders', contexts: [ NavigationContextType.Department ] },
//     { singular: 'material-order', plural: 'services', contexts: [ NavigationContextType.Department ] },
//     { singular: 'invoice', plural: 'invoices' },
// ];
export const JanKisHealthRecordUrls = {
    NURSING: 'nursing',
    CREATE_ISOLATION: `${createVerb}/${HealthRecordEntryType.Isolation}`,
    EDIT_ISOLATION: `${editVerb}/${HealthRecordEntryType.Isolation}/${UrlIdPlaceholders.ID}`,
    ORDER_SERVICE: `order/${DataTypeNames.SERVICE}`,
    CREATE_LETTER: `${createVerb}/letter`,
    EDIT_LETTER: `${editVerb}/letter/${UrlIdPlaceholders.ID}`,
}
export const JanKisUrls = {
    CREATE_SUBSCRIPTION: `/${createVerb}/subscription`,
    EDIT_SUBSCRIPTION: `/${editVerb}/subscription/${UrlIdPlaceholders.ID}`,

    CREATE_NEWS: `/${createVerb}/news`,
    EDIT_NEWS: `/${editVerb}/news/${UrlIdPlaceholders.ID}`,

    KNOWLEDGEBASE: '/knowledgebase',
    KNOWLEDGEBASE_CREATE_ARTICLE: `/knowledgebase/${createVerb}/${DataTypeNames.ARTICLE}`,
    KNOWLEDGEBASE_EDIT_ARTICLE: `/knowledgebase/${editVerb}/${DataTypeNames.ARTICLE}/${UrlIdPlaceholders.ID}`,
    KNOWLEDGEBASE_OPEN_ARTICLE: `/knowledgebase/${DataTypeNames.ARTICLE}/${UrlIdPlaceholders.ID}`,

    CONTACTS: `/${DataTypeNames.CONTACTS}`,
    CREATE_CONTACT: `/${createVerb}/${DataTypeNames.CONTACT}`,
    EDIT_CONTACT: `/${editVerb}/${DataTypeNames.CONTACT}/${UrlIdPlaceholders.ID}`,

    DEPARTMENT_EDIT_LAYOUT: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/layout`,
    PRINT_DEPARTMENT_BEDLIST: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${printVerb}/bedlist`,

    OPEN_DEPARTMENT: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.ID}`,
    ENTRYPAGE_ACTIONS: '/home/actions',
    ENTRYPAGE_CLINIC: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/clinic`,
    ENTRYPAGE_GENERIC: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/generic`,

    ROOMS: `/${DataTypeNames.ROOMS}`,
    INSTITUTION_ROOMS: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${DataTypeNames.ROOMS}`,
    INSTITUTION_DEPARTMENT_ROOMS: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.ROOMS}`,
    OPEN_ROOM: `/${DataTypeNames.ROOMS}/${UrlIdPlaceholders.ID}`,
    DEPARTMENT_OPEN_ROOM: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.ROOMS}/${UrlIdPlaceholders.ID}`,

    CREATE_BEDOCUPANCY: `/${createVerb}/${DataTypeNames.BEDOCCUPANCY}`,
    BED_CREATE_BEDOCUPANCY: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.ROOMS}/${UrlIdPlaceholders.ROOM}/${DataTypeNames.BEDS}/${UrlIdPlaceholders.BED}/${createVerb}/${DataTypeNames.BEDOCCUPANCY}`,
    EDIT_BEDOCUPANCY: `/${editVerb}/${DataTypeNames.BEDOCCUPANCY}/${UrlIdPlaceholders.ID}`,

    STOCKS: `/${DataTypeNames.STOCKS}`,
    DEPARTMENT_STOCKS: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.STOCKS}`,
    CREATE_STOCK: `/${createVerb}/${DataTypeNames.STOCK}`,
    EDIT_STOCK: `/${editVerb}/${DataTypeNames.STOCK}/${UrlIdPlaceholders.ID}`,
    OPEN_STOCK: `/${DataTypeNames.STOCK}/${UrlIdPlaceholders.ID}`,
    PRINT_STOCK_LABELS: `/${printVerb}/${DataTypeNames.STOCK}/labels`,
    DEPARTMENT_RESTOCKING: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/restocking`,

    CONSUMABLES: `/${DataTypeNames.CONSUMABLES}`,
    CREATE_CONSUMABLE: `/${createVerb}/${DataTypeNames.CONSUMABLE}`,
    EDIT_CONSUMABLE: `/${editVerb}/${DataTypeNames.CONSUMABLE}/${UrlIdPlaceholders.ID}`,
    OPEN_CONSUMABLE: `/${DataTypeNames.CONSUMABLES}/${UrlIdPlaceholders.ID}`,

    RESOURCES: `/${DataTypeNames.RESOURCES}`,
    DEPARTMENT_RESOURCES: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.RESOURCES}`,
    CREATE_RESOURCE: `/${createVerb}/${DataTypeNames.RESOURCE}`,
    EDIT_RESOURCE: `/${editVerb}/${DataTypeNames.RESOURCE}/${UrlIdPlaceholders.ID}`,
    OPEN_RESOURCE: `/${DataTypeNames.RESOURCES}/${UrlIdPlaceholders.ID}`,

    MATERIALORDERS: `/${DataTypeNames.MATERIALORDERS}`,
    OPEN_MATERIALORDER: `/${DataTypeNames.MATERIALORDERS}/${UrlIdPlaceholders.ID}`,

    EDIT_MEALMENU: `/${editVerb}/${DataTypeNames.MEALMENU}/${UrlIdPlaceholders.ID}`,

    ACCOUNTS: `/${DataTypeNames.ACCOUNTS}`,
    CREATE_ACCOUNT: `/${createVerb}/${DataTypeNames.ACCOUNT}`,
    EDIT_ACCOUNT: `/${editVerb}/${DataTypeNames.ACCOUNT}/${UrlIdPlaceholders.ID}`,

    ROLES: `/${DataTypeNames.ROLES}`,
    CREATE_ROLE: `/${createVerb}/${DataTypeNames.ROLE}`,
    EDIT_ROLE: `/${editVerb}/${DataTypeNames.ROLE}/${UrlIdPlaceholders.ID}`,
    OPEN_ROLE: `/${DataTypeNames.ROLES}/${UrlIdPlaceholders.ID}`,

    FUNCTIONAL_GROUPS: `/${DataTypeNames.FUNCTIONALGROUPS}`,
    CREATE_FUNCTIONALGROUP: `/${createVerb}/${DataTypeNames.FUNCTIONALGROUP}`,
    EDIT_FUNCTIONALGROUP: `/${editVerb}/${DataTypeNames.FUNCTIONALGROUP}/${UrlIdPlaceholders.ID}`,
    FUNCTIONALGROUP_MEMBERS: `/${DataTypeNames.FUNCTIONALGROUPS}/${UrlIdPlaceholders.FUNCTIONALGROUP}/members`,

    MY_PATIENTS: '/mypatients',
    PERSONS: `/${DataTypeNames.PERSONS}`,
    CREATE_PERSON: `/${createVerb}/${DataTypeNames.PERSON}`,
    PATIENT_DASHBOARD: `/patientdashboard/${UrlIdPlaceholders.PERSON}`,

    PRACTITIONERS: `/${DataTypeNames.PRACTITIONERS}`,
    CREATE_PRACTITIONER: `/${createVerb}/${DataTypeNames.PRACTITIONER}`,
    EDIT_PRACTITIONER: `/${editVerb}/${DataTypeNames.PRACTITIONER}/${UrlIdPlaceholders.ID}`,

    SHEHRD_SYNC: '/shehrd-sync',
    CHECKIN_TERMINAL: '/checkin',
    INSTITUTION_CHECKIN_TERMINAL: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/checkin`,

    ENCOUNTERS: `/${DataTypeNames.ENCOUNTERS}`,
    CREATE_ENCOUNTER: `/${createVerb}/${DataTypeNames.ENCOUNTER}`,
    DEPARTMENT_CREATE_ENCOUNTER: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${createVerb}/${DataTypeNames.ENCOUNTER}`,
    EDIT_ENCOUNTER: `/${editVerb}/${DataTypeNames.ENCOUNTER}/${UrlIdPlaceholders.ID}`,
    QUICK_ADMIT: '/quick-admit',

    INVOICES: `/${DataTypeNames.INVOICES}`,
    CREATE_INVOICE_FOR_INSTITUTION: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${createVerb}/${DataTypeNames.INVOICE}`,
    CREATE_INVOICE_FOR_ENCOUNTER: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${DataTypeNames.PERSONS}/${UrlIdPlaceholders.PERSON}/${DataTypeNames.ENCOUNTERS}/${UrlIdPlaceholders.ENCOUNTER}/${createVerb}/`,
    EDIT_INVOICE: `/${editVerb}/${DataTypeNames.INVOICE}/${UrlIdPlaceholders.ID}`,
    PRINT_INVOICE: `/${printVerb}/${DataTypeNames.INVOICE}/${UrlIdPlaceholders.ID}`,

    WAITINGROOM: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/clinic`,
    MEALSERVICE_MENUS: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/menus`,
    
    DEPARTMENT_PATIENTJOURNEYS: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.PATIENTJOURNEYS}`,
    OPEN_DEPARTMENT_PATIENTJOURNEY: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.PATIENTJOURNEYS}/${UrlIdPlaceholders.ID}`,
    PATIENT_HANDOVER: '/ward/handover',

    LETTERTEMPLATES: '/lettertemplates',
    CREATE_LETTERTEMPLATE: `/${createVerb}/lettertemplate`,
    EDIT_LETTERTEMPLATE: `/${editVerb}/lettertemplate/${UrlIdPlaceholders.ID}`
}

export const PatientPortalUrls = {
    CALENDAR: '/calendar',
    ORDER_MEAL: '/mealservice/order',
    CREATE_APPOINTMENT: '/create/appointment',
    MY_APPOINTMNETS: '/appointments',
    SOCIALSERVICES: '/socialservices',
    LIBRARY: '/library'
}