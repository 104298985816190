import 'react-big-calendar/lib/css/react-big-calendar.css';

import { format, parse, startOfWeek, getDay, addHours, startOfToday, endOfWeek, endOfDay, startOfDay } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Calendar, Event, dateFnsLocalizer } from 'react-big-calendar';
import { TimeRange } from '../../types/frontendTypes';
import { useEffect, useState } from 'react';
import { last } from '../../../sharedCommonComponents/helpers/CollectionHelpers';

interface LocalizedCalendarProps {
    events: Event[];
    onEventSelected?: (event: Event) => void;
    onVisibleTimeRangeChanged?: (timeRange: TimeRange) => void;
    height?: number;
}

const locales = {
    'en-US': enUS
};
const startOfWeekOptions: { weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 } = {
    weekStartsOn: 1
};
const localizer = dateFnsLocalizer({
    format: format,
    parse: parse,
    startOfWeek: (date: Date, options: any) => startOfWeek(date, {
        ...startOfWeekOptions,
        ...options
    }),
    getDay: getDay,
    locales: locales,
});

export const LocalizedCalendar = (props: LocalizedCalendarProps) => {

    const { events, onEventSelected, onVisibleTimeRangeChanged, height } = props;

    const [ visibleTimeRange, setVisibleTimeRange ] = useState<TimeRange>({
        start: startOfWeek(new Date(), startOfWeekOptions).toISOString() as any,
        end: endOfWeek(new Date(), startOfWeekOptions).toISOString() as any
    });

    useEffect(() => {
        if(onVisibleTimeRangeChanged) {
            onVisibleTimeRangeChanged(visibleTimeRange);
        }
    }, [ visibleTimeRange ]);

    return (
        <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultView='week'
            scrollToTime={addHours(startOfToday(), 7)}
            onRangeChange={(range) => {
                setVisibleTimeRange(Array.isArray(range) ? {
                    start: startOfDay(range[0]).toISOString() as any,
                    end: endOfDay(last(range)!).toISOString() as any
                } : {
                    start: range.start.toISOString() as any,
                    end: range.end.toISOString() as any
                })
            }}
            onSelectEvent={onEventSelected}
            style={{ height: `${height ?? 500}px` }}
        />
    );

}
export default LocalizedCalendar;