import { lazy } from 'react';
import { JankisRouteDefinition } from '../types/frontendTypes';
import { AccountType } from '../types/enums';
import RegisteredUserRoutes from './RegisteredUserRoutes';
import { Models } from '../types/models';
import JankisHealthRecordRoutes from './JankisHealthRecordRoutes';
import HealthRecordEnvironment from '../../sharedHealthComponents/pages/Patients/HealthRecordEnvironment';
import { PatientPortalUrls } from './Urls';
import { HealthRecordUrls } from '../../sharedHealthComponents/navigation/Urls';

const PatientHomePage = lazy(() => import('../pages/PatientPortal/PatientHomePage'));
const OrderMealPage = lazy(() => import('../pages/Services/MealService/OrderMealPage'));
const MyAppointmentsPage = lazy(() => import('../pages/PatientPortal/MyAppointmentsPage'));
const CreateEditAppointmentPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/CreateEditAppointmentPage'));
const PatientCalendarPage = lazy(() => import('../pages/PatientPortal/PatientCalendarPage'));

export const PatientPortalRoutes = (
    features: Models.Configuration.FeatureSettings,
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void,
    onLogOut: () => Promise<void>) : JankisRouteDefinition[] => {
    if(!features.enablePatientPortal) {
        return [];
    }

    const routes: JankisRouteDefinition[] = [
        { path: '/', element: <PatientHomePage />, audience: [ AccountType.Patient ]},
        { path: PatientPortalUrls.CALENDAR, element: <PatientCalendarPage />, audience: [ AccountType.Patient ]},

        { path: PatientPortalUrls.ORDER_MEAL, element: <OrderMealPage />, audience: [ AccountType.Patient ]},

        ...RegisteredUserRoutes(features, onNewAuthenticationResult, onLogOut),
        { path: `${HealthRecordUrls.HEALTHRECORD_BASEURL}/*`, element: <HealthRecordEnvironment routes={JankisHealthRecordRoutes(features)} />, usesCustomLayout: true }
    ];
    if(features.enableScheduling) {
        const scheduleRoutes: JankisRouteDefinition[] = [
            { path: PatientPortalUrls.CREATE_APPOINTMENT, element: <CreateEditAppointmentPage />, audience: [ AccountType.Patient ]},
            // { path: '/edit/appointment/:id', element: <CreateEditAppointmentPage />, audience: [ AccountType.Patient ]},
            { path: PatientPortalUrls.MY_APPOINTMNETS, element: <MyAppointmentsPage />, audience: [ AccountType.Patient ]},
        ];
        routes.push(...scheduleRoutes);
    }
    return routes;
}
export default PatientPortalRoutes;