import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';
import { resolveText } from '../../sharedCommonComponents/helpers/Globalizer';

interface HidableHealthRecordEntryValueProps {
    hideValue: boolean;
    hasBeenSeenBySharer: boolean;
    onMarkAsSeen: () => void;
}

export const HidableHealthRecordEntryValue = (props: PropsWithChildren<HidableHealthRecordEntryValueProps>) => {

    if(props.hideValue)
    {
        return (<i className='fa fa-eye-slash clickable' onClick={props.onMarkAsSeen} />);
    }
    if(!props.hasBeenSeenBySharer) {
        return (<Row>
            <Col xs="auto">
                <i 
                    className='fa fa-eye-slash red clickable' 
                    title={resolveText("HasNotBeenSeenBySharer")}
                    onClick={props.onMarkAsSeen} 
                />
            </Col>
            <Col>
                {props.children}
            </Col>
        </Row>)
    }
    return (<>{props.children}</>);

}