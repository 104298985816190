import { useNavigate } from "react-router-dom";
import { MenuItemGroup } from "./MenuBuilder";
import { Button } from "react-bootstrap";

interface MenuItemGroupViewerProps {
    menuGroup: MenuItemGroup;
}

export const MenuItemGroupViewer = (props: MenuItemGroupViewerProps) => {

    const { menuGroup } = props;

    const navigate = useNavigate();

    return (<div key={menuGroup.title}>
        <div className="ps-2"><strong>{menuGroup.title}</strong></div>
        {menuGroup.items.map(item => (
            <Button 
                key={item.displayName}
                variant="link"
                size="sm"
                onClick={() => navigate(item.url)}
            >
                {item.displayName}
            </Button>
        ))}
    </div>);

}