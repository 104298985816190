import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { Models } from "../../../localComponents/types/models";
import { showErrorAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";
import { deleteObject } from "../../../sharedCommonComponents/helpers/DeleteHelpers";
import { loadObject } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { getQueryParameter } from "../../../sharedCommonComponents/helpers/RequestHelpers";
import { sendPostRequest } from "../../../sharedCommonComponents/helpers/StoringHelpers";
import { LoadItemArgs, PersonDataArgs, RemoteState } from "../../types/reduxInterfaces";
import { ApiDeleteActionCreator, ApiGetActionCreator, ApiPostActionCreator, FilterComparer, QueryParameterBuilder, SliceStateSelector } from "../../types/reduxTypes";
import { QueryParameters } from "../../../sharedCommonComponents/types/frontendTypes";


const buildAndExecuteGetManyRequest = async <
    ArgsType, 
    ItemType extends Models.IId<string>,
    FilterType
>(
    dispatch: AppDispatch, 
    getState: () => RootState,
    apiPathBuilder: (args: ArgsType) => string,
    queryParametersBuilder: QueryParameterBuilder<ItemType,FilterType>,
    filterComparer: FilterComparer<FilterType>,
    errorTextBuilder: () => string,
    setIsLoading: ActionCreatorWithPayload<boolean>,
    setItems: ActionCreatorWithPayload<ItemType[]>,
    appendItems: ActionCreatorWithPayload<ItemType[]>,
    setHasMoreItems: ActionCreatorWithPayload<boolean>,
    setLastUsedFilter: ActionCreatorWithPayload<FilterType | undefined>,
    sliceStateSelector: (state: RootState) => RemoteState<ItemType,FilterType>,
    args: ArgsType,
    onSuccess?: (items: ItemType[]) => void,
    onFailure?: () => void,
    onFinally?: () => void
) => {

    // ### Query parameters ####
    const state = getState();
    const sliceState = sliceStateSelector(state);
    const filter = sliceState.filter;
    const lastUsedFilter = sliceState.lastUsedFilter;
    const filterHasChanged = !filterComparer(filter, lastUsedFilter);
    const queryParams = queryParametersBuilder(state, sliceState, filterComparer);

    const apiPath = apiPathBuilder(args);
    dispatch(setIsLoading(true));
    await loadObject<ItemType[]>(
        apiPath, 
        queryParams,
        errorTextBuilder(),
        items => {
            if(filterHasChanged) {
                dispatch(setItems(items));
            } else if(items.length > 0) {
                dispatch(appendItems(items));
            }
            const queryCount = getQueryParameter(queryParams, 'count');
            if(queryCount) {
                const expectedCount = Number(queryCount);
                dispatch(setHasMoreItems(items.length === expectedCount));
            } else {
                dispatch(setHasMoreItems(false));
            }
            dispatch(setLastUsedFilter(filter));
            if(onSuccess) {
                onSuccess(items);
            }
        },
        async _ => {
            if(onFailure) {
                onFailure();
            }
        },
        () => {
            dispatch(setIsLoading(false));
            if(onFinally) {
                onFinally();
            }
        }
    );
}
export const loadItemsActionBuilder = <
    ArgsType, 
    ItemType extends Models.IId<string>,
    FilterType
>(
    apiPathBuilder: (args: ArgsType) => string,
    queryParameterBuilder: QueryParameterBuilder<ItemType,FilterType>,
    filterComparer: FilterComparer<FilterType>,
    errorTextBuilder: () => string,
    setIsLoading: ActionCreatorWithPayload<boolean>,
    setItems: ActionCreatorWithPayload<ItemType[]>,
    appendItems: ActionCreatorWithPayload<ItemType[]>,
    setHasMoreItems: ActionCreatorWithPayload<boolean>,
    setLastUsedFilter: ActionCreatorWithPayload<FilterType | undefined>,
    sliceStateSelector: SliceStateSelector<ItemType,FilterType>): ApiGetActionCreator<ArgsType,ItemType[]> => {
    return ({ args, onSuccess, onFailure, onFinally }) => {
        return async (dispatch, getState) => {
            await buildAndExecuteGetManyRequest(
                dispatch,
                getState,
                apiPathBuilder,
                queryParameterBuilder,
                filterComparer,
                errorTextBuilder,
                setIsLoading,
                setItems,
                appendItems,
                setHasMoreItems,
                setLastUsedFilter,
                sliceStateSelector,
                args!,
                onSuccess,
                onFailure,
                onFinally
            )
        }
    }
};
export const loadPersonDataActionBuilder = <
    ArgsType extends PersonDataArgs, 
    ItemType extends (Models.IId<string> & Models.IPersonData),
    FilterType
>(
    apiPathBuilder: (args: ArgsType) => string,
    queryParametersBuilder: QueryParameterBuilder<ItemType,FilterType>,
    filterComparer: FilterComparer<FilterType>,
    errorTextBuilder: () => string,
    setCurrentPersonId: ActionCreatorWithPayload<string>,
    setIsLoading: ActionCreatorWithPayload<boolean>,
    setItems: ActionCreatorWithPayload<ItemType[]>,
    appendItems: ActionCreatorWithPayload<ItemType[]>,
    setHasMoreItems: ActionCreatorWithPayload<boolean>,
    setLastUsedFilter: ActionCreatorWithPayload<FilterType | undefined>,
    sliceStateSelector: SliceStateSelector<ItemType,FilterType>): ApiGetActionCreator<ArgsType,ItemType[]> => {
    return ({ args, onSuccess, onFailure, onFinally }) => {
        return async (dispatch, getState) => {
            dispatch(setCurrentPersonId(args!.personId));
            await buildAndExecuteGetManyRequest(
                dispatch,
                getState,
                apiPathBuilder,
                queryParametersBuilder,
                filterComparer,
                errorTextBuilder,
                setIsLoading,
                setItems,
                appendItems,
                setHasMoreItems,
                setLastUsedFilter,
                sliceStateSelector,
                args!,
                onSuccess,
                onFailure,
                onFinally
            )
        }
    }
};
const buildAndExecuteGetOneRequest = async <
    ArgsType extends LoadItemArgs,
    ItemType
>(
    dispatch: AppDispatch,
    getState: () => RootState,
    apiPathBuilder: (args: ArgsType) => string,
    queryParametersBuilder: (state: RootState) => QueryParameters,
    errorTextBuilder: () => string,
    setIsLoading: ActionCreatorWithPayload<boolean>,
    setItem: ActionCreatorWithPayload<ItemType>,
    args: ArgsType,
    onSuccess?: (items: ItemType) => void,
    onFailure?: () => void,
    onFinally?: () => void
) => {
    dispatch(setIsLoading(true));
    const state = getState();
    const queryParams = queryParametersBuilder(state);
    await loadObject<ItemType>(
        apiPathBuilder(args), 
        queryParams,
        errorTextBuilder(),
        item => {
            dispatch(setItem(item));
            if(onSuccess) {
                onSuccess(item);
            }
        },
        async _ => {
            if(onFailure) {
                onFailure();
            }
        },
        () => {
            dispatch(setIsLoading(false));
            if(onFinally) {
                onFinally();
            }
        }
    );
}
export const loadItemActionBuilder = <
    ArgsType extends LoadItemArgs,
    ItemType
>(
    apiPathBuilder: (args: ArgsType) => string,
    queryParameterBuilder: (state: RootState) => QueryParameters,
    errorTextBuilder: () => string,
    setIsLoading: ActionCreatorWithPayload<boolean>,
    setItem: ActionCreatorWithPayload<ItemType>): ApiGetActionCreator<ArgsType,ItemType> => {
    return ({ args, onSuccess, onFailure, onFinally }) => {
        return async (dispatch, getState) => {
            await buildAndExecuteGetOneRequest(
                dispatch,
                getState,
                apiPathBuilder,
                queryParameterBuilder,
                errorTextBuilder,
                setIsLoading,
                setItem,
                args!,
                onSuccess,
                onFailure,
                onFinally
            );
        }
    }
}
export const loadItemIfNotYetLoadedActionBuilder = <
    ArgsType extends LoadItemArgs,
    ItemType extends Models.IId<string>,
    FilterType
>(
    apiPathBuilder: (args: ArgsType) => string,
    queryParameterBuilder: (state: RootState) => { [key:string]: string},
    errorTextBuilder: () => string,
    setIsLoading: ActionCreatorWithPayload<boolean>,
    setItem: ActionCreatorWithPayload<ItemType>,
    sliceStateSelector: SliceStateSelector<ItemType,FilterType>): ApiGetActionCreator<ArgsType,ItemType> => {
    return ({ args, onSuccess, onFailure, onFinally }) => {
        return async (dispatch, getState) => {
            const state = getState();
            const sliceState = sliceStateSelector(state);
            const existingItem = sliceState.items.find(item => item.id === args!.itemId);
            if(existingItem) {
                if(onSuccess) {
                    onSuccess(existingItem);
                }
                return;
            }

            await buildAndExecuteGetOneRequest(
                dispatch,
                getState,
                apiPathBuilder,
                queryParameterBuilder,
                errorTextBuilder,
                setIsLoading,
                setItem,
                args!,
                onSuccess,
                onFailure,
                onFinally
            );
        }
    }
}
export const getActionBuilder = <ArgsType extends unknown, ItemType extends unknown>(
    apiPathBuilder: (args?: ArgsType) => string,
    errorTextBuilder: () => string,
    setIsLoading: ActionCreatorWithPayload<boolean>,
    storeCallback: (dispatch: AppDispatch, response: ItemType, args?: ArgsType) => void): ApiGetActionCreator<ArgsType,ItemType> => {
    return ({ args, queryParams, onSuccess, onFailure, onFinally }) => {
        return async (dispatch) => {
            dispatch(setIsLoading(true));
            await loadObject<ItemType>(
                apiPathBuilder(args), 
                queryParams,
                errorTextBuilder(),
                item => {
                    storeCallback(dispatch, item, args);
                    if(onSuccess) {
                        onSuccess(item);
                    }
                },
                async _ => {
                    if(onFailure) {
                        onFailure();
                    }
                },
                () => {
                    dispatch(setIsLoading(false));
                    if(onFinally) {
                        onFinally();
                    }
                }
            );
        }
    }
}
export const postActionBuilder = <ArgsType extends unknown, BodyType extends unknown>(
    apiPathBuilder: (args: ArgsType) => string,
    errorTextBuilder: () => string,
    setIsSubmitting: ActionCreatorWithPayload<boolean>,
    storeCallback: (dispatch: AppDispatch, response: any, args: ArgsType) => void): ApiPostActionCreator<ArgsType,BodyType> => {
    return ({ args, body, onSuccess, onFailure, onFinally }) => {
        return async (dispatch) => {
            dispatch(setIsSubmitting(true));
            await sendPostRequest(
                apiPathBuilder(args), {},
                errorTextBuilder(),
                body,
                async response => {
                    const hasContent = response.headers.get('content-type')?.includes('application/json') ?? false;
                    const item = hasContent ? await response.json() : undefined;
                    storeCallback(dispatch, item, args);
                    if(onSuccess) {
                        onSuccess();
                    }
                },
                async response => {
                    const serverErrorText = response ? await response.text() : undefined;
                    showErrorAlert(errorTextBuilder(), serverErrorText);
                    if(onFailure) {
                        onFailure();
                    }
                },
                () => {
                    dispatch(setIsSubmitting(false));
                    if(onFinally) {
                        onFinally();
                    }
                }
            );
        }
    }
}
export const deleteActionBuilder = <ArgsType extends unknown>(
    apiPathBuilder: (args: ArgsType) => string,
    successTextBuilder: () => string,
    errorTextBuilder: () => string,
    storeCallback: (dispatch: AppDispatch, args: ArgsType) => void): ApiDeleteActionCreator<ArgsType> => {
    return ({ args, onSuccess, onFailure, onFinally }) => {
        return async (dispatch) => {
            await deleteObject(
                apiPathBuilder(args), {},
                successTextBuilder(),
                errorTextBuilder(),
                () => {
                    storeCallback(dispatch, args);
                    if(onSuccess) {
                        onSuccess();
                    }
                },
                onFailure,
                onFinally
            );
        }
    }
}