import { ViewModels } from "../../../localComponents/types/viewModels";
import { GenericIdAutocomplete } from "../../../sharedCommonComponents/components/Autocompletes/GenericIdAutocomplete";
import { IdAutocompleteProps } from "../../../sharedCommonComponents/types/frontendTypes";
import { ServiceRequestAutocomplete } from "./ServiceRequestAutocomplete";

interface ServiceRequestIdAutocompleteProps extends IdAutocompleteProps {
    serviceId?: string;
}

export const ServiceRequestIdAutocomplete = (props: ServiceRequestIdAutocompleteProps) => {
    const { serviceId } = props;

    return (<GenericIdAutocomplete<ViewModels.ServiceRequestViewModel>
        loadItemApiPathBuilder={id => `api/serviceRequests/${id}`}
        autocompleteBuilder={(key, props) => (
            <ServiceRequestAutocomplete
                key={key}
                serviceId={serviceId}
                {...props}
            />
        )}
        {...props}
    />);

}