import { MedicalTextPlaceholderType, MedicalTextTemplatePartType } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { IInteractiveTextareaNodeBuilder, SelectedAbbreviations } from "../../types/medicalTextEditorTypes";
import { escapeTemplate } from "./MedicalTextTemplateEscaper";

export const MEDICAL_TEXT_TEMPLATE_CLASS = 'medical-text-template';
export const MEDICAL_TEXT_ABBREVIATION_CLASS = 'medical-text-abbreviation';
export const MEDICAL_TEXT_SNOMEDCT_CLASS = 'medical-text-snomedct';
export const MEDICAL_TEXT_DISEASE_CLASS = 'medical-text-snomedct';
export const MEDICAL_TEXT_PLACEHOLDER_CLASS = 'medical-text-placeholder';

export class MedicalTextNodeBuilder implements IInteractiveTextareaNodeBuilder {
    abbreviations: SelectedAbbreviations = {};
    templates: { [word: string]: Models.MedicalTextEditor.MedicalTextTemplate } = {};


    canBuild = (word: string) => {
        const matchingTemplate = this.templates[word] ?? this.templates[word.toLowerCase()];
        if(matchingTemplate) {
            return true;
        }
        const matchingAbbreviation = this.abbreviations[word] ?? this.abbreviations[word.toLowerCase()];
        if(matchingAbbreviation) {
            return true;
        }
        return false;
    }

    build = async (word: string) => {
        const matchingTemplate = this.templates[word] ?? this.templates[word.toLowerCase()];
        if(matchingTemplate) {
            const escapedTemplate = await escapeTemplate(matchingTemplate);
            return this.buildTemplateNode(escapedTemplate);
        }
        const matchingAbbreviation = this.abbreviations[word] ?? this.abbreviations[word.toLowerCase()];
        if(matchingAbbreviation) {
            return buildAbbreviationNode(matchingAbbreviation);
        }
        return buildUnknownWordNode(word);
    }

    buildTemplateNode = (template: Models.MedicalTextEditor.MedicalTextTemplate) => {
        const templateNode = document.createElement("span");
        templateNode.classList.add(MEDICAL_TEXT_TEMPLATE_CLASS);
        templateNode.title = `From template "${template.title}":\n${template.description}`;
        for(const part of template.parts) {
            switch(part.type) {
                case MedicalTextTemplatePartType.Text:
                    const textPart = part as Models.MedicalTextEditor.TextMedicalTextTemplatePart;
                    const paragraphs = textPart.text.split('\n');
                    let isFirstLine = true;
                    for(const paragraph of paragraphs) {
                        if(!isFirstLine) {
                            templateNode.appendChild(document.createElement('br'));
                        }
                        const textNode = document.createTextNode(paragraph);
                        templateNode.appendChild(textNode);
                        isFirstLine = false;
                    }
                    break;
                case MedicalTextTemplatePartType.Abbreviation:
                    const abbreviation = part as Models.MedicalTextEditor.AbbreviationMedicalTextTemplatePart;
                    templateNode.appendChild(buildAbbreviationNode(abbreviation));
                    break;
                case MedicalTextTemplatePartType.Placeholder:
                    const placeholder = part as Models.MedicalTextEditor.IPlaceholderMedicalTextTemplatePart;
                    switch(placeholder.placeholderType) {
                        case MedicalTextPlaceholderType.FreeText:
                            {
                                const freetextPlaceholder = placeholder as Models.MedicalTextEditor.FreetextPlaceholderMedicalTextTemplatePart;
                                const textNode = buildPlaceholderNode(`{{${freetextPlaceholder.helpText}}}`);
                                templateNode.appendChild(textNode);
                            }
                            break;
                        case MedicalTextPlaceholderType.Patient:
                            {
                                const textNode = buildPlaceholderNode(`{{PATIENT}}`);
                                templateNode.appendChild(textNode);
                            }
                            break;
                        case MedicalTextPlaceholderType.Disease:
                            {
                                const textNode = buildPlaceholderNode(`{{DISEASE}}`);
                                templateNode.appendChild(textNode);
                            }
                            break;
                        case MedicalTextPlaceholderType.SnomedCtConcept:
                            {
                                const textNode = buildPlaceholderNode(`{{SNOMEDCT}}`);
                                templateNode.appendChild(textNode);
                            }
                            break;
                    }
                    break;
            }
        }
        return templateNode;
    }
    
}
export const buildTextNode = (str: string) => {
    return document.createTextNode(str);
}
export const buildAbbreviationNode = (
    abbreviation: Models.MedicalTextEditor.AbbreviationMedicalTextPart 
                | Models.MedicalTextEditor.AbbreviationMedicalTextTemplatePart 
                | Models.MedicalTextEditor.PersonalizedAbbreviation) => {
    const abbreviationNode = document.createElement("span");
    abbreviationNode.className = `badge bg-info ${MEDICAL_TEXT_ABBREVIATION_CLASS}`;
    abbreviationNode.title = abbreviation.fullText;
    abbreviationNode.innerText = abbreviation.abbreviation;
    abbreviationNode.contentEditable = "false";
    return abbreviationNode;
}
export const buildSnomedCtNode = (snomedCtPart: Models.MedicalTextEditor.SnomedCtConceptMedicalTextPart) => {
    const snomedCtNode = document.createElement("span");
    snomedCtNode.className = `badge bg-primary ${MEDICAL_TEXT_SNOMEDCT_CLASS}`;
    snomedCtNode.title = snomedCtPart.conceptId;
    snomedCtNode.innerText = snomedCtPart.term;
    snomedCtNode.contentEditable = "false";
    return snomedCtNode;
}
export const buildDiseaseNode = (disease: Models.MedicalTextEditor.DiseaseMedicalTextPart) => {
    const diseaseNode = document.createElement("span");
    diseaseNode.className = `badge bg-primary ${MEDICAL_TEXT_DISEASE_CLASS}`;
    diseaseNode.title = disease.icd11Code;
    diseaseNode.innerText = disease.name;
    diseaseNode.contentEditable = "false";
    return diseaseNode;
}
export const buildPlaceholderNode = (text: string) => {
    const node = document.createElement("span");
    node.className = `badge bg-secondary ${MEDICAL_TEXT_PLACEHOLDER_CLASS}`;
    node.innerText = text;
    node.contentEditable = "false";
    node.onclick = () => selectNode(node);
    return node;
}
export const buildUnknownWordNode = (word: string) => {
    const node = document.createElement("span");
    node.className = "badge bg-secondary";
    node.innerText = word;
    node.contentEditable = "false";
    return node;
}
export const selectNode = (node: Node) => {
    const selection = document.getSelection();
    if(!selection) {
        return;
    }
    const range = document.createRange();
    if(!range) {
        return;
    }
    selection.removeAllRanges();
    range.selectNode(node);
    selection.addRange(range);
}