import { Card, Col, Modal, Row } from 'react-bootstrap';
import { resolveText } from '../../sharedCommonComponents/helpers/Globalizer';
import { formatContactPersonRole } from '../helpers/Formatters';
import { Models } from '../../localComponents/types/models';

interface ContactPersonsModalProps {
    show: boolean;
    onClose: () => void;
    contactPersons: Models.Individuals.ContactPerson[];
}

export const ContactPersonsModal = (props: ContactPersonsModalProps) => {

    return (
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{resolveText("Person_ContactPersons")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.contactPersons.map(contactPerson => {
                    const formattedRole = formatContactPersonRole(contactPerson.role);
                    return (
                        <Card 
                            key={contactPerson.id}
                            className='mb-3'
                        >
                            <Card.Header>
                                {contactPerson.name} ({formattedRole})
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={4}>{resolveText("ContactPerson_Role")}</Col>
                                    <Col>{formattedRole}</Col>
                                </Row>
                                {contactPerson.phoneNumber
                                ? <Row>
                                    <Col xs={4}>{resolveText("Contact_PhoneNumber")}</Col>
                                    <Col>{contactPerson.phoneNumber}</Col>
                                </Row> : null}
                                {contactPerson.email
                                ? <Row>
                                    <Col xs={4}>{resolveText("Contact_Email")}</Col>
                                    <Col>{contactPerson.email}</Col>
                                </Row> : null}
                                {contactPerson.note
                                ? <Row>
                                    <Col xs={4}>{resolveText("Contact_Note")}</Col>
                                    <Col>{contactPerson.note}</Col>
                                </Row> : null}
                                {contactPerson.languages && contactPerson.languages.length > 0
                                ? <Row>
                                    <Col xs={4}>{resolveText("Contact_PreferredLanguages")}</Col>
                                    <Col>{contactPerson.languages.join(", ")}</Col>
                                </Row> : null}
                            </Card.Body>
                        </Card>
                    );
                })}
            </Modal.Body>
        </Modal>
    );

}