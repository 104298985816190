import { lazy } from 'react';
import { AccountType } from '../types/enums';
import { JankisRouteDefinition } from '../types/frontendTypes';
import { Models } from '../types/models';
import RegisteredUserRoutes from './RegisteredUserRoutes';
import JankisHealthRecordRoutes from './JankisHealthRecordRoutes';
import ChecklistRoutes from '../../checklist/navigation/ChecklistRoutes';
import { JanKisUrls } from './Urls';
import { HealthRecordUrls, SharedUrls } from '../../sharedHealthComponents/navigation/Urls';
import LetterTemplatesPage from '../pages/Documentation/LetterTemplatesPage';
import PrintStockLabelsPage from '../pages/Stocks/PrintStockLabelsPage';
import RestockingPage from '../pages/Stocks/RestockingPage';
import PatientDashboardPage from '../pages/Patients/PatientDashboardPage';

const DischargeLetterTemplateBuilderPage = lazy(() => import('../pages/Documentation/DischargeLetterTemplateBuilderPage'));
const FunctionalGroupListPage = lazy(() => import('../pages/Collaboration/FunctionalGroupListPage'));
const CreateEditFunctionalGroupPage = lazy(() => import('../pages/Collaboration/CreateEditFunctionalGroupPage'));
const FunctionalGroupMembersPage = lazy(() => import('../pages/Collaboration/FunctionalGroupMembersPage'));
const QuickAdmitPatientPage = lazy(() => import('../pages/Registration/QuickAdmitPatientPage'));
const DepartmentRoomLayoutEditPage = lazy(() => import('../pages/Departments/DepartmentRoomLayoutEditPage'));
const PrintDepartmentPatientsListPage = lazy(() => import('../pages/Departments/PrintDepartmentPatientsListPage'));
const CheckinPage = lazy(() => import('../pages/Registration/CheckinPage'));
const HealthRecordEnvironment = lazy(() => import('../../sharedHealthComponents/pages/Patients/HealthRecordEnvironment'));
const KnowledgeBaseStartPage = lazy(() => import('../pages/Collaboration/KnowledgeBaseStartPage'));
const KnowledgeBaseArticleViewerPage = lazy(() => import('../pages/Collaboration/KnowledgeBaseArticleViewerPage'));
const CreateEditKnowledgeBaseArticlePage = lazy(() => import('../pages/Collaboration/CreateEditKnowledgeBaseArticlePage'));
const DepartmentPatientJourneysPage = lazy(() => import('../pages/Departments/DepartmentPatientJourneysPage'));
const FeedbackListPage = lazy(() => import('../pages/FeedbackListPage'));
const PrintTaskListPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/PrintTaskListPage'));
const DepartmentSelectionPage = lazy(() => import('../pages/EntryPages/DepartmentSelectionPage'));
const CreateEditServicePackagePage = lazy(() => import('../pages/Services/CreateEditServicePackagePage'));
const CreateEditMealMenuPage = lazy(() => import('../pages/Services/MealService/CreateEditMealMenuPage'));
const CreateEditAppointmentPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/CreateEditAppointmentPage'));
const AppointmentsListPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/AppointmentsListPage'));
const PrintAppointmentPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/PrintAppointmentPage'));
const EditInstitutionSchedulePage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/EditInstitutionSchedulePage'));
const CreateEditQuestionnairePage = lazy(() => import('../../sharedHealthComponents/pages/Questionnaires/CreateEditQuestionnairePage'));
const CreateEditPractitionerPage = lazy(() => import('../../sharedHealthComponents/pages/Organization/CreateEditPractitionerPage'));
const PersonsListPage = lazy(() => import('../../sharedHealthComponents/pages/Patients/PersonsListPage'));
const InstitutionEditPage = lazy(() => import('../pages/Config/InstitutionEditPage'));
const InstitutionsListPage = lazy(() => import('../pages/Config/InstitutionsListPage'));
const ConsumableEditPage = lazy(() => import('../pages/Materials/ConsumableEditPage'));
const ConsumablePage = lazy(() => import('../pages/Materials/ConsumablePage'));
const ConsumablesListPage = lazy(() => import('../pages/Materials/ConsumablesListPage'));
const ContactEditPage = lazy(() => import('../pages/ContactList/ContactEditPage'));
const ContactsListPage = lazy(() => import('../pages/ContactList/ContactsListPage'));
const BedOccupancyEditPage = lazy(() => import('../pages/Departments/BedOccupancyEditPage'));
const DepartmentEditPage = lazy(() => import('../pages/Departments/DepartmentEditPage'));
const DepartmentPage = lazy(() => import('../pages/Departments/DepartmentPage'));
const DepartmentsListPage = lazy(() => import('../pages/Departments/DepartmentsListPage'));
const RoomsPage = lazy(() => import('../pages/Departments/RoomsPage'));
const RoomPage = lazy(() => import('../pages/Departments/RoomPage'));
const CreateEditDrugPage = lazy(() => import('../pages/Drugs/CreateEditDrugPage'));
const DrugsListPage = lazy(() => import('../../sharedHealthComponents/pages/Medication/DrugsListPage'));
const MyPatientsPage = lazy(() => import('../pages/Patients/MyPatientsPage'));
const InstitutionEncountersListPage = lazy(() => import('../pages/Registration/InstitutionEncountersListPage'));
const CreateEditEncounterPage = lazy(() => import('../pages/Registration/CreateEditEncounterPage'));
const ResourceEditPage = lazy(() => import('../pages/Materials/ResourceEditPage'));
const ResourcePage = lazy(() => import('../pages/Materials/ResourcePage'));
const ResourcesListPage = lazy(() => import('../pages/Materials/ResourcesListPage'));
const RequestServicePage = lazy(() => import('../pages/Services/RequestServicePage'));
const ServiceEditPage = lazy(() => import('../pages/Services/CreateEditServicePage'));
const ServicePage = lazy(() => import('../pages/Services/ServicePage'));
const ServicePackagesListPage = lazy(() => import('../pages/Services/ServicePackagesListPage'));
const ServiceRequestPage = lazy(() => import('../pages/Services/ServiceRequestPage'));
const ServiceRequestsListPage = lazy(() => import('../pages/Services/ServiceRequestsListPage'));
const ServicesListPage = lazy(() => import('../pages/Services/ServicesListPage'));
const StockEditPage = lazy(() => import('../pages/Stocks/StockEditPage'));
const StockPage = lazy(() => import('../pages/Stocks/StockPage'));
const StocksListPage = lazy(() => import('../pages/Stocks/StocksListPage'));
const AccountEditPage = lazy(() => import('../pages/UserManagement/AccountEditPage'));
const AccountsListPage = lazy(() => import('../pages/UserManagement/AccountsListPage'));
const RoleEditPage = lazy(() => import('../pages/UserManagement/RoleEditPage'));
const RolePage = lazy(() => import('../pages/UserManagement/RolePage'));
const RolesListPage = lazy(() => import('../pages/UserManagement/RolesListPage'));
const QuestionnairesListPage = lazy(() => import('../../sharedHealthComponents/pages/Questionnaires/QuestionnairesListPage'));
const NewsEditPage = lazy(() => import('../pages/News/NewsEditPage'));
const MaterialOrdersListPage = lazy(() => import('../pages/Materials/MaterialOrdersListPage'));
const MaterialOrderPage = lazy(() => import('../pages/Materials/MaterialOrderPage'));
const CreateEditTodoListItemPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/CreateEditTodoListItemPage'));
const TodoListsPage = lazy(() => import('../pages/Collaboration/TodoListsPage'));
const DeployTasksPackagePage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/DeployTasksPackagePage'));
const TasksPackagesPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/TasksPackagesPage'));
const CreateEditTasksPackagePage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/CreateEditTasksPackagePage'));
const ClinicHomePage = lazy(() => import('../pages/EntryPages/ClinicHomePage'));
const GenericHomePage = lazy(() => import('../pages/EntryPages/GenericHomePage'));
const MealServiceHomePage = lazy(() => import('../pages/EntryPages/MealServiceHomePage'));
const RegistrationEntryPage = lazy(() => import('../pages/EntryPages/RegistrationEntryPage'));
const WardHomePage = lazy(() => import('../pages/EntryPages/WardHomePage'));
const ExportPatientDataPage = lazy(() => import('../../sharedHealthComponents/pages/DataImportExport/ExportPatientDataPage'));
const AdverseEventsListPage = lazy(() => import('../pages/Collaboration/AdverseEventsListPage'));
const ReportAdverseEventPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/ReportAdverseEventPage'));
const TaskPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/TaskPage'));
const TransferDataToFromShehrdPage = lazy(() => import('../pages/DataImportExport/TransferDataToFromShehrdPage'));
const DiagnosticTestsPackagesExplorerPage = lazy(() => import('../../sharedHealthComponents/pages/TestResults/DiagnosticTestsPackagesExplorerPage'));
const InvoicingOverviewPage = lazy(() => import('../pages/Financial/InvoicingOverviewPage'));
const CreateEditInvoicePage = lazy(() => import('../pages/Financial/CreateEditInvoicePage'));
const PrintInvoicePage = lazy(() => import('../pages/Financial/PrintInvoicePage'));
const PrintTemplateEditorPage = lazy(() => import('../../sharedHealthComponents/pages/PrintTemplateEditorPage'));
const PrescriptionPrintPage = lazy(() => import('../pages/Drugs/PrescriptionPrintPage'));
const ActionsPage = lazy(() => import('../pages/EntryPages/ActionsPage'));
const PrintTemplateListPage = lazy(() => import('../../sharedHealthComponents/pages/PrintTemplateListPage'));
const EquipmentMeasurementsListPage = lazy(() => import('../../sharedHealthComponents/pages/Equipments/EquipmentMeasurementsListPage'));
const CreateEditEquipmentMeasurement = lazy(() => import('../../sharedHealthComponents/pages/Equipments/CreateEditEquipmentMeasurement'));
const CreateEditPharmacyOrderPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/CreateEditPharmacyOrderPage'));
const PharmacyOrdersListPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyOrdersListPage'));
const PharmacyOrderPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyOrderPage'));

export const ProfessionalPortalRoutes = (
    features: Models.Configuration.FeatureSettings,
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void,
    onLogOut: () => Promise<void>): JankisRouteDefinition[] => {
    const routes: JankisRouteDefinition[] = [
        { path: '/', element: <DepartmentSelectionPage />, audience: [ AccountType.Employee ]},
        { path: '/home/clinic/:departmentId', element: <ClinicHomePage />, audience: [ AccountType.Employee ]},
        { path: '/home/generic/:departmentId', element: <GenericHomePage />, audience: [ AccountType.Employee ]},
        { path: '/home/mealservice/:departmentId', element: <MealServiceHomePage />, audience: [ AccountType.Employee ]},
        { path: '/home/registration/:departmentId', element: <RegistrationEntryPage />, audience: [ AccountType.Employee ]},
        { path: '/home/ward/:departmentId', element: <WardHomePage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.ENTRYPAGE_ACTIONS, element: <ActionsPage />, audience: [ AccountType.Employee ]},

        // Persons
        { path: JanKisUrls.MY_PATIENTS, element: <MyPatientsPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.PERSONS, element: <PersonsListPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.PATIENT_DASHBOARD, element: <PatientDashboardPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.CREATE_PRACTITIONER, element: <CreateEditPractitionerPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.EDIT_PRACTITIONER, element: <CreateEditPractitionerPage />, audience: [ AccountType.Employee ]},

        // Institution
        { path: SharedUrls.INSTITUTIONS, element: <InstitutionsListPage />, audience: [ AccountType.Employee ] },
        { path: SharedUrls.CREATE_INSTITUTION, element: <InstitutionEditPage />, audience: [ AccountType.Employee ]},
        { path: SharedUrls.EDIT_INSTITUTION, element: <InstitutionEditPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.CHECKIN_TERMINAL, element: <CheckinPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.INSTITUTION_CHECKIN_TERMINAL, element: <CheckinPage />, audience: [ AccountType.Employee ] },

        // Department
        { path: SharedUrls.DEPARTMENTS, element: <DepartmentsListPage />, audience: [ AccountType.Employee ] },
        { path: SharedUrls.CREATE_DEPARTMENT, element: <DepartmentEditPage />, audience: [ AccountType.Employee ] },
        { path: SharedUrls.INSTITUTION_CREATE_DEPARTMENT, element: <DepartmentEditPage />, audience: [ AccountType.Employee ]},
        { path: SharedUrls.EDIT_DEPARTMENT, element: <DepartmentEditPage />, audience: [ AccountType.Employee ] },
        { path: SharedUrls.OPEN_DEPARTMENT, element: <DepartmentPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.ENTRYPAGE_CLINIC, element: <ClinicHomePage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.DEPARTMENT_PATIENTJOURNEYS, element: <DepartmentPatientJourneysPage />, usesCustomLayout: true, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.OPEN_DEPARTMENT_PATIENTJOURNEY, element: <DepartmentPatientJourneysPage />, usesCustomLayout: true, audience: [ AccountType.Employee ]},

        // Rooms & beds
        { path: JanKisUrls.ROOMS, element: <RoomsPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.INSTITUTION_ROOMS, element: <RoomsPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.INSTITUTION_DEPARTMENT_ROOMS, element: <RoomsPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.OPEN_ROOM, element: <RoomPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.BED_CREATE_BEDOCUPANCY, element: <BedOccupancyEditPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.EDIT_BEDOCUPANCY, element: <BedOccupancyEditPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.QUICK_ADMIT, element: <QuickAdmitPatientPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.DEPARTMENT_EDIT_LAYOUT, element: <DepartmentRoomLayoutEditPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.PRINT_DEPARTMENT_BEDLIST, element: <PrintDepartmentPatientsListPage />, usesCustomLayout: true, audience: [ AccountType.Employee ]},

        // User management
        // { path: '/institution/:id/manage', element: <ManageInstitutionMembersPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.ACCOUNTS, element: <AccountsListPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.CREATE_ACCOUNT, element: <AccountEditPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.EDIT_ACCOUNT, element: <AccountEditPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.ROLES, element: <RolesListPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.CREATE_ROLE, element: <RoleEditPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.EDIT_ROLE, element: <RoleEditPage />, audience: [ AccountType.Employee ] },
        { path: JanKisUrls.OPEN_ROLE, element: <RolePage />, audience: [ AccountType.Employee ] },

        // Functional groups
        { path: JanKisUrls.FUNCTIONAL_GROUPS, element: <FunctionalGroupListPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.CREATE_FUNCTIONALGROUP, element: <CreateEditFunctionalGroupPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.EDIT_FUNCTIONALGROUP, element: <CreateEditFunctionalGroupPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.FUNCTIONALGROUP_MEMBERS, element: <FunctionalGroupMembersPage />, audience: [ AccountType.Employee ]},

        // Print templates
        { path: SharedUrls.PRINT_TEMPLATES, element: <PrintTemplateListPage />, audience: [ AccountType.Employee ] },
        { path: SharedUrls.CREATE_PRINTTEMPLATE, element: <PrintTemplateEditorPage />, audience: [ AccountType.Employee ] },
        { path: SharedUrls.EDIT_PRINTTEMPLATE, element: <PrintTemplateEditorPage />, audience: [ AccountType.Employee ] },

        // Letter templates
        { path: JanKisUrls.LETTERTEMPLATES, element: <LetterTemplatesPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.CREATE_LETTERTEMPLATE, element: <DischargeLetterTemplateBuilderPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.EDIT_LETTERTEMPLATE, element: <DischargeLetterTemplateBuilderPage />, audience: [ AccountType.Employee ]},

        // Knowledge base
        { path: JanKisUrls.KNOWLEDGEBASE, element: <KnowledgeBaseStartPage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.KNOWLEDGEBASE_CREATE_ARTICLE, element: <CreateEditKnowledgeBaseArticlePage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.KNOWLEDGEBASE_EDIT_ARTICLE, element: <CreateEditKnowledgeBaseArticlePage />, audience: [ AccountType.Employee ]},
        { path: JanKisUrls.KNOWLEDGEBASE_OPEN_ARTICLE, element: <KnowledgeBaseArticleViewerPage />, audience: [ AccountType.Employee ]},

        // Feedback
        { path: SharedUrls.FEEDBACK, element: <FeedbackListPage />, audience: [ AccountType.Employee ]},
        
        ...RegisteredUserRoutes(features, onNewAuthenticationResult, onLogOut),
        { path: `${HealthRecordUrls.HEALTHRECORD_BASEURL}/*`, element: <HealthRecordEnvironment routes={JankisHealthRecordRoutes(features)} />, usesCustomLayout: true }
    ];
    if(features.enableAdverseEvents) {
        const adverseEventRoutes: JankisRouteDefinition[] = [
            { path: SharedUrls.ADVERSE_EVENTS, element: <AdverseEventsListPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.CREATE_ADVERSE_EVENT, element: <ReportAdverseEventPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.EDIT_ADVERSE_EVENT, element: <ReportAdverseEventPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...adverseEventRoutes);
    }
    if(features.enableContactList) {
        const contactListRoutes: JankisRouteDefinition[] = [
            { path: JanKisUrls.CONTACTS, element: <ContactsListPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.CREATE_CONTACT, element: <ContactEditPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.EDIT_CONTACT, element: <ContactEditPage />, audience: [ AccountType.Employee ] },
        ];
        routes.push(...contactListRoutes);
    }
    if(features.enableDataImportExport) {
        const ioRoutes: JankisRouteDefinition[] = [
            { path: SharedUrls.EXPORT_PATIENTDATA, element: <ExportPatientDataPage />, audience: [ AccountType.Employee ]},
            { path: JanKisUrls.SHEHRD_SYNC, element: <TransferDataToFromShehrdPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...ioRoutes);
    }
    if(features.enableInvoicing) {
        const invoicingRoutes: JankisRouteDefinition[] = [
            { path: JanKisUrls.INVOICES, element: <InvoicingOverviewPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.CREATE_INVOICE_FOR_INSTITUTION, element: <CreateEditInvoicePage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.CREATE_INVOICE_FOR_ENCOUNTER, element: <CreateEditInvoicePage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.EDIT_INVOICE, element: <CreateEditInvoicePage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.PRINT_INVOICE, element: <PrintInvoicePage />, usesCustomLayout: true, audience: [ AccountType.Employee ] },
        ];
        routes.push(...invoicingRoutes);
    }
    if(features.enableNews) {
        const newsRoutes: JankisRouteDefinition[] = [
            { path: JanKisUrls.CREATE_NEWS, element: <NewsEditPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.EDIT_NEWS, element: <NewsEditPage />, audience: [ AccountType.Employee ] },
        ];
        routes.push(...newsRoutes);
    }
    if(features.enablePatientPortal) {
        const patientPortalRoutes: JankisRouteDefinition[] = [
            // Nothing to include for now
        ];
        routes.push(...patientPortalRoutes);
    }
    if(features.enableScheduling) {
        const schedulingEventRoutes: JankisRouteDefinition[] = [
            { path: SharedUrls.INSTITUTION_EDIT_SCHEDULE, element: <EditInstitutionSchedulePage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.INSTITUTION_DEPARTMENT_EDIT_SCHEDULE, element: <EditInstitutionSchedulePage />, audience: [ AccountType.Employee ]},

            { path: JanKisUrls.ENCOUNTERS, element: <InstitutionEncountersListPage />, audience: [ AccountType.Employee ]},
            { path: JanKisUrls.CREATE_ENCOUNTER, element: <CreateEditEncounterPage />, audience: [ AccountType.Employee ]},
            { path: JanKisUrls.DEPARTMENT_CREATE_ENCOUNTER, element: <CreateEditEncounterPage />, audience: [ AccountType.Employee ]},

            { path: SharedUrls.APPOINTMENTS, element: <AppointmentsListPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.CREATE_APPOINTMENT, element: <CreateEditAppointmentPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.INSTITUTION_DEPARTMENT_CREATE_APPOINTMENT, element: <CreateEditAppointmentPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.INSTITUTION_CREATE_APPOINTMENT, element: <CreateEditAppointmentPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.DEPARTMENT_CREATE_APPOINTMENT, element: <CreateEditAppointmentPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.EDIT_APPOINTMENT, element: <CreateEditAppointmentPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.PRINT_APPOINTMENT, element: <PrintAppointmentPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...schedulingEventRoutes);
    }
    if(features.enableServices) {
        const servicesRoutes: JankisRouteDefinition[] = [
            // Service
            { path: SharedUrls.SERVICES, element: <ServicesListPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.CREATE_SERVICE, element: <ServiceEditPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.EDIT_SERVICE, element: <ServiceEditPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.OPEN_SERVICE, element: <ServicePage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.DEPARTMENT_SERVICES, element: <ServicesListPage />, audience: [ AccountType.Employee ] },
            { path: SharedUrls.DEPARTMENT_CREATE_SERVICE, element: <ServiceEditPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.SERVICEPACKAGE_SERVICES, element: <ServicesListPage />, audience: [ AccountType.Employee ]},

            // Service package
            { path: SharedUrls.SERVICEPACKAGES, element: <ServicePackagesListPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.DEPARTMENT_SERVICEPACKAGES, element: <ServicePackagesListPage />, audience: [ AccountType.Employee ] },
            { path: SharedUrls.CREATE_SERVICEPACKAGE, element: <CreateEditServicePackagePage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.EDIT_SERVICEPACKAGE, element: <CreateEditServicePackagePage />, audience: [ AccountType.Employee ]},
            { path: JanKisUrls.EDIT_MEALMENU, element: <CreateEditMealMenuPage />, audience: [ AccountType.Employee ]},

            // Service request
            { path: SharedUrls.SERVICEREQUESTS, element: <ServiceRequestsListPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.SERVICE_SERVICEREQUESTS, element: <ServiceRequestsListPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.DEPARTMENT_SERVICEREQUESTS, element: <ServiceRequestsListPage />, audience: [ AccountType.Employee ] },
            { path: SharedUrls.OPEN_SERVICEREQUEST, element: <ServiceRequestPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.CREATE_SERVICEREQUEST, element: <RequestServicePage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.SERVICE_CREATE_SERVICEREQUESTS, element: <RequestServicePage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...servicesRoutes);
    }
    if(features.enableTaskList) {
        const taskListRoutes: JankisRouteDefinition[] = [
            { path: SharedUrls.CREATE_TASK, element: <CreateEditTodoListItemPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.EDIT_TASK, element: <CreateEditTodoListItemPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.TODOLIST, element: <TodoListsPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.OPEN_TASK, element: <TaskPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.TASK_PACKAGES, element: <TasksPackagesPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.CREATE_TASKPACKAGE, element: <CreateEditTasksPackagePage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.EDIT_TASKPACKAGE, element: <CreateEditTasksPackagePage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.DEPLOY_TASKPACKAGE, element: <DeployTasksPackagePage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.TASKPACKAGE_DEPLOY, element: <DeployTasksPackagePage />, audience: [ AccountType.Employee ]},

            { path: SharedUrls.PRINT_TODOLIST, element: <PrintTaskListPage />, usesCustomLayout: true, audience: [ AccountType.Employee ]},

            ...ChecklistRoutes().map(route => ({
                ...route,
                audience: [ AccountType.Employee ]
            }) as JankisRouteDefinition)
        ];
        routes.push(...taskListRoutes);
    }
    if(features.enableWarehousing) {
        const warehousingRoutes: JankisRouteDefinition[] = [
            { path: JanKisUrls.RESOURCES, element: <ResourcesListPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.DEPARTMENT_RESOURCES, element: <ResourcesListPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.CREATE_RESOURCE, element: <ResourceEditPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.EDIT_RESOURCE, element: <ResourceEditPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.OPEN_RESOURCE, element: <ResourcePage />, audience: [ AccountType.Employee ] },

            { path: JanKisUrls.CONSUMABLES, element: <ConsumablesListPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.CREATE_CONSUMABLE, element: <ConsumableEditPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.EDIT_CONSUMABLE, element: <ConsumableEditPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.OPEN_CONSUMABLE, element: <ConsumablePage />, audience: [ AccountType.Employee ] },

            { path: JanKisUrls.MATERIALORDERS, element: <MaterialOrdersListPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.OPEN_MATERIALORDER, element: <MaterialOrderPage />, audience: [ AccountType.Employee ] },

            { path: JanKisUrls.STOCKS, element: <StocksListPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.DEPARTMENT_STOCKS, element: <StocksListPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.CREATE_STOCK, element: <StockEditPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.EDIT_STOCK, element: <StockEditPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.OPEN_STOCK, element: <StockPage />, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.PRINT_STOCK_LABELS, element: <PrintStockLabelsPage />, usesCustomLayout: true, audience: [ AccountType.Employee ] },
            { path: JanKisUrls.DEPARTMENT_RESTOCKING, element: <RestockingPage />, audience: [ AccountType.Employee ] },
        ];
        routes.push(...warehousingRoutes);
    }
    if(features.healthRecord.enableMedication) {
        const medicationEventRoutes: JankisRouteDefinition[] = [
            { path: SharedUrls.DRUGS, element: <DrugsListPage />, audience: [ AccountType.Employee ] },
            { path: SharedUrls.CREATE_DRUG, element: <CreateEditDrugPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.EDIT_DRUG, element: <CreateEditDrugPage />, audience: [ AccountType.Employee ] },

            { path: HealthRecordUrls.PRINT_PRESCRIPTION, element: <PrescriptionPrintPage />, audience: [ AccountType.Employee, AccountType.Patient ]},
            { path: SharedUrls.CREATE_PHARMACY_ORDER, element: <CreateEditPharmacyOrderPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.EDIT_PHARMACY_ORDER, element: <CreateEditPharmacyOrderPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.PHARMACY_ORDERS, element: <PharmacyOrdersListPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.OPEN_PHARMACY_ORDER, element: <PharmacyOrderPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...medicationEventRoutes);
    }
    if(features.healthRecord.enableQuestionnaires) {
        const questionnaireEventRoutes: JankisRouteDefinition[] = [
            { path: SharedUrls.QUESTIONNAIRES, element: <QuestionnairesListPage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.CREATE_QUESTIONNAIRE, element: <CreateEditQuestionnairePage />, audience: [ AccountType.Employee ]},
            { path: SharedUrls.EDIT_QUESTIONNAIRE, element: <CreateEditQuestionnairePage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...questionnaireEventRoutes);
    }
    if(features.healthRecord.enableTestResults) {
        const testResultRoutes: JankisRouteDefinition[] = [
            { path: SharedUrls.LABTEST_PACKAGES, element: <DiagnosticTestsPackagesExplorerPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...testResultRoutes);
    }
    const equipmentMeasurementRoutes: JankisRouteDefinition[] = [
        { path: SharedUrls.EQUIPMENT_MEASUREMENTS , element: <EquipmentMeasurementsListPage />, audience: [ AccountType.Employee ]},
        { path: SharedUrls.CREATE_EQUIPMENT_MEASUREMENT, element: <CreateEditEquipmentMeasurement />, audience: [ AccountType.Employee ]},
        { path: SharedUrls.EDIT_EQUIPMENT_MEASUREMENT, element: <CreateEditEquipmentMeasurement />, audience: [ AccountType.Employee ]}
    ]
    routes.push(...equipmentMeasurementRoutes);
    return routes;
}