import { lazy } from 'react';
import HealthRecordRoutes from '../../sharedHealthComponents/navigation/HealthRecordRoutes';
import { AccountType } from '../types/enums';
import { JankisRouteDefinition } from '../types/frontendTypes';
import { Models } from '../types/models';
import { JanKisHealthRecordUrls } from './Urls';
import { HealthRecordUrls } from '../../sharedHealthComponents/navigation/Urls';
import DischargeLetterBuilderPage from '../pages/Documentation/DischargeLetterBuilderPage';
import DischargeLetterTemplateBuilderPage from '../pages/Documentation/DischargeLetterTemplateBuilderPage';

const HealthRecordPage = lazy(() => import('../../localComponents/pages/Patients/HealthRecordPage'));
const PatientNursingPage = lazy(() => import('../../localComponents/pages/Patients/PatientNursingPage'));
const OrderServiceForPatientPage = lazy(() => import('../../localComponents/pages/Patients/OrderServiceForPatientPage'));
const AddTaskOrChecklistPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/AddTaskOrChecklistPage'));
const CreateEditPrescriptionPage = lazy(() => import('../pages/Drugs/CreateEditPrescriptionPage'));
const CreateEditIsolationPage = lazy(() => import('../../localComponents/pages/Patients/CreateEditIsolationPage'));

export const JankisHealthRecordRoutes = (features: Models.Configuration.FeatureSettings): JankisRouteDefinition[] => {


    const healthRecordFeatures = features.healthRecord;
    const healthRecordRoutes = HealthRecordRoutes(healthRecordFeatures);
    const routes: JankisRouteDefinition[] = [
        ...healthRecordRoutes.map(route => ({
            ...route,
            audience: [ AccountType.Patient, AccountType.Employee ]
        }) as JankisRouteDefinition),
        { path: '/', usesCustomLayout: true, element: <HealthRecordPage />, audience: [ AccountType.Patient, AccountType.Employee ]},
        { path: JanKisHealthRecordUrls.NURSING, element: <PatientNursingPage />, usesCustomLayout: true, audience: [ AccountType.Patient, AccountType.Employee ]},
        { path: JanKisHealthRecordUrls.CREATE_LETTER, element: <DischargeLetterBuilderPage />, usesCustomLayout: true, audience: [ AccountType.Employee ]},
        { path: JanKisHealthRecordUrls.EDIT_LETTER, element: <DischargeLetterBuilderPage />, usesCustomLayout: true, audience: [ AccountType.Employee ]},
    ];
    if(features.healthRecord.enableMedication) {
        const isolationRoutes: JankisRouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_PRESCRIPTION, element: <CreateEditPrescriptionPage />, audience: [ AccountType.Employee ]},
            { path: HealthRecordUrls.EDIT_PRESCRIPTION, element: <CreateEditPrescriptionPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...isolationRoutes);
    }
    if(healthRecordFeatures.enableIsolations) {
        const isolationRoutes: JankisRouteDefinition[] = [
            { path: JanKisHealthRecordUrls.CREATE_ISOLATION, element: <CreateEditIsolationPage />, audience: [ AccountType.Employee ]},
            { path: JanKisHealthRecordUrls.EDIT_ISOLATION, element: <CreateEditIsolationPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...isolationRoutes);
    }
    if(features.enableServices) {
        const serviceRoutes: JankisRouteDefinition[] = [
            { path: JanKisHealthRecordUrls.ORDER_SERVICE, element: <OrderServiceForPatientPage />, audience: [ AccountType.Patient, AccountType.Employee ]},
        ];
        routes.push(...serviceRoutes);
    }
    if(features.enableTaskList) {
        const taskRoutes: JankisRouteDefinition[] = [
            { path: HealthRecordUrls.ADD_TASK, element: <AddTaskOrChecklistPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...taskRoutes);
    }

    return routes;
}
export default JankisHealthRecordRoutes;