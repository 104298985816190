import { Alert, Button, Col, FormControl, Modal, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../localComponents/redux/store/healthRecordStore";
import { useEffect, useRef, useState } from "react";
import { buildLoadObjectFunc, loadObject } from "../../sharedCommonComponents/helpers/LoadingHelpers";
import { QueryParameters } from "../../sharedCommonComponents/types/frontendTypes";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { Models } from "../../localComponents/types/models";
import { LoadingAlert } from "../../sharedCommonComponents/components/LoadingAlert";
import { QuestionAnswerTable } from "../components/QuestionnaireAnswers/QuestionAnswerTable";
import { ClassificationType } from "../../localComponents/types/enums";
import { healthRecordEntrySliceActions } from "../redux/slices/healthRecordsSlice";
import { healthRecordModalsSlice } from "../redux/slices/healthRecordModalsSlice";
import { areClassificationReferencesEqual } from "../helpers/ClassificationReferenceHelpers";

interface AdditionalPersonInformationModalProps {}

export const AdditionalPersonInformationModal = (props: AdditionalPersonInformationModalProps) => {

    const { show, parameters } = useAppSelector(state => state.healthRecordModals.additionalPersonInformation);
    const viewSettings = useAppSelector(state => state.healthRecords.viewSettings);
    const [ additionalInformationCodes, setAdditionalInformationCodes ] = useState<Models.ClassificationReference[]>(
        viewSettings?.additionalInformationCodes ?? []);
    const [ settingsHaveChanged, setSettingsHaveChanged ] = useState<boolean>(false);
    const newUmlsCodeFormControl = useRef<HTMLInputElement>(null);

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ answers, setAnswers ] = useState<Models.Interview.QuestionAnswer[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!viewSettings?.additionalInformationCodes) {
            return;
        }
        setAdditionalInformationCodes(viewSettings.additionalInformationCodes);
    }, [ viewSettings ]);

    useEffect(() => {
        if(!parameters || !viewSettings?.additionalInformationCodes) {
            return;
        }
        if(viewSettings.additionalInformationCodes.length === 0) {
            setIsLoading(false);
            return;
        }
        const query: QueryParameters = viewSettings.additionalInformationCodes
            .filter(code => code.classification === ClassificationType.Umls)
            .map(code => ({ key: 'umlsCuiCodes', value: code.conceptId }));

        setIsLoading(true);
        const loadAdditionalInformation = buildLoadObjectFunc(
            `api/persons/${parameters.personId}/questionAnswers`, query,
            resolveText("QuestionAnswers_CouldNotLoad"),
            setAnswers,
            undefined,
            () => setIsLoading(false)
        );
        loadAdditionalInformation();
    }, [ parameters, viewSettings ]);

    const addUmlsCode = async () => {
        const conceptId = newUmlsCodeFormControl.current?.value;
        if(!conceptId) {
            return;
        }
        const concept = {
            classification: ClassificationType.Umls,
            conceptId: conceptId,
            displayName: conceptId
        };
        if(additionalInformationCodes.some(x => areClassificationReferencesEqual(x, concept))) {
            newUmlsCodeFormControl.current.value = '';
            return;
        }
        setAdditionalInformationCodes(state => state.concat(concept));
        setSettingsHaveChanged(true);
        newUmlsCodeFormControl.current.value = '';
        await loadObject<Models.Interview.QuestionAnswer>(
            `api/persons/${parameters!.personId}/questionAnswers`, [{ key: 'umlsCuiCodes', value: conceptId }],
            resolveText("QuestionAnswers_CouldNotLoad"),
            item => {
                setAnswers(state => state.concat(item));
            }
        );
    }
    const hideInformation = (code: Models.ClassificationReference) => {
        setAdditionalInformationCodes(state => state.filter(x => {
            if(areClassificationReferencesEqual(x, code)) {
                return false;
            }
            return true;
        }));
        setAnswers(state => state.filter(x => {
            switch(code.classification) {
                case ClassificationType.Loinc:
                    if(x.question.loincCode && x.question.loincCode === code.conceptId) {
                        return false;
                    }
                    break;
                case ClassificationType.Umls:
                    if(x.question.umlsCuiCode && x.question.umlsCuiCode.conceptId === code.conceptId) {
                        return false;
                    }
                    break;
            }
            return true;
        }));
        setSettingsHaveChanged(true);
    }

    useEffect(() => {
        if(!settingsHaveChanged) {
            return;
        }
        dispatch(healthRecordEntrySliceActions.storeHealthRecordViewSettings({
            args: {},
            body: {
                ...viewSettings,
                additionalInformationCodes: additionalInformationCodes
            }
        }));
        setSettingsHaveChanged(false);
    }, [ settingsHaveChanged ]);

    return (
    <Modal 
        show={show} 
        onHide={() => dispatch(healthRecordModalsSlice.actions.closeAdditionalPersonInformationModal())}
        size="lg"
    >
        <Modal.Header closeButton>
            <Modal.Title>{resolveText("Person_AdditionalInformations")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isLoading ? <LoadingAlert />
            : <>
                <Alert variant="warning">This feature is not fully implemented</Alert>
                <Row className="mb-2 align-items-center">
                    <Col xs="auto">
                        UMLS CUI
                    </Col>
                    <Col>
                        <FormControl
                            ref={newUmlsCodeFormControl}
                        />
                    </Col>
                    <Col xs="auto">
                        <Button
                            onClick={addUmlsCode}
                        >
                            {resolveText("Add")}
                        </Button>
                    </Col>
                </Row>
                <QuestionAnswerTable 
                    answers={answers}
                    onHideItem={hideInformation}
                />
            </>}
        </Modal.Body>
    </Modal>);

}