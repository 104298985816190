import { useCallback, useContext, useEffect, useState } from "react";
import { Models } from "../../types/models";
import { buildLoadObjectFunc } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { LoadingAlert } from "../../../sharedCommonComponents/components/LoadingAlert";
import { NoEntriesAlert } from "../../../sharedCommonComponents/components/NoEntriesAlert";
import { formatLegalEntityReference } from "../../../sharedHealthComponents/helpers/Formatters";
import { Alert, Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import { ViewModels } from "../../types/viewModels";
import { AccountType, LegalEntityType } from "../../types/enums";
import { ToolsUrls } from "../../../sharedHealthComponents/navigation/Urls";
import { UrlIdPlaceholders } from "../../navigation/Urls";

interface MyTranslatedChatsPageProps {}

export const MyTranslatedChatsPage = (props: MyTranslatedChatsPageProps) => {

    const user = useContext(UserContext) as ViewModels.IPersonUserViewModel;
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ chats, setChats ] = useState<Models.Interview.TranslatedChat[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        const loadMyChats = buildLoadObjectFunc(
            'api/translatedChat', {},
            resolveText("TranslatedChats_CouldNotLoad"),
            setChats,
            undefined,
            () => setIsLoading(false)
        );
        loadMyChats();
    }, []);

    const isMe = useCallback((chatParticipant: Models.Interview.TranslatedChatParticipants) => {
        switch(chatParticipant.legalEntity.type) {
            case LegalEntityType.Guest:
                return false; // This page is only accessible to registered users, which cannot be guests
            case LegalEntityType.Person:
                return chatParticipant.legalEntity.entityId === user.profileData.personId;
            case LegalEntityType.Practitioner:
                if(user.accountType !== AccountType.Employee) {
                    return false;
                }
                const practitioner = (user as ViewModels.EmployeeUserViewModel).professionalProfile;
                return chatParticipant.legalEntity.entityId === practitioner.id;
            case LegalEntityType.Institution:
            case LegalEntityType.Unknown:
            default:
                return false;
        }
    }, []);

    return (<>
        <Row>
            <Col>
                <h1>{resolveText("TranslatedChats")}</h1>
            </Col>
            <Col xs="auto">
                <Button
                    onClick={() => navigate(ToolsUrls.NEW_CHAT)}
                >
                    {resolveText("CreateNew")}
                </Button>
            </Col>
        </Row>
        {isLoading ? <LoadingAlert />
        : chats.length === 0 ? <NoEntriesAlert />
        : <Table>
            <thead>
                <tr>
                    <th>{resolveText("TranslatedChat_Participants")}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {chats.map(chat => {
                const participants = chat.participants.filter(participant => !isMe(participant));
                return (
                <tr
                    key={chat.id}
                >
                    <td>
                        {participants.length > 0
                        ? participants
                            .map(participant => participant.name ?? formatLegalEntityReference(participant.legalEntity))
                            .join(', ')
                        : resolveText("Me")}
                    </td>
                    <td>
                        <Button
                            onClick={() => navigate(ToolsUrls.OPEN_CHAT.replace(UrlIdPlaceholders.CHAT, chat.id))}
                            size="sm"
                        >
                            {resolveText("Open")}
                        </Button>
                    </td>
                </tr>);
            })}
            </tbody>
        </Table>}
    </>);

}
export default MyTranslatedChatsPage;