import { FormCheck } from "react-bootstrap";
import { Models } from "../../types/models";
import { useMemo } from "react";

interface DischargeLetterTemplateItemCheckboxProps {
    templateItem: Models.Documentation.DischargeLetterTemplateItem;
    allTemplateItems: { [id:string]: Models.Documentation.DischargeLetterTemplateItem };
    letterItems: Models.Documentation.DischargeLetterItem[];
    onSelected: (templateItem: Models.Documentation.DischargeLetterTemplateItem) => void;
    onUnselected: (templateItem: Models.Documentation.DischargeLetterTemplateItem) => void;
}

export const DischargeLetterTemplateItemCheckbox = (props: DischargeLetterTemplateItemCheckboxProps) => {

    const { templateItem, allTemplateItems, letterItems, onSelected, onUnselected } = props;

    const isSelected = useMemo(() => letterItems.some(x => x.templateItemId === templateItem.id), [ templateItem, letterItems ]);
    const subItems = useMemo(() => templateItem.subItemIds.map(subItemId => allTemplateItems[subItemId]).filter(x => !!x), [ templateItem, allTemplateItems ]);

    return (<>
        <FormCheck
            checked={isSelected}
            onChange={e => {
                if(e.target.checked) {
                    onSelected(templateItem);
                } else {
                    onUnselected(templateItem);
                }
            }}
            label={templateItem.name}
        />
        {!!templateItem.description
        ? <div>
            <small>{templateItem.description}</small>
        </div> : null}
        {isSelected && subItems.length > 0
        ? <div className="ms-3">
            {subItems.map(subItem => {
                return (
                    <DischargeLetterTemplateItemCheckbox
                        key={subItem.id}
                        templateItem={subItem}
                        allTemplateItems={allTemplateItems}
                        letterItems={letterItems}
                        onSelected={onSelected}
                        onUnselected={onUnselected}
                    />
                );
            })}
        </div> : null}
    </>);

}