import { GenericAutocomplete, GenericAutocompleteImplementationProps } from "../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete";
import { Models } from "../../types/models";

interface DischargeLetterTemplateAutocompleteProps extends GenericAutocompleteImplementationProps<Models.Documentation.DischargeLetterTemplate> {}

export const DischargeLetterTemplateAutocomplete = (props: DischargeLetterTemplateAutocompleteProps) => {

    return (<GenericAutocomplete
        {...props}
        apiPath="api/letterTemplates"
        displayFunc={x => x.name}
    />);

}