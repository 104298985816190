import { useEffect, useMemo, useState } from "react";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Col, FormGroup, FormLabel, ProgressBar, Row, Table } from "react-bootstrap";
import DepartmentIdAutocomplete from "../../../sharedHealthComponents/components/Autocompletes/DepartmentIdAutocomplete";
import { useNavigate, useParams } from "react-router-dom";
import { JanKisUrls, UrlIdPlaceholders } from "../../navigation/Urls";
import { buildLoadObjectFunc } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { LoadingAlert } from "../../../sharedCommonComponents/components/LoadingAlert";
import { ViewModels } from "../../types/viewModels";
import { NoEntriesTableRow } from "../../../sharedCommonComponents/components/NoEntriesTableRow";
import { groupBy } from "../../../sharedCommonComponents/helpers/CollectionHelpers";
import { formatStock } from "../../helpers/Formatters";

interface RestockingPageProps {}

enum RestockingGroupKey {
    Consumable = "Consumable",
    Stock = "Stock"
}
export const RestockingPage = (props: RestockingPageProps) => {

    const { departmentId } = useParams();

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ lowStockStates, setLowStockStates ] = useState<ViewModels.StockStateViewModel[]>([]);
    const [ groupKey, setGroupKey ] = useState<RestockingGroupKey>(RestockingGroupKey.Stock);
    const groupedStockStates = useMemo(() => {
        switch(groupKey) {
            case RestockingGroupKey.Consumable:
                return groupBy(lowStockStates, x => x.consumableId);
            case RestockingGroupKey.Stock:
                return groupBy(lowStockStates, x => x.stockId);
            default:
                throw new Error(`Unknown group key ${groupKey} for low stock states`);
        }
    }, [ groupKey, lowStockStates ]);
    const navigate = useNavigate();

    useEffect(() => {
        if(!departmentId) {
            setLowStockStates([]);
            return;
        }
        setIsLoading(true);
        const loadLowStockStates = buildLoadObjectFunc(
            `api/departments/${departmentId}/restocking`, {},
            resolveText("StockStates_CouldNotLoadLowStockStates"),
            setLowStockStates,
            undefined,
            () => setIsLoading(false)
        );
        loadLowStockStates();
    }, [ departmentId ]);

    if(isLoading) {
        return (<LoadingAlert />);
    }

    return (<>
        <h1>{resolveText("StockState_RestockingPageTitle")}</h1>
        <FormGroup as={Row}>
            <FormLabel column>{resolveText("Department")}</FormLabel>
            <Col>
                <DepartmentIdAutocomplete
                    value={departmentId}
                    onChange={id => {
                        if(!id) {
                            return;
                        }
                        navigate(JanKisUrls.DEPARTMENT_RESTOCKING.replace(UrlIdPlaceholders.DEPARTMENT, id));
                    }}
                />
            </Col>
        </FormGroup>
        <Table>
            <thead>
                <tr>
                    <th>{resolveText("Consumable")}</th>
                    <th>{resolveText("Stock")}</th>
                    <th>{resolveText("StockState_Quantity")}</th>
                    <th>{resolveText("StockState_LowQuantityThreshold_Abbreviation")}</th>
                </tr>
            </thead>
            <tbody>
                {lowStockStates.length === 0 ? <NoEntriesTableRow colSpan={4} />
                : lowStockStates.map(stockState => (
                    <tr key={stockState.id}>
                        <td>{stockState.consumable.name}</td>
                        <td>{formatStock(stockState.stock)}</td>
                        <td>
                            <ProgressBar
                                variant="danger"
                                now={stockState.quantity}
                                max={stockState.lowQuantityThreshold}
                                label={stockState.quantity}
                                className="me-2"
                            />
                        </td>
                        <td>
                            {stockState.lowQuantityThreshold}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </>);

}
export default RestockingPage;