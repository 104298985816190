import { useNavigate } from "react-router-dom";
import { EditLinkButton } from "../../../sharedCommonComponents/components/EditLinkButton";
import { GenericReduxList } from "../../../sharedHealthComponents/components/GenericReduxList";
import { letterTemplatesActions } from "../../redux/slices/letterTemplatesSlice";
import { JanKisUrls, UrlIdPlaceholders } from "../../navigation/Urls";
import { DeleteButton } from "../../../sharedCommonComponents/components/DeleteButon";
import { useCallback } from "react";
import { useAppDispatch } from "../../redux/store/healthRecordStore";

interface LetterTemplatesPageProps {}

export const LetterTemplatesPage = (props: LetterTemplatesPageProps) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const deleteTemplate = useCallback((itemId: string) => {
        dispatch(letterTemplatesActions.deleteItem({
            args: itemId
        }));
    }, [ dispatch ]);

    return (<GenericReduxList
        sliceSelector={state => state.letterTemplates}
        loadItems={letterTemplatesActions.loadItems}
        tableHeaderBuilder={() => (
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
        )}
        tableRowBuilder={item => (
            <tr key={item.id}>
                <td>
                    <DeleteButton
                        size="xs"
                        onClick={() => deleteTemplate(item.id)}
                    />
                </td>
                <td>
                    {item.name}
                    {item.description
                    ? <div className="text-secondary">
                        <small>{item.description}</small>
                    </div> : null}
                </td>
                <td>
                    <EditLinkButton
                        onClick={() => navigate(JanKisUrls.EDIT_LETTERTEMPLATE.replace(UrlIdPlaceholders.ID, item.id))}
                    />
                </td>
            </tr>
        )}
        colSpan={3}
    />);

}
export default LetterTemplatesPage;