import { FormGroup, FormLabel, FormControl, Table, Col, Row, FormText, FormCheck, Badge, Button } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Update } from "../../../sharedCommonComponents/types/frontendTypes";
import { Fragment, ReactNode, useCallback, useEffect, useMemo } from "react";
import { AutoCompleteContext, DischargeLetterItemTimeAspect, DischargeLetterItemType, MedicalProcedureOutcome } from "../../types/enums";
import { DateFormControl } from "../../../sharedCommonComponents/components/FormControls/DateFormControl";
import { Models } from "../../types/models";
import { DischargeLetterItemAutocomplete } from "./DischargeLetterItemAutocomplete";
import { SelectFormControl } from "../../../sharedCommonComponents/components/FormControls/SelectFormControl";
import { MemoryFormControl } from "../../../sharedCommonComponents/components/FormControls/MemoryFormControl";
import { DeleteButton } from "../../../sharedCommonComponents/components/DeleteButon";
import { ListFormControl } from "../../../sharedCommonComponents/components/FormControls/ListFormControl";
import { uuid } from "../../../sharedCommonComponents/helpers/uuid";
import { extractPlaceholders } from "../../helpers/LetterHelpers";
import { toDictionary } from "../../../sharedCommonComponents/helpers/Transformations";

interface DischargeLetterItemEditorProps {
    item: Models.Documentation.DischargeLetterItem;
    template?: Models.Documentation.DischargeLetterTemplateItem;
    onChange: (itemId: string, update: Update<Models.Documentation.DischargeLetterItem>) => void;
    allItems: Models.Documentation.DischargeLetterItem[];
    onNavigateToOtherItem: (itemId: string) => void;
}

export const DischargeLetterItemEditor = (props: DischargeLetterItemEditorProps) => {

    const { item, template, onChange, allItems, onNavigateToOtherItem } = props;

    const itemsReactingToThisItem = useMemo(() => allItems.filter(x => (x as unknown as Models.Documentation.IReactionDischargeLetterItem).reactionTo?.includes(item.id)), [ item.id, allItems ]);

    const placeholders: string[] = useMemo(() => extractPlaceholders(item.text), [ item.text ]);
    const placeholderReplacedText = useMemo(() => {
        let str = item.text;
        for (const placeholder in item.placeholderValues) {
            const placeholderValue = item.placeholderValues[placeholder].trim();
            if(placeholderValue.length === 0) {
                return;
            }
            str = str.replaceAll(placeholder, placeholderValue);
        }
        return str;
    }, [ item.text, item.placeholderValues ]);

    useEffect(() => {
        onChange(item.id, item => ({
            ...item,
            placeholderValues: Object.assign(toDictionary(placeholders, x => x, _ => ''), item.placeholderValues)
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ item.id, placeholders ]);

    const updatePlaceholderValue = useCallback((placeholder: string, value: string) => {
        onChange(item.id, item => ({
            ...item,
            placeholderValues: {
                ...item.placeholderValues,
                [placeholder]: value
            }
        }));
    }, [ item, onChange ]);

    const addDailySymptomValue = useCallback(() => {
        if(item.type !== DischargeLetterItemType.Symptom) {
            return;
        }
        const symptomItem = item as Models.Documentation.SymptomDischargeLetterItem;
        onChange(item.id, item => ({
            ...item,
            dailyValues: symptomItem.dailyValues.concat({
                id: uuid(),
                date: new Date().toISOString() as any,
                value: '',
                isHighlight: false
            } as Models.Documentation.DailySymptomValue)
        } as Models.Documentation.SymptomDischargeLetterItem));
    }, [ item, onChange ]);

    const updateDailyValue = useCallback((valueId: string, update: Update<Models.Documentation.DailySymptomValue>) => {
        if(item.type !== DischargeLetterItemType.Symptom) {
            return;
        }
        const symptomItem = item as Models.Documentation.SymptomDischargeLetterItem;
        onChange(item.id, item => ({
            ...item,
            dailyValues: symptomItem.dailyValues.map(x => x.id === valueId ? update(x) : x)
        } as Models.Documentation.SymptomDischargeLetterItem));
    }, [ item, onChange ]);

    const deleteDailyValue = useCallback((valueId: string) => {
        if(item.type !== DischargeLetterItemType.Symptom) {
            return;
        }
        const symptomItem = item as Models.Documentation.SymptomDischargeLetterItem;
        onChange(item.id, item => ({
            ...item,
            dailyValues: symptomItem.dailyValues.filter(x => x.id !== valueId)
        } as Models.Documentation.SymptomDischargeLetterItem));
    }, [ item, onChange ]);

    const typeSpecificFormControls = useMemo(() => {
        const nodes: ReactNode[] = [];
        if(!!template) {
            switch(template.timeAspect) {
                case DischargeLetterItemTimeAspect.Permanent:
                    // Nothing to do
                    break;
                case DischargeLetterItemTimeAspect.PointInTime:
                    const pointInTimeItem = item as Models.Documentation.PointInTimeDischargeLetterItem;
                    nodes.push(<FormGroup key='timestamp'>
                        <FormLabel>{resolveText("PointInTimeDischargeLetterItem_Timestamp")}</FormLabel>
                        <DateFormControl
                            value={pointInTimeItem.timestamp ?? undefined}
                            onChange={timestamp => onChange(item.id, item => ({
                                ...item,
                                timestamp: timestamp
                            } as Models.Documentation.PointInTimeDischargeLetterItem))}
                        />
                    </FormGroup>);
                    break;
                case DischargeLetterItemTimeAspect.Interval:
                    const timeRangeItem = item as Models.Documentation.TimeRangeDischargeLetterItem;
                    nodes.push(<Row key='time-range'>
                        <Col>
                            <FormGroup>
                                <FormLabel>{resolveText("TimeRangeDischargeLetterItem_StartDate")}</FormLabel>
                                <DateFormControl
                                    value={timeRangeItem.startDate ?? undefined}
                                    onChange={date => onChange(item.id, item => ({
                                        ...item,
                                        startDate: date
                                    } as Models.Documentation.TimeRangeDischargeLetterItem))}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <FormLabel>{resolveText("TimeRangeDischargeLetterItem_EndDate")}</FormLabel>
                                <DateFormControl
                                    value={timeRangeItem.endDate ?? undefined}
                                    onChange={date => onChange(item.id, item => ({
                                        ...item,
                                        endDate: date
                                    } as Models.Documentation.TimeRangeDischargeLetterItem))}
                                />
                            </FormGroup>
                        </Col>
                    </Row>);
                    break;
            }
        }
        
        switch(item.type) {
            case DischargeLetterItemType.Disease:
                //const diseaseItem = item as Models.Documentation.DiseaseDischargeLetterItem;
                // Nothing type-specific
                break;
            case DischargeLetterItemType.Equipment:
                //const equipmentItem = item as Models.Documentation.EquipmentDischargeLetterItem;
                // Nothing type-specific
                break;
            case DischargeLetterItemType.Freetext:
                //const freetextItem = item as Models.Documentation.FreetextDischargeLetterItem;
                // Nothing type-specific
                break;
            case DischargeLetterItemType.MedicationDispension:
                const medicationDispensionItem = item as Models.Documentation.MedicationDispensionDischargeLetterItem;
                nodes.push(<FormGroup key='medication-dosage'>
                    <FormLabel>{resolveText("MedicationDispensionDischargeLetterItem_Dosage")}</FormLabel>
                    <FormControl
                        value={medicationDispensionItem.dosage ?? ''}
                        onChange={e => onChange(item.id, item => ({
                            ...item,
                            dosage: e.target.value
                        } as Models.Documentation.MedicationDispensionDischargeLetterItem))}
                    />
                </FormGroup>);
                break;
            case DischargeLetterItemType.Observation:
                //const observationItem = item as Models.Documentation.ObservationDischargeLetterItem;
                // Nothing type-specific
                break;
            case DischargeLetterItemType.PatientInformation:
                //const patientInformationItem = item as Models.Documentation.PatientInformationDischargeLetterItem;
                // Nothing type-specific
                break;
            case DischargeLetterItemType.Procedure:
                const procedureItem = item as Models.Documentation.ProcedureDischargeLetterItem;
                nodes.push(<FormGroup key='procedure-name'>
                    <FormLabel>{resolveText("ProcedureDischargeLetterItem_ProcedureName")}</FormLabel>
                    <MemoryFormControl
                        context={AutoCompleteContext.MedicalProcedure}
                        defaultValue={procedureItem.procedureName}
                        onChange={value => onChange(item.id, item => ({
                            ...item,
                            procedureName: value
                        } as Models.Documentation.ProcedureDischargeLetterItem))}
                    />
                </FormGroup>);
                nodes.push(<FormGroup key='procedure-outcome'>
                    <FormLabel>{resolveText("ProcedureDischargeLetterItem_Outcome")}</FormLabel>
                    <SelectFormControl
                        enumName="MedicalProcedureOutcome"
                        enumValues={Object.values(MedicalProcedureOutcome)}
                        value={procedureItem.outcome ?? undefined}
                        onChange={outcome => onChange(item.id, item => ({
                            ...item,
                            outcome: outcome as MedicalProcedureOutcome | undefined
                        } as Models.Documentation.ProcedureDischargeLetterItem))}
                    />
                </FormGroup>);
                break;
            case DischargeLetterItemType.Symptom:
                const symptomItem = item as Models.Documentation.SymptomDischargeLetterItem;
                nodes.push(
                <FormGroup key='symptom-daily-values'>
                    <FormLabel>{resolveText("SymptomDischargeLetterItem_DailyValues")}</FormLabel>
                    <Table>
                        <thead>
                            <tr>
                                <th>{resolveText("DailySymptomValue_IsHighlight")}</th>
                                <th>{resolveText("DailySymptomValue_Date")}</th>
                                <th>{resolveText("DailySymptomValue_Value")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {symptomItem.dailyValues.map(dailyValue => (
                                <tr key={dailyValue.date as any}>
                                    <td>
                                        <FormCheck
                                            checked={dailyValue.isHighlight}
                                            onChange={e => updateDailyValue(dailyValue.id, x => ({
                                                ...x,
                                                isHighlight: e.target.checked
                                            } as Models.Documentation.DailySymptomValue))}
                                        />
                                    </td>
                                    <td>
                                        <DateFormControl
                                            enableTime
                                            value={dailyValue.date}
                                            onChange={date => {
                                                if(!date) {
                                                    return;
                                                }
                                                updateDailyValue(dailyValue.id, x => ({
                                                    ...x,
                                                    date: date
                                                } as Models.Documentation.DailySymptomValue));
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <FormControl
                                            value={dailyValue.value}
                                            onChange={e => updateDailyValue(dailyValue.id, x => ({
                                                ...x,
                                                value: e.target.value
                                            } as Models.Documentation.DailySymptomValue))}
                                        />
                                    </td>
                                    <td>
                                        <DeleteButton
                                            size="xs"
                                            requireConfirm
                                            confirmDialogTitle={resolveText("DailySymptomValue_ConfirmDelete_Title")}
                                            confirmDialogMessage={resolveText("DailySymptomValue_ConfirmDelete_Message")}
                                            onClick={() => deleteDailyValue(dailyValue.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={4} className="text-center">
                                    <Button
                                        size="sm"
                                        onClick={addDailySymptomValue}
                                    >
                                        + {resolveText("CreateNew")}
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </FormGroup>);
                nodes.push(<FormGroup key='symptom-most-significant-value'>
                    <FormLabel>{resolveText("SymptomDischargeLetterItem_MostSignificantValue")}</FormLabel>
                    <FormControl
                        value={symptomItem.mostSignificantValue ?? ''}
                        onChange={e => onChange(item.id, item => ({
                            ...item,
                            mostSignificantValue: e.target.value
                        } as Models.Documentation.SymptomDischargeLetterItem))}
                    />
                </FormGroup>);
                break;
            case DischargeLetterItemType.TestResult:
                const testResultItem = item as Models.Documentation.TestResultDischargeLetterItem;
                nodes.push(<FormGroup key='test-result-testname'>
                    <FormLabel>{resolveText("TestResultDischargeLetterItem_TestName")}</FormLabel>
                    <MemoryFormControl
                        context={AutoCompleteContext.DiagnosticTestName}
                        defaultValue={testResultItem.testName}
                        onChange={value => onChange(item.id, item => ({
                            ...item,
                            testName: value
                        } as Models.Documentation.TestResultDischargeLetterItem))}
                    />
                </FormGroup>);
                nodes.push(<FormGroup key='test-result-value'>
                    <FormLabel>{resolveText("TestResultDischargeLetterItem_Value")}</FormLabel>
                    <FormControl
                        value={testResultItem.value ?? ''}
                        onChange={e => onChange(item.id, item => ({
                            ...item,
                            value: e.target.value
                        } as Models.Documentation.TestResultDischargeLetterItem))}
                    />
                </FormGroup>);
                break;
        }
        switch(item.type) { // Items with reaction
            case DischargeLetterItemType.Equipment:
            case DischargeLetterItemType.MedicationDispension:
            case DischargeLetterItemType.Procedure:
                const reactionItem = item as unknown as Models.Documentation.IReactionDischargeLetterItem;
                const itemsBeingReactedTo = allItems.filter(otherItem => reactionItem.reactionTo.includes(otherItem.id));
                nodes.push(<Fragment key='reactions'>
                <FormGroup>
                    <FormLabel>{resolveText("IReactionDischargeLetterItem_ReactionTo")}</FormLabel>
                    <DischargeLetterItemAutocomplete
                        items={allItems}
                        onChange={otherItem => {
                            if(!otherItem) {
                                return;
                            }
                            onChange(item.id, item => {
                                const reactionItem = item as unknown as Models.Documentation.IReactionDischargeLetterItem;
                                if(reactionItem.reactionTo.includes(otherItem.id)) {
                                    return item;
                                }
                                return {
                                    ...item,
                                    reactionTo: reactionItem.reactionTo.concat(otherItem.id)
                                };
                            });
                        }}
                    />
                </FormGroup>
                <Row className="mt-1">
                    <Col>
                        <ListFormControl
                            items={itemsBeingReactedTo}
                            displayFunc={x => x.name}
                            idFunc={x => x.id}
                            removeItem={itemToBeRemoved => onChange(item.id, item => ({
                                ...item,
                                reactionTo: (item as unknown as Models.Documentation.IReactionDischargeLetterItem).reactionTo.filter(x => x !== itemToBeRemoved.id)
                            }))}
                        />
                    </Col>
                </Row>
                </Fragment>);
                break;
        }
        return nodes;
    }, [ item, template, onChange, allItems, addDailySymptomValue, updateDailyValue, deleteDailyValue ]);

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("DischargeLetterTemplateItem_Texts")}</FormLabel>
            <Row className="align-items-center">
                {!!template
                ? <>
                    <Col>
                        <FormControl
                            as="select"
                            value=""
                            onChange={e => {
                                if(!e.target.value) {
                                    return;
                                }
                                onChange(item.id, item => ({
                                    ...item,
                                    text: template.texts[Number(e.target.value)]
                                }));
                            }}
                            disabled={item.text.trim().length > 0}
                        >
                            <option value="">{resolveText("PleaseSelect...")}</option>
                            {template.texts.map((text, textIndex) => (
                                <option key={textIndex} value={textIndex}>{text}</option>
                            ))}
                        </FormControl>
                    </Col>
                    <Col xs="auto">
                        <i className="fa fa-arrow-right" />
                    </Col>
                </> : null}
                <Col>
                    <FormControl
                        value={item.text}
                        onChange={e => onChange(item.id, item => ({
                            ...item,
                            text: e.target.value
                        }))}
                    />
                    <FormText>{placeholderReplacedText}</FormText>
                </Col>
            </Row>
        </FormGroup>
        {typeSpecificFormControls}
        {placeholders.length > 0
        ? <Table>
            <thead>
                <tr>
                    <th>Placeholder</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                {placeholders.map(placeholder => (
                    <tr key={placeholder}>
                        <td>{placeholder}</td>
                        <td>
                            <FormControl
                                value={item.placeholderValues[placeholder] ?? ''}
                                onChange={e => updatePlaceholderValue(placeholder, e.target.value)}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table> : null}
        {itemsReactingToThisItem.length > 0 
        ? <FormGroup>
            {resolveText("DischargeLetterItem_Reactions")} {itemsReactingToThisItem.map(reactingItem => (
                <Badge
                    key={reactingItem.id}
                    pill
                    bg="success"
                    className="text-light m-2 clickable"
                    onClick={() => onNavigateToOtherItem(reactingItem.id)}
                >
                    {reactingItem.name}
                </Badge>
            ))}
        </FormGroup> : null}
    </>);

}