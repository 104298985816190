import { lazy } from 'react';
import { RouteDefinition } from '../../sharedCommonComponents/types/frontendTypes';
import { RedirectToLogin } from '../components/RedirectToLogin';
import { Models } from '../types/models';
import { SharedUrls } from '../../sharedHealthComponents/navigation/Urls';

const LoginPage = lazy(() => import('../pages/LoginPage'));
const PrivacyPage = lazy(() => import('../pages/PrivacyPage'));

interface NotLoggedInRoutesProps {
    onLoggedIn: (loginResult: Models.AccessControl.LoginResult, redirectUrl?: string) => void;
}

export const NotLoggedInRoutes = (props: NotLoggedInRoutesProps): RouteDefinition[] => [
    { path: SharedUrls.PRIVACY_STATEMENT, element: <PrivacyPage /> },
    { path: '/', element: <LoginPage onLoggedIn={props.onLoggedIn} /> },
    { path: SharedUrls.LOGIN, element: <LoginPage onLoggedIn={props.onLoggedIn} /> },
    { path: '*', element: <RedirectToLogin /> },
]