import { apiClient } from "../../sharedCommonComponents/communication/ApiClient";
import { downloadFile } from "../../sharedCommonComponents/communication/FileDownloader";

export const readFileAsBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
export const createOneTimeToken = async (documentId: string) => {
    const oneTimeTokenResponse = await apiClient.instance!.get(`api/documents/${documentId}/one-time-token`);
    return await oneTimeTokenResponse.json();
}
export const createOneTimeTokenAndDownloadDocument = async (documentId: string) => {
    const oneTimeToken = await createOneTimeToken(documentId);
    await downloadFile(`api/documents/${oneTimeToken}/download`);
}