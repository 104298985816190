import { Alert, Card, Col, Form, FormControl, FormGroup, FormLabel, Row, Tab, Tabs } from "react-bootstrap";
import PageContainer from "../../../sharedCommonComponents/components/PageContainer";
import { useNavigate, useParams } from "react-router-dom";
import { buildHealthRecordUrl } from "../../../sharedHealthComponents/navigation/Urls";
import { JanKisHealthRecordUrls, UrlIdPlaceholders } from "../../navigation/Urls";
import { MissingIdAlert } from "../../../sharedCommonComponents/components/MissingIdAlert";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Models } from "../../types/models";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { uuid } from "../../../sharedCommonComponents/helpers/uuid";
import { DischargeLetterItemType, DischargeLetterStructure, TextInsertMode } from "../../types/enums";
import { sendPostRequest } from "../../../sharedCommonComponents/helpers/StoringHelpers";
import { openConfirmDeleteAlert, showSuccessAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";
import { StoreButton } from "../../../sharedCommonComponents/components/StoreButton";
import { loadObject } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { ViewModels } from "../../types/viewModels";
import { LoadingAlert } from "../../../sharedCommonComponents/components/LoadingAlert";
import { groupBy } from "../../../sharedCommonComponents/helpers/CollectionHelpers";
import { DischargeLetterTemplateAutocomplete } from "../../components/Autocompletes/DischargeLetterTemplateAutocomplete";
import { toDictionary } from "../../../sharedCommonComponents/helpers/Transformations";
import { SelectFormControl } from "../../../sharedCommonComponents/components/FormControls/SelectFormControl";
import { DischargeLetterItemGroupedAccordionCards } from "../../components/Documentation/DischargeLetterItemGroupedAccordionCards";
import { Update } from "../../../sharedCommonComponents/types/frontendTypes";
import { useMemorizedSelector } from "../../../sharedHealthComponents/redux/helpers/ReduxHooks";
import { personsActions, personsSelectors } from "../../../sharedHealthComponents/redux/slices/personsSlice";
import { useAppDispatch } from "../../redux/store/healthRecordStore";
import { DischargeLetterPreview } from "../../components/Documentation/DischargeLetterPreview";
import { DischargeLetterTemplateItemCheckbox } from "../../components/Documentation/DischargeLetterTemplateItemCheckbox";
import { LetterPlaceholdersTable } from "../../components/Documentation/LetterPlaceholdersTable";
import { extractPlaceholders } from "../../helpers/LetterHelpers";
import { TextPlaceholderModal } from "../../modals/TextPlaceholderModal";

interface DischargeLetterBuilderPageProps {}

export const DischargeLetterBuilderPage = (props: DischargeLetterBuilderPageProps) => {

    const { personId, id: matchedId } = useParams();

    const [ isLoading, setIsLoading ] = useState<boolean>(!!matchedId);
    const person = useMemorizedSelector(personsSelectors.getById, { id: personId });
    const [ letter, setLetter ] = useState<Models.Documentation.DischargeLetter>({
        id: matchedId ?? uuid(),
        personId: personId!,
        timestamp: new Date().toISOString() as any,
        title: '',
        structure: DischargeLetterStructure.Chronologic,
        items: [],
        templateIds: []
    });
    const [ templates, setTemplates ] = useState<Models.Documentation.DischargeLetterTemplate[]>([]);
    const allTemplateItems = useMemo(() => 
        toDictionary<
            Models.Documentation.DischargeLetterTemplateItem,
            Models.Documentation.DischargeLetterTemplateItem
        >(
            templates.flatMap(template => template.items), 
            x => x.id
        )
    , [ templates ]);
    const topLevelTemplateItems = useMemo(() => {
        return templates.flatMap(template => {
            return template.items.filter(templateItem => template.topLevelItemIds.includes(templateItem.id));
        });
    }, [ templates ]);
    const typeGroupedTopLevelTemplateItems = useMemo(() => {
        return groupBy(topLevelTemplateItems, x => x.type);
    }, [ topLevelTemplateItems ]);
    const allPlaceholders = useMemo(() => Object.assign({}, ...letter.items.map(item => item.placeholderValues)), [ letter ]);
    const [ isStoring, setIsStoring ] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const load = useCallback(async (id: string) => {
        setIsLoading(true);
        await loadObject<ViewModels.Documentation.DischargeLetterViewModel>(
            `api/letters/${id}`, {},
            resolveText("DischargeLetter_CouldNotLoad"),
            vm => {
                setLetter({
                    id: vm.id,
                    personId: vm.personId,
                    title: vm.title,
                    structure: vm.structure,
                    timestamp: vm.timestamp,
                    items: vm.items,
                    templateIds: vm.templateIds
                });
                setTemplates(vm.templates);
            },
            undefined,
            () => setIsLoading(false)
        );
    }, []);

    useEffect(() => {
        if(!matchedId) {
            return;
        }
        load(matchedId);
    }, [ matchedId, load ]);

    useEffect(() => {
        if(!personId) {
            return;
        }
        dispatch(personsActions.loadItemIfNotLoadedYet({
            args: {
                itemId: personId
            }
        }));
    }, [ personId, dispatch ]);

    const store = useCallback(async (e?: FormEvent) => {
        e?.preventDefault();
        if(!personId) {
            return;
        }
        setIsStoring(true);
        await sendPostRequest(
            'api/letters', {},
            resolveText("DischargeLetter_CouldNotStore"),
            letter,
            async () => {
                showSuccessAlert(resolveText("DischargeLetter_SuccessfullyStored"));
                if(!matchedId) {
                    navigate(buildHealthRecordUrl(personId, JanKisHealthRecordUrls.EDIT_LETTER
                        .replace(UrlIdPlaceholders.PERSON, personId)
                        .replace(UrlIdPlaceholders.ID, letter.id)), 
                    { replace: true });
                }
            },
            undefined,
            () => setIsStoring(false)
        );
    }, [letter, matchedId, navigate, personId]);

    const createLetterItem = useCallback((templateItem: Models.Documentation.DischargeLetterTemplateItem) => {
        setLetter(state => {
            if(state.items.some(item => item.templateItemId === templateItem.id)) {
                return state;
            }
            const itemModel: Models.Documentation.DischargeLetterItem = {
                id: uuid(),
                type: templateItem.type,
                name: templateItem.name,
                description: templateItem.description,
                text: templateItem.texts[0],
                textInsertMode: TextInsertMode.Append,
                placeholderValues: toDictionary(extractPlaceholders(templateItem.texts[0]), placeholder => placeholder, _ => ''),
                templateItemId: templateItem.id,
            };
            switch(itemModel.type) {
                case DischargeLetterItemType.Symptom:
                    const symptomItem = itemModel as Models.Documentation.SymptomDischargeLetterItem;
                    symptomItem.dailyValues = [];
                    break;
            }
            switch(itemModel.type) { // Reaction-items
                case DischargeLetterItemType.Equipment:
                case DischargeLetterItemType.MedicationDispension:
                case DischargeLetterItemType.Procedure:
                    const reactionItem = itemModel as unknown as Models.Documentation.IReactionDischargeLetterItem;
                    reactionItem.reactionTo = [];
                    break;
            }
            return {
                ...state,
                items: state.items.concat(itemModel)
            };
        })
    }, []);

    const updateItem = useCallback((itemId: string, update: Update<Models.Documentation.DischargeLetterItem>) => {
        setLetter(state => ({
            ...state,
            items: state.items.map(item => item.id === itemId ? update(item) : item)
        }));
    }, []);

    const unselectTemplateItem = useCallback((templateItemId: string, templateItemName: string, force: boolean = false) => {
        if(!force) {
            openConfirmDeleteAlert(
                templateItemName,
                resolveText("DischargeLetterItem_ConfirmDelete_Title"),
                resolveText("DischargeLetterItem_ConfirmDelete_Message"),
                () => unselectTemplateItem(templateItemId, templateItemName, true)
            );
            return;
        }
        setLetter(state => ({
            ...state,
            items: state.items.filter(x => x.templateItemId !== templateItemId)
        }));
    }, []);

    const updatePlaceholder = useCallback((placeholder: string, value: string) => {
        setLetter(state => ({
            ...state,
            items: state.items.map(item => {
                if(item.placeholderValues[placeholder] !== undefined) {
                    return {
                        ...item,
                        placeholderValues: Object.assign(item.placeholderValues, { [placeholder]: value })
                    }
                }
                return item;
            })
        }));
    }, []);

    if(!personId) {
        return (<MissingIdAlert />);
    }
    if(isLoading) {
        return (<LoadingAlert />);
    }

    return (<PageContainer>
        <Row className="align-items-center">
            <Col>
                <h1>Discharge letter builder</h1>
            </Col>
            <Col xs="auto">
                <StoreButton 
                    onClick={store}
                    isStoring={isStoring}
                    className="m-0"
                />
            </Col>
        </Row>
        <Form onSubmit={store}>
            <FormGroup>
                <FormLabel>{resolveText("DischargeLetter_Title")}</FormLabel>
                <FormControl
                    value={letter.title}
                    onChange={e => setLetter(state => ({
                        ...state,
                        title: e.target.value
                    }))}
                    size="lg"
                />
            </FormGroup>
            <Tabs className="mt-3">
                <Tab
                    eventKey='settings'
                    title={resolveText("DischargeLetter_Settings")}
                    className="pt-3"
                >
                    <FormGroup>
                        <FormLabel>{resolveText("DischargeLetter_Structure")}</FormLabel>
                        <SelectFormControl
                            enumName="DischargeLetterStructure"
                            enumValues={Object.values(DischargeLetterStructure)}
                            value={letter.structure}
                            onChange={e => setLetter(state => ({
                                ...state,
                                structure: e as DischargeLetterStructure
                            }))}
                        />
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <FormLabel>{resolveText("DischargeLetter_TemplateIds")}</FormLabel>
                        {templates.map(template => (
                            <Alert
                                key={template.id}
                                variant="warning"
                                dismissible
                                onClose={() => setTemplates(state => state.filter(x => x.id !== template.id))}
                            >
                                {template.name}
                                {!!template.description
                                ? <div className="text-secondary">
                                    <small>{template.description}</small>
                                </div> : null}
                            </Alert>
                        ))}
                        <DischargeLetterTemplateAutocomplete
                            onChange={template => {
                                if(!template) {
                                    return;
                                }
                                setTemplates(state => {
                                    if(state.some(x => x.id === template.id)) {
                                        return state;
                                    }
                                    return state.concat(template);
                                });
                            }}
                        />
                    </FormGroup>
                </Tab>
                <Tab
                    eventKey='items'
                    title={resolveText("DischargeLetter_Items")}
                    className="pt-3"
                >
                    <Row>
                        <Col>
                            {typeGroupedTopLevelTemplateItems.map(typeGroup => (
                                <Card
                                    key={typeGroup.key}
                                    className="mb-1"
                                >
                                    <Card.Header className="py-1">
                                        <Card.Title>{resolveText(`DischargeLetterItemType_${typeGroup.key}`)}</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="py-1">
                                        {typeGroup.items.map(templateItem => {
                                            return (<DischargeLetterTemplateItemCheckbox 
                                                key={templateItem.id}
                                                templateItem={templateItem}
                                                allTemplateItems={allTemplateItems}
                                                letterItems={letter.items}
                                                onSelected={createLetterItem}
                                                onUnselected={templateItem => unselectTemplateItem(templateItem.id, templateItem.name)}
                                            />);
                                        })}
                                    </Card.Body>
                                </Card>
                            ))}
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Tab>
                <Tab
                    eventKey='details'
                    title={resolveText("DischargeLetter_Details")}
                    className="pt-3"
                >
                    <DischargeLetterItemGroupedAccordionCards
                        items={letter.items}
                        templateItems={allTemplateItems}
                        onChange={updateItem}
                        onItemDeleted={itemId => setLetter(state => ({
                            ...state,
                            items: state.items.filter(item => item.id !== itemId)
                        }))}
                    />
                </Tab>
                <Tab
                    eventKey='placeholders'
                    title={resolveText("DischargeLetter_Placeholders")}
                    className="pt-3"
                >
                    <LetterPlaceholdersTable
                        placeholders={allPlaceholders}
                        onChange={updatePlaceholder}
                    />
                </Tab>
                <Tab
                    eventKey='preview'
                    title={resolveText("DischargeLetter_Preview")}
                    className="pt-3"
                >
                    <DischargeLetterPreview
                        person={person}
                        letter={letter}
                    />
                </Tab>
            </Tabs>
        </Form>
        <TextPlaceholderModal />
    </PageContainer>);

}
export default DischargeLetterBuilderPage;