import { startOfWeek, endOfWeek } from "date-fns";
import { useState, useMemo, useEffect } from "react";
import LocalizedCalendar from "../../../sharedHealthComponents/components/Scheduling/LocalizedCalendar";
import { appointmentsSlice, appointmentsActions } from "../../../sharedHealthComponents/redux/slices/appointmentsSlice";
import { medicalProceduresSlice, medicalProceduresActions } from "../../../sharedHealthComponents/redux/slices/medicalProceduresSlice";
import { TimeRange } from "../../../sharedHealthComponents/types/frontendTypes";
import { createCalendarEventsFromAppointmentsAndProcedures } from "../../helpers/CalendarHelpers";
import { useAppSelector, useAppDispatch } from "../../redux/store/healthRecordStore";

interface PatientCalendarProps {
    personId: string;
    height: number;
}

export const PatientCalendar = (props: PatientCalendarProps) => {

    const { personId, height } = props;

    const [ visibleTimeRange, setVisibleTimeRange ] = useState<TimeRange>({
        start: startOfWeek(new Date(), {
            weekStartsOn: 1
        }).toISOString() as any,
        end: endOfWeek(new Date(), {
            weekStartsOn: 1
        }).toISOString() as any
    });
    const procedures = useAppSelector(state => state.medicalProcedures.items);
    const appointments = useAppSelector(state => state.appointments.items);
    const events = useMemo(() => createCalendarEventsFromAppointmentsAndProcedures(appointments, procedures), [ appointments, procedures ]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(appointmentsSlice.actions.patchFilter({
            timeRange: visibleTimeRange
        }));
        dispatch(appointmentsActions.loadItems({
            args: { personId: personId }
        }));
        dispatch(medicalProceduresSlice.actions.patchFilter({
            timeRange: {
                start: visibleTimeRange.start,
                end: visibleTimeRange.end
            }
        }));
        dispatch(medicalProceduresActions.loadItems({
            args: { personId: personId }
        }));
    }, [ visibleTimeRange, personId, dispatch ]);

    return (
        <LocalizedCalendar
            events={events}
            onVisibleTimeRangeChanged={setVisibleTimeRange}
            height={height}
        />
    );

}